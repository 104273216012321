import React from 'react';
import { connect } from 'react-redux'
import {branch, compose, lifecycle, renderComponent} from 'recompose'
import { opticianActions, opticianSelectors} from './redux'
import { sessionSelectors } from '../session/redux'
import Loading from '../../components/Loading'
import OpticianProfile from "./OpticianProfile";

const mapStateToProps = (state) => ({
    isOpen: opticianSelectors.profileDialogOpen(state),
    isLoading: opticianSelectors.isLoading(state),
    opticianForm: opticianSelectors.opticianForm(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
    loggedInOrganizationId: sessionSelectors.organizationId(state),
    errorMessage: opticianSelectors.errorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadOptician: (orgId) => dispatch(opticianActions.loadOptician(orgId)),
    updateOptician: (opticianForm) => dispatch(opticianActions.updateOptician(opticianForm)),
    closeDialog: () => dispatch(opticianActions.closeDialogs()),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({

        componentDidUpdate() {
        },

        componentWillReceiveProps(newProps) {
        },

        componentDidMount() {
            this.props.loadOptician(this.props.loggedInOrganizationId)
        }

    }),
    branch(({isLoading, opticianForm, errorMessage}) => isLoading || (!errorMessage && (!opticianForm || !opticianForm.organizationId)),
        renderComponent(()=><Loading title={'Laddar...'}/>)
    )
)(OpticianProfile)