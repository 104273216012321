import React from 'react';
import { CubeGrid } from 'better-react-spinkit'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';
import {Modal} from "react-bootstrap";

const style = {
  display: 'flex',
  justifyContent: 'center',
  align: 'center',
};

const Loading = ({title}) =>
<div className="Loading">
  <Modal
      show={true}
      align={"center"}
      valign={"center"}
      // onHide={onCancelClicked}
      backdrop="static"
      // keyboard={true}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      // centered
  >
      <Modal.Body>
        <div className="Loading" >
          <div>{title ? <h3>{title}</h3>:<h3>Laddar...</h3>}</div>
          <div style={style}><CubeGrid size={50}/></div>
        </div>
      </Modal.Body>
    </Modal>
</div>

export default Loading