import {lifecycle} from 'recompose';

export default lifecycle({

  componentDidUpdate() {
  },

  componentWillReceiveProps(newProps) {
    console.log('NEW PROPS', newProps)
    if (!this.props.selectedOpticianShop) {
      console.log('TP1');
      if (newProps.opticianShops && this.props.loggedInOrganizationId) {
        console.log('TP2');
        this.props.updateReferralValue("sender.organizationId", this.props.loggedInOrganizationId)
      } else if (newProps.opticianShops && newProps.opticianShops.length === 1) {
          console.log('TP3');
          this.props.updateReferralValue("senderId", newProps.opticianShops[0].organizationId)
      }
    }
    if (!this.props.selectedCareUnit) {
        console.log('TP4');
        if (newProps.careUnits && newProps.careUnits.length ===1) {
            console.log('TP5');
            this.props.updateReferralValue("receiver.organizationId", newProps.careUnits[0].organizationId)
        }
    }
  },

  componentDidMount() {
    console.log('TP6');
    this.props.loadCareUnits();
    console.log('TP7');
    this.props.loadOpticianShops();
  }

});