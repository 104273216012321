import React from 'react'
import './Home.css';
import Illustration from '../../images/startpage-image.png'
import {Col, Grid, Jumbotron, Row} from 'react-bootstrap';

const Home = () => (
    <div className="Home">
            <Grid id="homeJumbotronGrid">
                <Row className="show-grid">
                    <Col xs={12} md={7} lg={7}>
                        <Grid>
                            <Col xs={12} md={7} lg={7}>
                                <Jumbotron className={"white welcome"}>
                                    <span className={"startingLine"}></span>
                                    <h2>Välkommen</h2>
                                    <p>Ögonremiss är en tjänst för dig som Optiker, där du på ett enkelt och
                                        effektivt sätt kan skapa och skicka en remiss.</p>
                                </Jumbotron>
                            </Col>
                            <Col xs={12} md={5} lg={5} className={"illustrationContainer"}>
                                <Jumbotron className={"white"}>
                                    <img alt={'Ögonremiss illustration'} className={""} src={Illustration}/>
                                </Jumbotron>
                            </Col>
                        </Grid>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col xs={12} md={6} lg={6}>
                        <Grid>
                            <Col xs={12} md={6} lg={6}>
                                <Jumbotron className={"shadow"}>
                                    <span className={"startingLine"}></span>
                                    <a href={"/info/#beskrivning"} className={"btn"}>Mer info</a>
                                    <h1>För optiker</h1>
                                    <p>Som Optiker kan du nu skicka in din remiss elektronisk.
                                        Läs mer om vilka landsting som är anslutna samt hur det fungerar.</p>
                                </Jumbotron>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Jumbotron className={"hangDown"}>
                                    <span className={"startingLine"}></span>
                                    <a href={"/info/#beskrivning"} className={"btn"}>Mer info</a>
                                    <h1>Skicka en remiss</h1>
                                    <p>Logga In och skicka en remiss, eller ta del av information om hur du får tillgång till tjänsten.
                                    </p>
                                </Jumbotron>
                            </Col>
                        </Grid>
                    </Col>
                </Row>
                {/*<Row style={{height:'64px'}}/>*/}
                {/*<Row className="show-grid">*/}
                    {/*<Col xs={12} md={7} lg={7}>*/}
                        {/*<Grid>*/}
                            {/*<Row>*/}
                            {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*<Col xs={10} md={10} lg={10}>*/}
                                {/*<Jumbotron className={"white description"}>*/}
                                    {/*<span className={"startingLine"}></span>*/}
                                    {/*<h2 id="beskrivning">Om tjänsten</h2>*/}
                                    {/*<p>Ögonremiss är en tjänst för dig som Optiker, där du på ett enkelt och effektivt sätt kan skapa och skicka en remiss elektroniskt till mottagaren.*/}
                                        {/*Ta kontakt med den mottagning som du brukar skicka era remisser till, så kan de meddela om de är anslutna till tjänsten ögonremiss.se.*/}
                                        {/*Om mottagaren är ansluten, kommer Ni få ett mail från ögonremiss.se där du får möjlighet att logga in med unikt lösenord. När du är inloggad kan du fylla i information om patienten och skicka remissen elektroniskt till mottagaren. Allt du behöver göra efter att remissen är ifylld, är att klicka på knappen skicka. Du kommer få en bekräftelse på att remissen är inskickad direkt på skärmen i form av en PDF. PDF’en kan du välja att spara, skriva ut och ge till patienten eller helt enklat stänga ner. Remissen som skickas till mottagaren kommer läsas in elektroniskt i mottagares journalsystem, och patient kommer bli kontaktad på vanligt sätt av mottagaren efter att en läkare bedömt remissen.*/}
                                        {/*All kommunikation mellan din dator och mottagande journalsystem sker krypterat och med de säkerhetsfunktioner som krävs och godkänts av mottagaren och berörda myndigheter. Ingen patientinformation lagras på någon annanstans än hos i mottagarens journalsystem på vanligt sätt.*/}
                                    {/*</p>*/}
                                {/*</Jumbotron>*/}
                            {/*</Col>*/}
                            {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*<Col xs={10} md={10} lg={10}>*/}
                                {/*<Jumbotron className={"white about"}>*/}
                                    {/*<span className={"startingLine"}></span>*/}
                                    {/*<h2 id="omoss">Om oss</h2>*/}
                                    {/*<p>Ögonremiss.se är en tjänst för utvecklad av Bluebrick AB i samråd med*/}
                                        {/*Region Jämtland Härjedalen för att förenkla och*/}
                                        {/*förbättra hanteringen av ögonremisser.</p>*/}
                                {/*</Jumbotron>*/}
                            {/*</Col>*/}
                                {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*<Col xs={10} md={10} lg={10}>*/}
                                {/*<Jumbotron className={"white contact"}>*/}
                                    {/*<span className={"startingLine"}></span>*/}
                                    {/*<h2 id="kontakt">Kontakt</h2>*/}
                                    {/*<p>Har du frågor om inloggning eller om remisser som du skickat.*/}
                                        {/*Kontakta den enhet som tar emot dina remisser.*/}
                                        {/*Om du idag inte har inloggning till ögonremiss.se, så ber vi er kontakta*/}
                                        {/*den mottagare som du normalt skickar dina remisser*/}
                                        {/*till för att bli registrerad.</p>*/}
                                {/*</Jumbotron>*/}
                            {/*</Col>*/}
                                {/*<Col xs={1} md={1} lg={1}></Col>*/}
                            {/*</Row>*/}
                        {/*</Grid>*/}
                    {/*</Col>*/}

                {/*</Row>*/}
            </Grid>
    </div>
)
export default Home