import { connect } from 'react-redux'
import {compose, lifecycle} from 'recompose'
import { sessionActions, sessionSelectors} from './redux'
import ForgotPassword from "./ForgotPassword";
import {withRouter} from "react-router";

const mapStateToProps = (state, { match: { params: { username, otp }}}) => ({
    username: username,
    forgotPasswordModel: sessionSelectors.forgotPasswordModel(state),
});

const mapDispatchToProps = (dispatch) => ({
    resetForgotPasswordForm: () => dispatch(sessionActions.resetForgotPasswordForm()),
    performSendResetMail: (email) => dispatch(sessionActions.resetUserPassword(email)),
});

export default compose (
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({

        componentDidUpdate() {
        },

        componentWillReceiveProps(newProps) {
        },

        componentDidMount() {

            this.props.resetForgotPasswordForm();
        }

    }),
)(ForgotPassword)