import React from 'react'
import { sessionSelectors } from './redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'

const isUserAllowed = (allowedRoles, userRoles) => {
    return userRoles.filter((userRole) => (allowedRoles.filter((allowedRole) => "ROLE_" + allowedRole === userRole).length > 0)).length > 0
}

const Secured = ({children, loggedInUser, roles, allowedRoles}) => {
    let allowed = false;
    if (loggedInUser && roles) {
        allowed = isUserAllowed(allowedRoles, roles);
    }
    return (
        <span>
            {allowed && children}
        </span>
    )
}

const mapStateToProps = (state) => ({
    loggedInUser: sessionSelectors.loggedInUser(state),
    roles: sessionSelectors.roles(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
)(Secured)