import {lifecycle} from 'recompose';

export default lifecycle({

  componentDidUpdate() {
  },

  componentWillReceiveProps(newProps) {
  },

  componentDidMount() {
    this.props.resetChangePasswordForm();
  }

});