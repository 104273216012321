import React from 'react'
import {Alert,  Button, FormGroup, ControlLabel, FormControl, Radio, Glyphicon, Row, Col, Well, Panel} from 'react-bootstrap'
import './Referral.css'
import SendReferralDialog from './SendReferralDialog';
import ResetReferralDialog from "./ResetReferralDialog";
import ClinicInfoDialog from './ClinicInfoDialog'
import Grid from "react-bootstrap/es/Grid"; // Might possibly create a smaller dist compared to importing it above (something with treeshaking)
// TODO: change to date-fns
import moment from 'moment';
import DatePicker from "react-datepicker";
import {validateSwedishPersonalId} from "../../components/Helpers";

import "react-datepicker/dist/react-datepicker.css";


const Referral = ({referral,
                      careUnits, selectedCareUnit, opticianShops, selectedOpticianShop, isClinicInfoDialogOpen,
                      isResetDialogOpen, isSendDialogOpen, sendReferralError, validationErrors,
                      openClinicInfoDialog, openResetDialog, openSendDialog, closeDialogs, setValidationErrors,
                      updateReferralValue, fileURL, fileCreatedDate,
                      resetReferral, sendReferral}) => {

    const isReadOnly = () => {
        return fileURL !== null;
    }

    // For person number validation
    const isValidAtPositionIndex = (c, positionIndex) => {
        // console.log('isValidAtPositionIndex', c , positionIndex)
        if (positionIndex === 8) {
            if ('0123456789-'.indexOf(c) !== -1) {
                // Is a number or -
                return true;
            }
        } else {
            if ('0123456789'.indexOf(c) !== -1) {
                // Is a number
                return true;
            }
        }
        return false;
    }

    const handlePersonNoChange = (e) => {
        if (e.target.value && e.target.value.length > 0) {
            if (!isValidAtPositionIndex(e.target.value.charAt(e.target.value.length-1), e.target.value.length-1)) {
                // disregard the change if the last character is not a number or a - if the length is nine
                console.log('out1')
                return
            }
        }

        if (e.target.value && e.target.value.length===9){
            if (e.target.value && referral.patientPersonalId && e.target.value.length>referral.patientPersonalId.length) {
                // on addition
                // if we add something else than a '-' (i.e. a number), also add the '-' automatically
                if (e.target.value.charAt(8) !== '-') {
                    e.target.value = e.target.value.substring(0, 8) + '-' + e.target.value.charAt(8)
                }
            } else {
                // on deletion
                // do nothing, just let any character be deleted normally
            }
        }

        handleChange(e);
    }

    const handleNumericValueChange = (e) => {
        let val = e.target.value;
        if (val && val.length>0) {
            if (val && val.length===1) {
                if ('0123456789.,+-'.indexOf(val.charAt(0)) === -1) {
                    return;
                }
            } else {
                let adjustedVal = val.replace(',', '.');
                if (isNaN(adjustedVal)) {
                    return;
                }
            }
        }
        handleChange(e);
    }

    const handleChange = (e) => {
        // e.stopPropagation();
        // e.preventDefault();
        const fieldName = e.target.id === '' ? e.target.name : e.target.id;
        const fieldValue = e.target.value;
        const fieldType = e.target.type;
        if (fieldType === 'radio') {
            updateReferralValue(fieldName, fieldValue==='on');
        } else {
            updateReferralValue(fieldName, fieldValue);
        }
    }

    const handleDateChange = (value, formattedValue) =>{
        updateReferralValue('lastExaminationDate', value);
    }

    // const noValue = (val) => {
    //     return typeof val === "undefined" || val===null || val === "";
    // }

    // const noEyeValue = (eye) => {
    //     return noValue(eye.freeVisus)
    //     && noValue(eye.bestCorrectionSphere)
    //     && noValue(eye.bestCorrectionCyl)
    //     && noValue(eye.bestCorrectionAx)
    //     && noValue(eye.visus)
    //     && noValue(eye.visusAddStph)
    //     && noValue(eye.pressure)
    // }

    // const noExaminationValue = (examination) => {
    //     return noEyeValue(examination.left) && noEyeValue(examination.right);
    // }

    // const hasExaminationValues = (examination) => {
    //     return !noExaminationValue(examination);
    // }

    const validateReferral = () => {
        let errors = [];
        if (!referral.patientPersonalId
            || referral.patientPersonalId.length !== 13
            || !validateSwedishPersonalId(referral.patientPersonalId)
            || !(referral.patientPersonalId.substring(0,2) === "19"
                    || referral.patientPersonalId.substring(0,2) === "20")
        ) {
            errors.push("Inget eller felaktigt personnummer, använd formen ÅÅÅÅMMDD-NNNN");
            // console.log('errors', errors)
        }
        if (!referral.sender.organizationId) {
            errors.push("Ingen avsändare vald");
            // console.log('errors', errors)
        }
        if (!referral.receiver.organizationId) {
            errors.push("Ingen mottagare vald");
            // console.log('errors', errors)
        }
        // if (hasExaminationValues(referral.resultLastExamination) && noValue(referral.lastExaminationDate)) {
        //     errors.push("Det finns värden i tidigare undersökning men datum är inte angivet");
        //     console.log('errors', errors)
        // }
        return errors;
    }

    const sendReferralClicked = (e) => {
        let errors = validateReferral();
        if (errors && errors.length>0) {
            setValidationErrors(errors);
        } else {
            sendReferral();
        }
    }

    const resetReferralClicked = (e) => {
        resetReferral();
    }

    return (
    <div className="Referral">
        {(isClinicInfoDialogOpen &&
            <ClinicInfoDialog onCloseClicked={closeDialogs}/>)
        || (isResetDialogOpen &&
            <ResetReferralDialog />)
        || (isSendDialogOpen &&
            <SendReferralDialog />)
        }
        <Grid id="referralMainGrid">
            <Row>
                {sendReferralError &&
                <Col xs={12} md={12} lg={12}>
                    <Alert bsStyle="danger">{sendReferralError}</Alert>
                </Col>
                }
                {validationErrors && validationErrors.length>0 &&
                <Col xs={12} md={12} lg={12}>
                    <Alert bsStyle="danger">
                        <b><Glyphicon glyph={"exclamation-sign"}/> Fel upptäcktes i remissen, rätta dessa och testa att skicka igen</b>
                        <ul>
                        {
                            validationErrors.map((e, i)=> (<li key={"valerr-"+i}>{e}</li>))
                        }
                        </ul>
                    </Alert>
                </Col>
                }
                <Col xs={12} md={2} lg={2}>
                    <Alert bsStyle="info">
                        <p><Glyphicon glyph="info-sign" /> <strong>Instruktion</strong></p>
                    <p>
                        Fyll i uppgifterna i formuläret. Använd TAB-tangenterna för att förflytta dig
                        mellan fälten. När du är klar klickar du på Skicka.
                    </p>
                    <p>
                        Remissen skickas då direkt till mottagande enhet elektroniskt.
                        Du får direkt en bekräftelse på skärmen som du kan skriva ut och ge till kunden.
                    </p>
                    </Alert>
                </Col>
                <Col xs={12} md={7} lg={7} className={"fatBorderColumn"}>
                    {/*
                        PATIENTUPPGIFTER
                    */}
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <FormGroup controlId={"patientPersonalId"}>
                                <ControlLabel>Personnummer</ControlLabel>
                                <FormControl type={"text"}
                                             required
                                             value={referral.patientPersonalId || ''}
                                             placeholder={"ÅÅÅÅMMDD-NNNN"}
                                             maxLength={13}
                                             disabled={isReadOnly()}
                                             onChange={handlePersonNoChange}
                                />
                                {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4} lg={4}>

                            <FormGroup controlId={"patientName"}>
                                <ControlLabel>Namn</ControlLabel>
                                <FormControl type={"text"}
                                             value={referral.patientName || ''}
                                             placeholder={"Namn"}
                                             maxLength={64}
                                             disabled={isReadOnly()}
                                             onChange={handleChange}
                                />
                                {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <FormGroup controlId={"patientPhone"}>
                                <ControlLabel>Telefonnummer</ControlLabel>
                                <FormControl type={"text"}
                                             value={referral.patientPhone || ''}
                                             placeholder={"Telefonnummer"}
                                             maxLength={20}
                                             disabled={isReadOnly()}
                                             onChange={handleChange}
                                />
                                {/*<HelpBlock>Hjälptext</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8} lg={8}>
                            <FormGroup controlId={"interpreterNeeded"}>
                                <Col xs={12} md={5} lg={5}>
                                    <Col>
                                        <ControlLabel >Krävs tolk?</ControlLabel>{' '}
                                    </Col>
                                    <Col>
                                        <Radio name="interpreterNeeded"
                                               value={'on'}
                                               checked={referral.interpreterNeeded === true}
                                               onChange={handleChange}
                                               disabled={isReadOnly()}
                                               inline>
                                            JA
                                        </Radio>{' '}
                                        <Radio name="interpreterNeeded"
                                               onChange={handleChange}
                                               value={'off'}
                                               checked={referral.interpreterNeeded === false}
                                               disabled={isReadOnly()}
                                               inline>
                                            NEJ
                                        </Radio>{' '}
                                    </Col>
                                </Col>
                                <Col xs={12} md={7} lg={7}>
                                    <FormGroup controlId="interpreterLanguage">
                                        <ControlLabel>Språk</ControlLabel>
                                        <FormControl type={"text"}
                                                     value={referral.interpreterLanguage || ''}
                                                     placeholder={"Språk"}
                                                     maxLength={32}
                                                     disabled={isReadOnly()}
                                                     onChange={handleChange}
                                        />
                                        {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                                    </FormGroup>
                                {/*<FormGroup controlId="interpreterLanguage">*/}
                                    {/*<FormControl componentClass="select"*/}
                                                 {/*placeholder="Språk"*/}
                                                 {/*value={referral.interpreterLanguage || ""}*/}
                                                 {/*onChange={handleChange}*/}
                                                 {/*disabled={isReadOnly()}>*/}
                                        {/*<option value="">Språk</option>*/}
                                        {/*<option value="Arabiska">Arabiska</option>*/}
                                        {/*<option value="Finska">Finska</option>*/}
                                        {/*<option value="Samiska">Samiska</option>*/}
                                        {/*<option value="Annat">Annat</option>*/}
                                    {/*</FormControl>*/}
                                {/*</FormGroup>*/}
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <FormGroup controlId={"referralPhotoSent"}>
                                <Col>
                                <ControlLabel>Foto inskickat?</ControlLabel>{' '}
                                </Col><Col>
                                <Radio name="photoSent"
                                       value={'on'}
                                       checked={referral.photoSent === true}
                                       onChange={handleChange}
                                       disabled={isReadOnly()}
                                       inline>
                                    JA
                                </Radio>{' '}
                                <Radio name="photoSent"
                                       value={'off'}
                                       checked={referral.photoSent === false}
                                       onChange={handleChange}
                                       disabled={isReadOnly()}
                                       inline>
                                    NEJ
                                </Radio>{' '}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <FormGroup controlId={"question"}>
                                <ControlLabel>Frågeställning (max 130 tecken)</ControlLabel>
                                <FormControl type={"text"}
                                             value={referral.question || ''}
                                             placeholder={"Frågeställning"}
                                             maxLength={130}
                                             onChange={handleChange}
                                             disabled={isReadOnly()}
                                />
                                {/*<HelpBlock>Hjälptext</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <FormGroup controlId={"description"}>
                                <ControlLabel>Detaljerad beskrivning av patientens besvär (max 1000 tecken)</ControlLabel>
                                <FormControl //type={"text"}
                                             componentClass="textarea"
                                             rows={7}
                                             value={referral.description || ''}
                                             placeholder={"Detaljerad beskrivning av patientens besvär"}
                                             maxLength={1000}
                                             onChange={handleChange}
                                             disabled={isReadOnly()}
                                />
                                {/*<HelpBlock>Hjälptext</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <FormGroup controlId={"duration"}>
                                <ControlLabel>Hur länge har patienten haft sina besvär?</ControlLabel>
                                <FormControl type={"text"}
                                    value={referral.duration || ''}
                                    placeholder={"Varaktighet"}
                                    maxLength={128}
                                    onChange={handleChange}
                                             disabled={isReadOnly()}
                                />
                                {/*<HelpBlock>Hjälptext</HelpBlock>*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <b>Resultat vid dagens undersökning</b>
                        </Col>
                    </Row>
                    <Panel>
                    <Row>
                        {/*TODAYS RESULT MATRIX*/}
                        <Col xs={12} md={12} lg={12} className={'resultMatrix'}>

                            <Row className={"resultHeader"}>
                                <Col xs={1} md={1} lg={1}><b></b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2}>Fri</Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={12} md={12} lg={12}>Bästa korrektion</Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4}></Col>
                                        <Col xs={4} md={4} lg={4}>Add</Col>
                                        <Col xs={4} md={4} lg={4}></Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className={"resultHeader"}>
                                <Col xs={1} md={1} lg={1}><b></b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2}>Visus</Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4} >Sfär</Col>
                                        <Col xs={4} md={4} lg={4}>Cyl</Col>
                                        <Col xs={4} md={4} lg={4}>Ax</Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4}>Visus</Col>
                                        <Col xs={4} md={4} lg={4}>stph</Col>
                                        <Col xs={4} md={4} lg={4}>Tryck</Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={1} lg={1} className={"resultHeader"}><b>Höger</b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2} className={"referralValueCol"}>
                                        <FormControl type={"text"}
                                                     id="resultToday.right.freeVisus"
                                                     value={referral.resultToday.right.freeVisus || ''}
                                                     maxLength={6}
                                                     onChange={handleNumericValueChange}
                                                     disabled={isReadOnly()}
                                        />
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.bestCorrectionSphere"
                                                         value={referral.resultToday.right.bestCorrectionSphere || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                                         />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.bestCorrectionCyl"
                                                         value={referral.resultToday.right.bestCorrectionCyl || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.bestCorrectionAx"
                                                         value={referral.resultToday.right.bestCorrectionAx || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.visus"
                                                         value={referral.resultToday.right.visus || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.visusAddStph"
                                                         value={referral.resultToday.right.visusAddStph || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.right.pressure"
                                                         value={referral.resultToday.right.pressure || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={1} lg={1} className={"resultHeader"}><b>Vänster</b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2} className={'referralValueCol'}>
                                        <FormControl type={"text"}
                                                     id="resultToday.left.freeVisus"
                                                     value={referral.resultToday.left.freeVisus || ''}
                                                     maxLength={6}
                                                     onChange={handleNumericValueChange}
                                                     disabled={isReadOnly()}
                                                    />
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.bestCorrectionSphere"
                                                         value={referral.resultToday.left.bestCorrectionSphere || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.bestCorrectionCyl"
                                                         value={referral.resultToday.left.bestCorrectionCyl || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.bestCorrectionAx"
                                                         value={referral.resultToday.left.bestCorrectionAx || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.visus"
                                                         value={referral.resultToday.left.visus || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.visusAddStph"
                                                         value={referral.resultToday.left.visusAddStph || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultToday.left.pressure"
                                                         value={referral.resultToday.left.pressure || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </Panel>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <p>{' '}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <FormGroup controlId={"receivedCorrectionToday"}>
                                <ControlLabel>Erhållit korrektion vid dagens besök?</ControlLabel>{' '}
                                <Radio name="receivedCorrectionToday"
                                       value={'on'}
                                       checked={referral.receivedCorrectionToday === true}
                                       onChange={handleChange}
                                       disabled={isReadOnly()}
                                       inline>
                                    JA
                                </Radio>{' '}
                                <Radio name="receivedCorrectionToday"
                                       value={'off'}
                                       checked={referral.receivedCorrectionToday === false}
                                       onChange={handleChange}
                                       disabled={isReadOnly()}
                                       inline>
                                    NEJ
                                </Radio>{' '}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6} lg={5}>
                            <ControlLabel>Resultat vid föregående undersökning</ControlLabel>
                            {/*<b>Resultat vid föregående undersökning</b>*/}
                        </Col>
                        <Col xs={6} md={6} lg={7}>
                            {/*<FormGroup controlId="dob">*/}
                            {/*    <ControlLabel>Select Date</ControlLabel>*/}
                            <FormControl type="date" name="lastExaminationDate" placeholder="Datum ÅÅÅÅ-MM-DD" />
                            {/*</FormGroup>*/}


                            {/*<DatePicker id="referralLastExaminationDate"*/}
                            {/*            showIcon*/}
                            {/*            locale={"se"}*/}
                            {/*            selected={referral.lastExaminationDate || null}*/}
                            {/*            dateFormat={"yyyy-MM-dd"}*/}

                            {/*            placeholderText="Datum ÅÅÅÅ-MM-DD"*/}
                            {/*            // dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']}*/}
                            {/*            // monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli','Augusti', 'September', 'Oktober', 'November', 'December']}*/}
                            {/*            // calendarContainer={document.getElementById('datePickerCalendar')}*/}
                            {/*            onChange={handleDateChange}*/}
                            {/*            disabled={isReadOnly()}*/}
                            {/*/>*/}
                            {/*<div id={'datePickerCalendar'} className={"datePickerCalendar"}>&nbsp;</div>*/}
                            {/*<FormControl className={"date"}*/}
                                         {/*type={"text"}*/}
                                         {/*id="referralLastExaminationDate"*/}
                                         {/*value={referral.lastExaminationDate || ''}*/}
                                         {/*placeholder="Datum ÅÅÅÅMMDD"*/}
                                         {/*onChange={handleChange}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Panel>
                    <Row>
                        {/*LAST RESULT MATRIX*/}

                        <Col xs={12} md={12} lg={12} className={'resultMatrix'}>

                            <Row className={"resultHeader"}>
                                <Col xs={1} md={1} lg={1}><b></b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2}>Fri</Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={12} md={12} lg={12}>Bästa korrektion</Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4}></Col>
                                        <Col xs={4} md={4} lg={4}>Add</Col>
                                        <Col xs={4} md={4} lg={4}></Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className={"resultHeader"}>
                                <Col xs={1} md={1} lg={1}><b></b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2}>Visus</Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4} >Sfär</Col>
                                        <Col xs={4} md={4} lg={4}>Cyl</Col>
                                        <Col xs={4} md={4} lg={4}>Ax</Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn'}>
                                        <Col xs={4} md={4} lg={4}>Visus</Col>
                                        <Col xs={4} md={4} lg={4}>stph</Col>
                                        <Col xs={4} md={4} lg={4}>Tryck</Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={1} lg={1} className={"resultHeader"}><b>Höger</b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2} className={"referralValueCol"}>
                                        <FormControl type={"text"}
                                                     id="resultLastExamination.right.freeVisus"
                                                     value={referral.resultLastExamination.right.freeVisus || ''}
                                                     maxLength={6}
                                                     onChange={handleNumericValueChange}
                                                     disabled={isReadOnly()}
                                        />
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.bestCorrectionSphere"
                                                         value={referral.resultLastExamination.right.bestCorrectionSphere || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.bestCorrectionCyl"
                                                         value={referral.resultLastExamination.right.bestCorrectionCyl || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.bestCorrectionAx"
                                                         value={referral.resultLastExamination.right.bestCorrectionAx || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.visus"
                                                         value={referral.resultLastExamination.right.visus || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.visusAddStph"
                                                         value={referral.resultLastExamination.right.visusAddStph || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.right.pressure"
                                                         value={referral.resultLastExamination.right.pressure || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} md={1} lg={1} className={"resultHeader"}><b>Vänster</b></Col>
                                <Col xs={11} md={11} lg={11}>
                                    <Col xs={2} md={2} lg={2} className={'referralValueCol'}>
                                        <FormControl type={"text"}
                                                     id="resultLastExamination.left.freeVisus"
                                                     value={referral.resultLastExamination.left.freeVisus || ''}
                                                     maxLength={6}
                                                     onChange={handleNumericValueChange}
                                                     disabled={isReadOnly()}
                                        />
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.bestCorrectionSphere"
                                                         value={referral.resultLastExamination.left.bestCorrectionSphere || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.bestCorrectionCyl"
                                                         value={referral.resultLastExamination.left.bestCorrectionCyl || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.bestCorrectionAx"
                                                         value={referral.resultLastExamination.left.bestCorrectionAx || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={5} md={5} lg={5} className={'borderColumn referralValueCol'}>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.visus"
                                                         value={referral.resultLastExamination.left.visus || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.visusAddStph"
                                                         value={referral.resultLastExamination.left.visusAddStph || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                        <Col xs={4} md={4} lg={4} style={{padding:'1px'}}>
                                            <FormControl type={"text"}
                                                         id="resultLastExamination.left.pressure"
                                                         value={referral.resultLastExamination.left.pressure || ''}
                                                         maxLength={6}
                                                         onChange={handleNumericValueChange}
                                                         disabled={isReadOnly()}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Panel>

                </Col>
                <Col xs={12} md={3} lg={3} className={"fatBorderColumn"}>
                    <Well>
                    <Row className={"senderReceiver"}>
                        <Col xs={6} md={12} lg={12}>
                            <p>Remitterande optiker</p>
                            <FormGroup controlId="sender.organizationId">
                                <FormControl componentClass="select"
                                             placeholder="Remitterande optiker"
                                             value={referral.sender.organizationId || ""}
                                             onChange={handleChange}
                                             disabled={isReadOnly() || !opticianShops || opticianShops.length === 1}>
                                    <option value="">Välj avsändare</option>
                                    {
                                        opticianShops && opticianShops.map((org, index) =>
                                            <option value={org.organizationId} key={"opt-"+index}>{org.name}</option>
                                        )
                                    }
                                </FormControl>
                            </FormGroup>
                            {
                                (selectedOpticianShop && <b>{selectedOpticianShop.name}</b>) || <b></b>
                            }
                            {
                                (selectedOpticianShop && <p>{selectedOpticianShop.streetAddress}</p>) || <p></p>
                            }
                            {
                                (selectedOpticianShop && <p>{selectedOpticianShop.zipCode} {selectedOpticianShop.city}</p>) || <p></p>
                            }
                            <FormGroup controlId={"opticianName"}>
                                {/*<ControlLabel>Namn på optiker</ControlLabel>*/}
                                <FormControl type={"text"}
                                             value={referral.opticianName || ''}
                                             placeholder={"Namn på optiker"}
                                             onChange={handleChange}
                                             disabled={isReadOnly()}
                                />
                                {/*<HelpBlock>Hjälptext</HelpBlock>*/}
                            </FormGroup>
                        </Col>

                        <Col xs={6} md={12} lg={12}>
                            <p>Mottagande enhet</p>
                            <FormGroup controlId="receiver.organizationId">
                                <FormControl componentClass="select"
                                             placeholder="Mottagande enhet"
                                             value={referral.receiver.organizationId || ""}
                                             onChange={handleChange}
                                             disabled={isReadOnly() || !careUnits || careUnits.length === 1}>
                                    <option value="">Välj mottagare</option>
                                    {
                                        careUnits && careUnits.map((org, index) =>
                                            <option value={org.organizationId} key={"ve-"+index}>{org.name}</option>
                                        )
                                    }
                                </FormControl>
                            </FormGroup>
                            {
                                (selectedCareUnit && <b>{selectedCareUnit.name}</b>) || <b></b>
                            }
                            {
                                (selectedCareUnit && <p>{selectedCareUnit.streetAddress}</p>) || <p></p>
                            }
                            {
                                (selectedCareUnit && <p>{selectedCareUnit.zipCode} {selectedCareUnit.city}</p>) || <p></p>
                            }
                            {/*{*/}
                                {/*(selectedCareUnit && selectedCareUnit.clinicInfo && <span><b><Glyphicon glyph={"info-sign"}/> Info från kliniken</b> <p>{selectedCareUnit.clinicInfo} </p></span>) || <p></p>*/}
                            {/*}*/}
                            <span><b><Glyphicon glyph={"info-sign"}/> Info från kliniken</b>
                                <button onClick={()=>openClinicInfoDialog()}><Glyphicon glyph={"new-window"}/> Visa info</button></span>
                        </Col>
                    </Row>
                    <Row style={{height:'8px'}}/>
                    </Well>
                    {(!fileURL &&
                        <Row>
                            <Col xs={4} md={12} lg={12}>
                                <Button className={"cancel"} bsStyle="danger" onClick={resetReferralClicked}>Avbryt</Button>
                            </Col>
                            <Col xs={4} md={12} lg={12}>
                                <Button className={"send"} bsStyle="primary" onClick={sendReferralClicked}>Skicka</Button>
                            </Col>
                        </Row>)
                        ||
                        <Row>
                            <Col xs={4} md={12} lg={12}>
                                <Button className={"cancel"} bsStyle="danger" onClick={resetReferralClicked}>Rensa</Button>
                            </Col>
                            <Col xs={4} md={12} lg={12}>
                                <Button className={"download"} bsStyle="primary" href={fileURL} download={"remiss-"+moment(fileCreatedDate).format('YYYYMMDD-hhmmss')+".pdf"}><Glyphicon glyph={"download"}/> Hämta</Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Grid>
    </div>
    )}
export default Referral;