import {applyMiddleware, compose, createStore} from 'redux'
import { createLogger } from 'redux-logger'
import { rootReducer, rootEpic } from './redux'
import browserHistory from './history'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable';
import config from "react-global-configuration";
import history from "./history";

let epicMiddleware = createEpicMiddleware();

let loggerMiddleware = createLogger({
    actionTransformer: (action) => {
      if (action.password) {
        action = {
            ...action,
            password: '*** Intentionally hidden, see configureStore.js ***'
        }
      }
      if (action.retypePassword) {
            action = {
                ...action,
                retypePassword: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
      if (action.newPassword) {
            action = {
                ...action,
                newPassword: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
        if (action.retypeNewPassword) {
            action = {
                ...action,
                retypeNewPassword: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
        if (action.currentPassword) {
            action = {
                ...action,
                currentPassword: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
        if (action.newPasswordRetype) {
            action = {
                ...action,
                newPasswordRetype: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
        if (action.fieldName && (action.fieldName === "newPassword" || action.fieldName === "newPasswordRetype" || action.fieldName === "currentPassword")) {
            action = {
                ...action,
                fieldValue: '*** Intentionally hidden, see configureStore.js ***'
            }
        }
      return action;
    }
})

let redirectMiddleware = (store) => next => action => {
  next(action);
  switch (action.type) {
  case "REDIRECT": {
    console.log('REDIRECT', action.url)
    window.location=action.url;
    // next(
    //     apiRequest({
    //       url: `${SERVER_URL}/register`,
    //       method: "POST",
    //       data: action.payload
    //     })
    // );
    break;
  }
  default:
    break;
  }
};

let configureStore = () => {
  let store = config.get('debug') ?
      createStore(
          rootReducer,
          {},
          compose(
            applyMiddleware(
                epicMiddleware,
                redirectMiddleware,
                routerMiddleware(browserHistory),
                loggerMiddleware// Middleware that logs actions,
            )
          )
      )
      :
      createStore(
        rootReducer,
          {},
          compose(
            applyMiddleware(
                epicMiddleware,
                redirectMiddleware,
                routerMiddleware(browserHistory),
                loggerMiddleware// Middleware that logs actions,
            )
          )
      );
  epicMiddleware.run(rootEpic);
  return store
}


export default configureStore