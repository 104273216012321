export const UPDATE_REFERRAL_VALUE = 'referral.UPDATE_REFERRAL_VALUE'
export const SEND_REFERRAL = 'referral.SEND_REFERRAL';
export const SEND_REFERRAL_SUCCESS = 'referral.SEND_REFERRAL_SUCCESS';
export const SEND_REFERRAL_FAILURE = 'referral.SEND_REFERRAL_FAILURE';

export const RESET_REFERRAL = 'referral.RESET_REFERRAL';
export const OPEN_RESET_DIALOG = 'referral.OPEN_RESET_DIALOG';
export const OPEN_SEND_DIALOG = 'referral.OPEN_SEND_DIALOG';
export const CLOSE_DIALOGS = 'referral.CLOSE_DIALOGS';

export const OPEN_CLINIC_INFO_DIALOG = 'referral.OPEN_CLINIC_INFO_DIALOG';

export const SET_REFERRAL_VALIDATION_ERRORS = 'referral.SET_REFERRAL_VALIDATION_ERRORS';

export const LIST_CARE_UNITS = 'referral.LIST_CARE_UNITS';
export const LIST_CARE_UNITS_SUCCESS = 'referral.LIST_CARE_UNITS_SUCCESS';
export const LIST_CARE_UNITS_FAILURE = 'referral.LIST_CARE_UNITS_FAILURE';

export const LIST_OPTICIAN_SHOPS = 'referral.LIST_OPTICIAN_SHOPS';
export const LIST_OPTICIAN_SHOPS_SUCCESS = 'referral.LIST_OPTICIAN_SHOPS_SUCCESS';
export const LIST_OPTICIAN_SHOPS_FAILURE = 'referral.LIST_OPTICIAN_SHOPS_FAILURE';
