import React from 'react'
import {
    Alert,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    Row,
    Col,
} from 'react-bootstrap'
// import './ChangePassword.css'
import Grid from "react-bootstrap/es/Grid";
import {useParams} from "react-router";

const ChangePassword = ({ forgotPasswordModel: {
                            forgotPasswordForm,
                            forgotPasswordInfo,
                            forgotPasswordError},
                            performSendResetMail}) => {

    let {username, otp} = useParams();

    var emailInputRef;

    const resetForm = () => {
        emailInputRef.value = '';
    }

    const resetClicked = (e) => {
        performSendResetMail(emailInputRef.value);
        resetForm();
    }

    return (
    <div className="ForgotPassword">
        { (forgotPasswordInfo &&
            <Alert bsStyle="info">{forgotPasswordInfo}</Alert>)
        || (forgotPasswordError &&
            <Alert bsStyle="danger">{forgotPasswordError}</Alert>)
        }
        <Grid id={"forgotPasswordMainGrid"}>
            <Row>
                <Col xs={8} md={8} lg={8}>
                    <div>
                        <h1>Glömt ditt lösenord?</h1>
                        <h4>Fyll i din mailadress och klicka på nollställ lösenord nedan så skickar vi ett mail med information om hur du nollställer ditt lösenord.</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={8} md={8} lg={8}>
                    <FormGroup controlId={"email"}>
                        <ControlLabel>Email-adress</ControlLabel>
                        <FormControl type={"email"}
                                     placeholder={"Email-adress"}
                                     onKeyPress={(e) => {
                                         if (e.charCode === 13) {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             resetClicked();
                                         }
                                     }}
                                     inputRef={(ref) => {
                                         emailInputRef = ref
                                     }}
                        />
                        {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                    </FormGroup>
                </Col>
            </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} >
                        <FormGroup>
                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                            resetClicked()
                        }}>
                            Nollställ lösenord
                        </Button>
                        </FormGroup>
                    </Col>
                </Row>
        </Grid>
    </div>
    )}
export default ChangePassword;