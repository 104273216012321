import React from 'react'
import './App.css';
import Menu from './Menu'
import Main from './Main'
import Footer from './Footer'

const App = () => (
    <div className="App">
        <div className="App-header">
            <Menu />
        </div>
        <div className="App-body">
            <Main />
        </div>
        <div className="App-footer">
            <Footer />
        </div>
    </div>
)

export default App