import React from 'react';
import { connect } from 'react-redux'
import {branch, compose, lifecycle, renderComponent} from 'recompose'
import {careUnitActions, careUnitSelectors} from './redux'
import { sessionSelectors } from '../session/redux'
import Loading from '../../components/Loading'
import CareUnitProfile from "./CareUnitProfile";

const mapStateToProps = (state) => ({
    isOpen: careUnitSelectors.profileDialogOpen(state),
    isLoading: careUnitSelectors.isLoading(state),
    careUnitForm: careUnitSelectors.careUnitForm(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
    loggedInOrganizationId: sessionSelectors.organizationId(state),
    errorMessage: careUnitSelectors.errorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadCareUnit: (orgId) => dispatch(careUnitActions.loadCareUnit(orgId)),
    updateCareUnit: (careUnitForm) => dispatch(careUnitActions.updateCareUnit(careUnitForm)),
    closeDialog: () => dispatch(careUnitActions.closeDialogs()),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({

        componentDidUpdate() {
        },

        componentWillReceiveProps(newProps) {
            // console.log('will receive props', newProps)
            // if (!this.props.careUnitOrganizationId) {
            //   if (newProps.careUnits || newProps.careUnits == 1) {
            //
            //   }
            // }
        },

        componentDidMount() {
            this.props.loadCareUnit(this.props.loggedInOrganizationId)
        }

    }),
    branch(({isLoading, careUnitForm, errorMessage}) => isLoading || (!errorMessage && (!careUnitForm || !careUnitForm.organizationId)),
        renderComponent(()=><Loading title={'Laddar...'}/>)
    )
)(CareUnitProfile)