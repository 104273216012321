import React from 'react'
import {Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Well, Modal, ModalTitle} from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const ClinicInfoDialog = ({onCloseClicked}) => {
    return (
    <div className="ClinicInfoDialog">
        <Modal
            show={true}
            onHide={onCloseClicked}
            backdrop="static"
            keyboard={true}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
        >
                <Modal.Body>
                    <Col style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <div><h3>Information till optiker</h3></div>
                    <h2>Riktlinjer vid remiss från optiker till Ögonkliniken Östersund</h2>

                    <p>Vi har svårt att hinna med alla inkommande remisser, så vi vill uppmana er att vara restriktiva
                        med att remittera patienter och hoppas att dessa riktlinjer kan vara ett stöd.</p>

                    <p>
                        <strong>Remissen ska innehålla en utförlig beskrivning av patientens besvär och uppgifter om hur
                        länge besvären funnits. Har patienten besvär i sin vardag med till exempel läsning,
                        TV-tittande eller Bilkörning?</strong>
                    </p>

                    <ul>
                        <li>Vi utför inte hälsokontroller på besvärsfria patienter.</li>
                        <li>Måttliga irritationer, allergier, torra ögon med mera - hänvisa till primärvården.</li>
                        <li>Huvudvärk utan ögonsymtom – hänvisa till primärvården</li>
                        <li>Forier är ett är normaltillstånd och behöver inte remitteras såvida inte patienten har påtagliga ögonsymtom.</li>
                    </ul>

                    <p>
                        <strong>Vid långsam synnedsättning på patienter över 65 år utan andra symtom:</strong>
                    </p>
                    <ul>
                        <li>Remiss vid visus 0.7 eller sämre.</li>
                        <li>Visus 0.8 eller bättre ska inte remitteras till ögonkliniken.</li>
                        <li>Vid visus lägre än 0.7 hos patient med linsgrumlingar kan man avvakta med remiss så länge inte patienterna har så stora besvär att de är motiverade för operation.</li>
                    </ul>

                    <p>
                        <strong>Ögontryck</strong>
                    </p>

                    <ul>
                        <li>Tryck 24-29 vid minst 2 tillfällen – icke akut remiss till ögon</li>
                        <li>Tryck 30-40mmHg – icke akut remiss till ögonkliniken</li>
                        <li>Tryck över 40mmHg – ring ögonjouren som bedömer när patienten behöver undersökas</li>
                    </ul>

                    <p>
                        <strong>Krokseende</strong>
                    </p>
                    <ul>
                        <li>Ange vilket öga, samt hur länge besvären funnits. Nytillkommet krokseende ska remitteras omgående.</li>
                        <li>Amsler test ska inte göras på patienter som inte spontant klagar över krokseende. Testet har dålig träffsäkerhet, och det blir allt för många onödiga remisser.</li>
                    </ul>

                    <p>
                        <strong>Nytillkomna flugor, blixtar, skuggor med mera - Ring ögonjouren.</strong>
                    </p>

                    <Well>
                    Om remiss skickas pga fynd vid ögonbottenfotografering SKALL foto skickas till: ogonbilder@regionjh.se, ange på remissen att foto är inskickat.

                    OBS! Inga personuppgifter får skickas via mail, namnge bilden med remissdatum och patientens initialer!
                    </Well>
                    </Col>


                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                            onCloseClicked()
                        }}>Stäng</Button>

            </Modal.Body>
        </Modal>
    </div>
    )}
export default ClinicInfoDialog;