import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const SendReferralDialog = ({saveErrorMessage, onSaveClicked, onUpdateClicked, onCancelClicked, organizationId, organizationName, contactName, contactPhone}) => {
    var organizationNameInputRef;
    var contactNameInputRef;
    var contactPhoneInputRef;
    var saveButton;

    const significantFieldChanged = () => {
        saveButton.disabled = !(organizationNameInputRef.value && organizationNameInputRef.value.length > 0)
    }

    return (
    <div className="SaveOrganizationDialog">
        <Modal>
                <Modal.Header>
                    {(organizationName &&
                        <Modal.Title><h3>Update organization</h3></Modal.Title>)
                            ||
                        <div><h3>Create organization</h3></div>
                    }
                </Modal.Header>
                <Modal.Body>

                    {saveErrorMessage &&
                        <Alert bsStyle="danger">{saveErrorMessage}</Alert>
                    }
                    <Form id="saveOrganizationForm" className="SaveOrganizationForm" style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                              onSaveClicked(organizationNameInputRef.value, contactNameInputRef.value, contactPhoneInputRef.value)
                          }}>
                            <Row>
                                <Col sm={12}>
                        <FormGroup controlId="formHorizontalOrganizationName">
                            <ControlLabel>Organization name</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             organizationNameInputRef = ref
                                             if (ref) {
                                                 ref.focus();
                                             }
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 contactNameInputRef.focus();
                                             }
                                         }}
                                         onChange={()=>significantFieldChanged()}
                                         disabled={organizationName}
                                         value={organizationName}
                                         placeholder="Organization name"
                                         />
                        </FormGroup>
                        <FormGroup controlId="formHorizontalContactName">
                            <ControlLabel>Contact person name</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactNameInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 contactPhoneInputRef.focus();
                                             }
                                         }}
                                         defaultValue={contactName}
                                         placeholder="First and last name"
                                        />
                        </FormGroup>
                        <FormGroup controlId="formHorizontalContactPhone">
                            <ControlLabel>Contact person phone</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactPhoneInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 if (onSaveClicked) {
                                                     onSaveClicked(organizationNameInputRef.value, contactNameInputRef.value, contactPhoneInputRef.value)
                                                 } else if (onUpdateClicked) {
                                                     onUpdateClicked(organizationId, organizationNameInputRef.value, contactNameInputRef.value, contactPhoneInputRef.value)
                                                 }
                                             }
                                         }}
                                         defaultValue={contactPhone}
                                         placeholder="Phone number"
                                         />
                        </FormGroup>
                                </Col>
                            </Row>

                        <FormGroup>
                            <Row>
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}} >
                                    <Button onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Cancel
                                    </Button>
                                    {(onUpdateClicked &&
                                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                            onUpdateClicked(organizationId, organizationNameInputRef.value, contactNameInputRef.value, contactPhoneInputRef.value)
                                        }}>
                                            Update organization</Button>)
                                    ||
                                        <FormControl type={"submit"}
                                                     disabled
                                                     bsStyle={'primary'}
                                                     style={{marginLeft: '5px'}}
                                                     value={"Create organization"}
                                                     inputRef={(ref) => saveButton = ref}
                                                     onClick={(e) => {
                                                         e.preventDefault();
                                                         e.stopPropagation();

                                                     }}>
                                        </FormControl>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>
        </Modal>
    </div>
    )}
export default SendReferralDialog;