const getSessionState = (state) => state && state.session ? state.session : {};
export const loggedInUser = (state) => getSessionState(state).loggedInUser;
export const organizationId = (state) => getSessionState(state).organizationId;
export const organizationName = (state) => getSessionState(state).organizationName;
export const jwt = (state) => getSessionState(state).jwt;
export const loggingIn = (state) => getSessionState(state).loggingIn;
export const loggingOut = (state) => getSessionState(state).loggingOut;
export const loginDialogOpen = (state) => getSessionState(state).loginDialogOpen;
export const loginErrorMessage = (state) => getSessionState(state).loginErrorMessage;
export const expiresUTC = (state) => getSessionState(state).expiresUTC
export const roles = (state) => getSessionState(state).roles
export const changePasswordModel = (state) => getSessionState(state).changePasswordModel
export const forgotPasswordModel = (state) => getSessionState(state).forgotPasswordModel;
export const oidcPkceCodeChallenge = (state) => getSessionState(state).oidcPkceCodeChallenge;
export const oidcGrantCode = (state) => getSessionState(state).oidcGrantCode;
export const refreshToken = (state) => getSessionState(state).refreshToken;
export const idToken = (state) => getSessionState(state).idToken;