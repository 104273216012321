import React from 'react'
import { Alert, Button, Col, Row, Form, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const DeleteCareUnitDialog = (
    {deleteErrorMessage,
        onDeleteClicked,
        onCancelClicked,
        careUnitForm: {
            emailUsername,
            emailUsernameRepetition,
            organizationId,
            name,
            streetAddress,
            zipCode,
            city,
            contactName,
            contactPhoneNumber,
            clinicInfo
        },
    }) => {
    return (
    <div className="DeleteCareUnitDialog">
        <Modal
            show={true}
            onHide={onCancelClicked}
            keyboard={true}
        >
            <ModalHeader closeButton>
                <ModalTitle>Ta bort vårdenhet</ModalTitle>
            </ModalHeader>
            <Modal.Body>

                {deleteErrorMessage &&
                    <Alert bsStyle="danger">{deleteErrorMessage}</Alert>
                }
                <Form id="saveCareUnitForm" className="SaveCareUnitForm" style={{width: '360px'}}
                      onSubmit={(e)=>{
                          e.preventDefault();
                          e.stopPropagation();
                      }}>
                    <Row>
                        <Col sm={12}>
                                <p>Är du säker på att du vill ta bort vårdenheten, {name}?</p>
                                <Button bsStyle={'danger'} onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                    Avbryt
                                </Button>
                                <Button bsStyle={'primary'}
                                        style={{marginLeft: '5px'}}
                                        onClick={() => {onDeleteClicked({organizationId})}}>Ta bort vårdenhet</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    </div>
    )}
export default DeleteCareUnitDialog;