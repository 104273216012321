export const CREATE_USER = 'users.CREATE_USER';
export const CREATE_USER_SUCCESS = 'users.CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'users.CREATE_USER_FAILURE';
export const UPDATE_USER = 'users.UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'users.UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'users.UPDATE_USER_FAILURE';
// export const CHANGE_PASSWORD = 'users.CHANGE_PASSWORD';
// export const CHANGE_PASSWORD_SUCCESS = 'users.CHANGE_PASSWORD_SUCCESS';
// export const CHANGE_PASSWORD_FAILURE = 'users.CHANGE_PASSWORD_FAILURE';
// export const UPDATE_CHANGE_PASSWORD_FORM = 'users.UPDATE_CHANGE_PASSWORD_FORM';
// export const RESET_CHANGE_PASSWORD_FORM = 'users.RESET_CHANGE_PASSWORD_FORM';
export const DELETE_USER = 'users.DELETE_USER';
export const DELETE_USER_SUCCESS = 'users.DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'users.DELETE_USER_FAILURE';
export const LIST_USERS = 'users.LIST_USERS';
export const LIST_USERS_SUCCESS = 'users.LIST_USERS_SUCCESS';
export const LIST_USERS_FAILURE = 'users.LIST_USERS_FAILURE';
export const OPEN_DELETE_DIALOG = 'users.OPEN_DELETE_DIALOG';
export const OPEN_UPDATE_DIALOG = 'users.OPEN_UPDATE_DIALOG';
export const OPEN_CREATE_DIALOG = 'users.OPEN_CREATE_DIALOG';
export const CLOSE_DIALOGS = 'users.CLOSE_DIALOGS';