import {
    LIST_USERS_SUCCESS,
    OPEN_UPDATE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_DELETE_DIALOG,
    CLOSE_DIALOGS,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    // UPDATE_CHANGE_PASSWORD_FORM, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_SUCCESS, RESET_CHANGE_PASSWORD_FORM,
} from './UsersActionTypes'

export const initialUsersState = () => ({
    userList: null,
    username: null,
    role: null,
    createDialogOpen: false,
    updateDialogOpen: false,
    deleteDialogOpen: false,
    saveUserError: null,
});

const users = (state = initialUsersState(), action) => {
    switch (action.type) {
        // case UPDATE_CHANGE_PASSWORD_FORM:
        //     let changePasswordForm = {...state.changePasswordModel.changePasswordForm};
        //     const fieldName = action.fieldName;
        //     const fieldValue = action.fieldValue;
        //     changePasswordForm[fieldName] = fieldValue;
        //     return {
        //         ...state,
        //         changePasswordModel: {
        //             ...state.changePasswordModel,
        //             changePasswordForm
        //         }
        //     }
        // case CHANGE_PASSWORD_FAILURE:
        //     return {
        //         ...state,
        //         changePasswordModel: {
        //             ...state.changePasswordModel,
        //             changePasswordError: action.message,
        //             changePasswordInfo: null,
        //             // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
        //         }
        //     }
        // case CHANGE_PASSWORD_SUCCESS:
        //     return {
        //         ...state,
        //         changePasswordModel: {
        //             ...state.changePasswordModel,
        //             changePasswordError: null,
        //             changePasswordInfo: "Lösenordet uppdaterades",
        //             // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
        //         }
        //     }
        // case RESET_CHANGE_PASSWORD_FORM:
        //     return {
        //         changePasswordModel: {
        //             ...state.changePasswordModel,
        //             changePasswordError: null,
        //             changePasswordInfo: null,
        //             // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
        //         }
        //     }
        case LIST_USERS_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                userList: action.users,
                username: null,
                role: null,
            }
        case OPEN_CREATE_DIALOG:
            return {
                ...state,
                createDialogOpen: true,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                username: null,
                role: null,
            }
        case OPEN_UPDATE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: true,
                deleteDialogOpen: false,
                username: action.username,
                role: action.role,
                organizationId : action.organizationId,
            }
        case OPEN_DELETE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: true,
                username: action.username,
            }
        case CLOSE_DIALOGS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                username: null,
                role: null,
                organizationId: null,
                saveUserError: null,
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                saveUserError: null,
            }
        case CREATE_USER_FAILURE:
            return {
                ...state,
                createDialogOpen: true,
                saveUserError: action.message || "Fel vid försök att skapa användare",
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateDialogOpen: false,
                saveUserError: null,
            }
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                updateDialogOpen: true,
                saveUserError: action.message || "Fel vid försök att uppdatera användare",
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteDialogOpen: false,
                saveUserError: null,
            }
        case DELETE_USER_FAILURE:
            return {
                ...state,
                deleteDialogOpen: true,
                saveUserError: "Error while trying to delete user",
            }
        default:
            return state;
    }
}
export default users;