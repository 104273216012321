import {
    CREATE_CARE_UNIT, CREATE_CARE_UNIT_SUCCESS,
    UPDATE_CARE_UNIT, UPDATE_CARE_UNIT_SUCCESS,
    DELETE_CARE_UNIT, DELETE_CARE_UNIT_SUCCESS,
    LIST_CARE_UNITS, LOAD_CARE_UNIT,
} from './CareUnitActionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import config from 'react-global-configuration';
import {careUnitActions} from './'
import {
    deleteWithAuth,
    errorHandlingWithLogoutCheck,
    getJsonWithAuth,
    postJsonWithAuth,
    putJsonWithAuth
} from "../../../components/AjaxHelpers";

export const careUnitEpics = combineEpics(

    // Events of type LIST_CARE_UNITS
    (action$, state$) => action$.ofType(LIST_CARE_UNITS).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1',
                state: state$.value
            }).pipe(
                map(payload => careUnitActions.listCareUnitsSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response,
                    [careUnitActions.listCareUnitsFailed(response)],
                        "Något gick fel när lista av vårdenheter skulle hämtas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(LOAD_CARE_UNIT).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1/' + action.organizationId,
                state: state$.value
            }).pipe(
                map(payload => careUnitActions.loadCareUnitSucceeded(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error,
                    [careUnitActions.loadCareUnitFailed(error)],
                    "Något gick fel när vårdenhetsdata skulle laddas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_CARE_UNIT).pipe(
        switchMap(action =>
            postJsonWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1',
                body: {
                    ...action.careUnitForm,
                    organizationId: null,
                },
                state: state$.value
            }).pipe(
                map(payload => careUnitActions.createCareUnitSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response,
                    [careUnitActions.createCareUnitFailed(response)],
                        "Något gick fel när vårdenhet skulle skapas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_CARE_UNIT_SUCCESS).pipe(
        map(action => careUnitActions.listCareUnits())
    ),


    (action$, state$) => action$.ofType(UPDATE_CARE_UNIT).pipe(
        switchMap(action =>
            putJsonWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1/' + action.careUnitForm.organizationId,
                body: {
                    ...action.careUnitForm,
                },
                state: state$.value
            }).pipe(
                map(ajaxResponse => careUnitActions.updateCareUnitSucceeded(ajaxResponse.response)),
                catchError(response => errorHandlingWithLogoutCheck(response, [careUnitActions.updateCareUnitFailed(response)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(UPDATE_CARE_UNIT_SUCCESS).pipe(
        map(action => careUnitActions.listCareUnits())
    ),

    (action$, state$) => action$.ofType(DELETE_CARE_UNIT).pipe(
        switchMap(action =>
            deleteWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1/' + action.careUnitForm.organizationId,
                state: state$.value
            }).pipe(
                map(payload => careUnitActions.deleteCareUnitSucceeded()),
                catchError(response => errorHandlingWithLogoutCheck(response, [careUnitActions.deleteCareUnitFailed(response)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(DELETE_CARE_UNIT_SUCCESS).pipe(
        map(action => careUnitActions.listCareUnits())
    ),

);