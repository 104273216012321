import {
    GLOBAL_ERROR,
    GLOBAL_INFO, RESET_GLOBAL_ERROR,
    RESET_GLOBAL_INFO,
} from './GlobalActionTypes'

export const initialGlobalState = () => ({
    info: null,
});

const global = (state = initialGlobalState(), action) => {
    switch (action.type) {
        case GLOBAL_INFO:
            return {
                ...state,
                info: action.message,
            }
        case RESET_GLOBAL_INFO:
            return {
                ...state,
                info: null,
            }
        case GLOBAL_ERROR:
            return {
                ...state,
                error: action.message,
            }
        case RESET_GLOBAL_ERROR:
            return {
                ...state,
                error: null,
            }
        default:
            return state;
    }
}
export default global;