import {
    SEND_REFERRAL,
    SEND_REFERRAL_SUCCESS,
    SEND_REFERRAL_FAILURE,
    RESET_REFERRAL,
    OPEN_RESET_DIALOG,
    OPEN_SEND_DIALOG,
    CLOSE_DIALOGS,
    UPDATE_REFERRAL_VALUE,
    LIST_CARE_UNITS_SUCCESS,
    LIST_CARE_UNITS_FAILURE,
    LIST_OPTICIAN_SHOPS_SUCCESS,
    LIST_OPTICIAN_SHOPS_FAILURE,
    SET_REFERRAL_VALIDATION_ERRORS, OPEN_CLINIC_INFO_DIALOG,
} from './ReferralActionTypes'
import {errorMessage} from "../../../components/AjaxHelpers";
import {UPDATE_OPTICIAN_SUCCESS} from "../../optician/redux/OpticianActionTypes";
import {UPDATE_CARE_UNIT_SUCCESS} from "../../careUnit/redux/CareUnitActionTypes";

export const initialReferralState = () => ({
    referral: {
        sender: {
            organizationId: null,
            streetAddress: null,
            zipCode: null,
            city: null,
        },
        receiver: {
            organizationId: null,
            streetAddress: null,
            zipCode: null,
            city: null,
        },
        opticianName: null,
        patientPersonalId: null,
        patientName: null,
        patientPhone: null,
        interpreterNeeded: null,
        interpreterLanguage: null,
        photoSent: null,
        question: null,
        description: null,
        duration: null,
        resultToday: {
            right: {
                freeVisus: null,
                bestCorrectionSphere: null,
                bestCorrectionCyl: null,
                bestCorrectionAx: null,
                visus: null,
                visusAddStph: null,
                pressure: null,
            },
            left: {
                freeVisus: null,
                bestCorrectionSphere: null,
                bestCorrectionCyl: null,
                bestCorrectionAx: null,
                visus: null,
                visusAddStph: null,
                pressure: null,
            }
        },
        receivedCorrectionToday: null,
        lastExaminationDate: null,
        resultLastExamination: {
            right: {
                freeVisus: null,
                bestCorrectionSphere: null,
                bestCorrectionCyl: null,
                bestCorrectionAx: null,
                visus: null,
                visusAddStph: null,
                pressure: null,
            },
            left: {
                freeVisus: null,
                bestCorrectionSphere: null,
                bestCorrectionCyl: null,
                bestCorrectionAx: null,
                visus: null,
                visusAddStph: null,
                pressure: null,
            }
        }
    },
    validationErrors: null,
    fileURL: null,
    fileCreatedDate: null,
    careUnitList: null,
    careUnitListError: null,
    selectedCareUnit: null,
    opticianShopList: null,
    opticianShopListError: null,
    selectedOpticianShop: null,
    sendDialogOpen: false,
    resetDialogOpen: false,
    clinicInfoDialogOpen: false,
    sendReferralError: null,
    isSending: false,
});

const referral = (state = initialReferralState(), action) => {
    let currentState = {...state}
    switch (action.type) {
        case UPDATE_OPTICIAN_SUCCESS:
            if (currentState.opticianShopList) {
                let updatedElementIndex = state.opticianShopList.findIndex((o) => o.organizationId === action.optician.organizationId)
                let opticianShopList = [...state.opticianShopList]
                if (updatedElementIndex !== -1) {
                    opticianShopList[updatedElementIndex] = action.optician;
                }
                currentState.opticianShopList = opticianShopList;
            }
            if (currentState.referral && currentState.referral.sender
                && currentState.referral.sender.organizationId === action.optician.organizationId)
            {
                currentState.referral.sender = {...currentState.referral.sender, ...action.optician}
            }
            if (currentState.selectedOpticianShop
                && currentState.selectedOpticianShop.organizationId === action.optician.organizationId)
            {
                currentState.selectedOpticianShop = {...currentState.selectedOpticianShop, ...action.optician}
            }
            return currentState

        case UPDATE_CARE_UNIT_SUCCESS:
            if (currentState.careUnitList) {
                let updatedElementIndex = state.careUnitList.findIndex((cu) => cu.organizationId === action.careUnit.organizationId)
                let careUnitList = [...state.careUnitList]
                if (updatedElementIndex !== -1) {
                    careUnitList[updatedElementIndex] = action.careUnit;
                }
                currentState.careUnitList = careUnitList;
            }
            if (currentState.referral
                && currentState.referral.receiver
                && currentState.referral.receiver.organizationId === action.careUnit.organizationId)
            {
                currentState.referral.receiver = {...currentState.referral.receiver, ...action.careUnit}
            }
            if (currentState.selectedCareUnit
                && currentState.selectedCareUnit.organizationId === action.careUnit.organizationId)
            {
                currentState.selectedCareUnit = {...currentState.selectedCareUnit, ...action.careUnit}
            }
            return currentState

        case SET_REFERRAL_VALIDATION_ERRORS:
            return {
                ...state,
                sendReferralError: null,
                validationErrors: action.errors,
            }

        case LIST_CARE_UNITS_SUCCESS:
            return {
                ...state,
                careUnitList: action.careUnits,
                careUnitListError: null,
            }

        case LIST_CARE_UNITS_FAILURE:
            return {
                ...state,
                careUnitList: [],
                careUnitListError: errorMessage(action.error,
                    "Något gick fel när vårdenheter skulle hämtas."),
            }

        case LIST_OPTICIAN_SHOPS_SUCCESS:
            return {
                ...state,
                opticianShopList: action.opticianShops,
                opticianShopListError: null,
            }

        case LIST_OPTICIAN_SHOPS_FAILURE:
            return {
                ...state,
                opticianShopList: [],
                opticianShopListError: errorMessage(action.error,
                    "Något gick fel när optikerlista skulle hämtas."),
            }

        case UPDATE_REFERRAL_VALUE:
            let referral = {...state.referral};
            // The action.name holds the controlId value of the form, the action.value holds the value to set
            // If the controlId value has periods (.) in it we want to traverse that as a path in the state structure.
            // For example action.name="a.b" would mean update state value {a:{b=action.value}}

            let selectedCareUnit = state.selectedCareUnit;
            let selectedOpticianShop = state.selectedOpticianShop;

            if (action.name === 'receiver.organizationId') {
                if (action.value === '') {
                    selectedCareUnit = null;
                    referral.receiver = initialReferralState().referral.receiver;
                } else {
                    state.careUnitList.forEach((c) => {
                            if (c.organizationId === action.value) {
                                selectedCareUnit = c;
                                referral.receiver = c;
                            }
                        }
                    )
                }
            } else if (action.name === 'sender.organizationId') {
                if (action.value === '') {
                    selectedOpticianShop = null;
                    referral.sender = initialReferralState().referral.sender;
                } else {
                    state.opticianShopList.forEach((o) => {
                            if (o.organizationId === action.value) {
                                selectedOpticianShop = o;
                                referral.sender = o;
                            }
                        }
                    )
                }
            } else {
                // Split the name/id on periods
                // iterate the path down the object tree and
                // set the value when you reach the last sub-node
                let path = action.name.split('.');
                let currNode = referral;
                // console.log(path)
                path.forEach((subNode, i) => {
                    // console.log(subNode)
                    if (i < path.length - 1) {
                        currNode = currNode[subNode];
                    } else { // when i = path.length - 1 (i.e. the last path entry)
                        currNode[subNode] = action.value;
                    }
                })
            }
            return {
                ...state,
                selectedCareUnit,
                selectedOpticianShop,
                referral
            }
        case SEND_REFERRAL:
            return {
                ...state,
                resetDialogOpen: false,
                sendDialogOpen: false,
                sendReferralError: null,
                validationErrors: null,
                fileURL: null,
                fileCreatedDate: null,
                isSending: true,
            }
        case SEND_REFERRAL_SUCCESS:
            return {
                ...state,
                sendDialogOpen: false,
                resetDialogOpen: false,
                sendReferralError: null,
                validationErrors: null,
                fileURL: action.fileURL,
                fileCreatedDate: action.fileCreatedDate,
                isSending: false,
            }
        case SEND_REFERRAL_FAILURE:
            return {
                ...state,
                sendDialogOpen: false,
                resetDialogOpen: false,
                sendReferralError: action.message,
                fileURL: null,
                fileCreatedDate: null,
                isSending: false,
            }
        case RESET_REFERRAL:
            return {
                ...state,
                referral: {
                    ...initialReferralState().referral,
                    sender: state.referral.sender,
                    receiver: state.referral.receiver
                },
                fileURL: null,
                // selectedOpticianShop: null,
                validationErrors: null,
                sendReferralError: null,
                isSending: false,
            }
        case OPEN_RESET_DIALOG:
            return {
                ...state,
                resetDialogOpen: true,
            }
        case OPEN_SEND_DIALOG:
            return {
                ...state,
                sendDialogOpen: true,
            }
        case OPEN_CLINIC_INFO_DIALOG:
            return {
                ...state,
                clinicInfoDialogOpen: true,
            }
        case CLOSE_DIALOGS:
            return {
                ...state,
                clinicInfoDialogOpen: false,
                sendDialogOpen: false,
                resetDialogOpen: false,
            }
        default:
            return state;
    }
}
export default referral;