import React from 'react';
import { connect } from 'react-redux'
import { branch, compose, renderComponent } from 'recompose'
import { careUnitActions, careUnitSelectors} from './redux'
import { sessionSelectors } from '../session/redux'
import lifeCycle from './CareUnitListLifecycle'
import CareUnitList from "./CareUnitList";
import Loading from '../../components/Loading'

const mapStateToProps = (state) => ({
    careUnits: careUnitSelectors.careUnitList(state),
    createDialogOpen: careUnitSelectors.createDialogOpen(state),
    updateDialogOpen: careUnitSelectors.updateDialogOpen(state),
    deleteDialogOpen: careUnitSelectors.deleteDialogOpen(state),
    careUnitForm: careUnitSelectors.careUnitForm(state),
    saveCareUnitError: careUnitSelectors.saveCareUnitError(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
    errorMessage: careUnitSelectors.errorMessage(state),
    isLoading: careUnitSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    listCareUnits: () => dispatch(careUnitActions.listCareUnits()),
    deleteCareUnitClicked: (careUnitForm) => dispatch(careUnitActions.openDeleteDialog(careUnitForm)),
    updateCareUnitClicked: (careUnitForm) => dispatch(careUnitActions.openUpdateDialog(careUnitForm)),
    addCareUnitClicked: () => dispatch(careUnitActions.openCreateDialog()),
    closeDialogClicked: () => dispatch(careUnitActions.closeDialogs()),
    createCareUnit: (careUnitForm) => dispatch(careUnitActions.createCareUnit(careUnitForm)),
    updateCareUnit: (careUnitForm) => dispatch(careUnitActions.updateCareUnit(careUnitForm)),
    deleteCareUnit: (careUnitForm) => dispatch(careUnitActions.deleteCareUnit(careUnitForm))

});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    lifeCycle,
    branch(({isLoading, careUnits, errorMessage}) => isLoading || (!careUnits && !errorMessage),
        renderComponent(()=><Loading title={'Laddar...'}/>)
    )
)(CareUnitList)