import {
    LOG_IN,
    LOG_IN_SUCCESS,
    LOG_OUT,
    LOG_OUT_SUCCESS,
    SHOW_LOGIN_DIALOG,
    CLOSE_LOGIN_DIALOG,
    LOG_IN_FAIL,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    RESET_CHANGE_PASSWORD_FORM,
    EXTEND_SESSION_SUCCESS,
    RESET_FORGOT_PASSWORD_FORM,
    FORGOT_PASSWORD,
    RESET_USER_PASSWORD_FAILED, LOG_IN_OIDC_PKCE, GET_TOKEN_OIDC_PKCE, CHANGE_PASSWORD_OIDC_PKCE
} from './SessionActionTypes'
import store from 'store2'

export const initialSessionState = () => ({
    loggedInUser: store.session.get('ogonremiss.auth.loggedInUser') || null,
    organizationId: store.session.get('ogonremiss.auth.organizationId') || null,
    organizationName: store.session.get('ogonremiss.auth.organizationName') || null,
    jwt: store.session.get('ogonremiss.auth.jwt') || null,
    expiresUTC: store.session.get('ogonremiss.auth.expiresUTC') || null,
    roles: store.session.get('ogonremiss.auth.roles') || null,
    oidcPkceCodeChallenge: store.session.get('ogonremiss.auth.pkce') || null,
    refreshToken: store.session.get('ogonremiss.auth.refreshToken') || null,
    idToken: store.session.get('ogonremiss.auth.idToken') || null,
    loggingIn: false,
    loggingOut: false,
    loginDialogOpen: false,
    loginErrorMessage: null,
    forgotPasswordModel: {
        forgotPasswordError: null,
        forgotPasswordInfo: null,
        // forgotPasswordForm: {
        //     currentPassword: null,
        //     newPassword: null,
        //     newPasswordRetype: null,
        // }
    },
    changePasswordModel: {
        changePasswordError: null,
        changePasswordInfo: null,
        // changePasswordForm: {
        //     currentPassword: null,
        //     newPassword: null,
        //     newPasswordRetype: null,
        // }
    },
});

const session = (state = initialSessionState(), action) => {
    switch (action.type) {
        case LOG_IN_OIDC_PKCE:
            store.session.set('ogonremiss.auth.pkce', action.codeVerifier)
            console.log("setting pkce verifier", action.codeVerifier)
            return {
                ...state,
                loggingIn: true,
                oidcPkceCodeChallenge: action.codeVerifier
            }
        case CHANGE_PASSWORD_OIDC_PKCE:
            store.session.set('ogonremiss.auth.pkce', action.codeVerifier)
            console.log("setting pkce verifier", action.codeVerifier)
            return {
                ...state,
                oidcPkceCodeChallenge: action.codeVerifier
            }
        case GET_TOKEN_OIDC_PKCE:
            return {
                ...state,
                loggingIn: true,
            }
        case LOG_IN:
            return {
                ...state,
                loggingIn: true,
                loginErrorMessage: null,
            }
        case LOG_IN_SUCCESS:
            store.session.set('ogonremiss.auth.jwt', action.auth.jwt)
            store.session.set('ogonremiss.auth.loggedInUser', action.auth.username)
            store.session.set('ogonremiss.auth.organizationId', action.auth.organizationId)
            store.session.set('ogonremiss.auth.organizationName', action.auth.organizationName)
            store.session.set('ogonremiss.auth.expiresUTC', action.auth.expiresUTC)
            store.session.set('ogonremiss.auth.roles', action.auth.roles)
            store.session.set('ogonremiss.auth.sid', action.auth.sid)
            store.session.set('ogonremiss.auth.refreshToken', action.auth.refreshToken)
            store.session.set('ogonremiss.auth.idToken', action.auth.idToken)
            return {
                ...state,
                loggedInUser: action.auth.username,
                organizationId: action.auth.organizationId,
                organizationName: action.auth.organizationName,
                jwt: action.auth.jwt,
                expiresUTC: action.auth.expiresUTC,
                roles: action.auth.roles,
                loggingIn: false,
                loginDialogOpen: false,
                sid: action.auth.sid,
                refreshToken: action.auth.refreshToken,
                idToken: action.auth.idToken
            }
        case EXTEND_SESSION_SUCCESS:
            store.session.set('ogonremiss.auth.jwt', action.auth.jwt)
            store.session.set('ogonremiss.auth.loggedInUser', action.auth.username)
            store.session.set('ogonremiss.auth.organizationId', action.auth.organizationId)
            store.session.set('ogonremiss.auth.organizationName', action.auth.organizationName)
            store.session.set('ogonremiss.auth.expiresUTC', action.auth.expiresUTC)
            store.session.set('ogonremiss.auth.roles', action.auth.roles)
            store.session.set('ogonremiss.auth.sid', action.auth.sid)
            store.session.set('ogonremiss.auth.refreshToken', action.auth.refreshToken)
            store.session.set('ogonremiss.auth.idToken', action.auth.idToken)
            return {
                ...state,
                loggedInUser: action.auth.username,
                organizationId: action.auth.organizationId,
                organizationName: action.auth.organizationName,
                jwt: action.auth.jwt,
                expiresUTC: action.auth.expiresUTC,
                roles: action.auth.roles,
                loggingIn: false,
                loginDialogOpen: false,
                sid: action.auth.sid,
                refreshToken: action.auth.refreshToken,
                idToken: action.auth.idToken
            }
        case LOG_IN_FAIL:
            store.namespace('ogonremiss.auth').clearAll()
            console.log('LOGIN FAIL', state.loginDialogOpen)
            return {
                ...state,
                loggingIn: false,
                loggedInUser: null,
                organizationId: null,
                organizationName: null,
                roles: null,
                jwt: null,
                refreshToken: null,
                idToken: null,
                sid: null,
                expiresUTC: null,
                loggingOut: false,
                loginErrorMessage: 'Inloggning misslyckades, kontrollera uppgifterna och testa igen.',
            }
        case LOG_OUT:
            return {
                ...state,
                loggingOut: true,
            }
        case LOG_OUT_SUCCESS:
            store.namespace('ogonremiss.auth').clearAll()
            return {
                ...state,
                loggedInUser: null,
                organizationId: null,
                organizationName: null,
                roles: null,
                jwt: null,
                refreshToken: null,
                idToken: null,
                sid: null,
                expiresUTC: null,
                loggingOut: false,
            }
        case SHOW_LOGIN_DIALOG:
            console.log('SHOW LOGIN DIALOG')
            return {
                ...state,
                loginDialogOpen: true,
                loginErrorMessage: null,
            }
        case CLOSE_LOGIN_DIALOG:
            return {
                ...state,
                loginDialogOpen: false,
                loginErrorMessage: null,
            }
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordModel: {
                    ...state.changePasswordModel,
                    changePasswordError: action.errorMessage,
                    changePasswordInfo: null,
                    // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
                }
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordModel: {
                    ...state.changePasswordModel,
                    changePasswordError: null,
                    changePasswordInfo: "Lösenordet uppdaterades",
                    // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
                }
            }
        case RESET_CHANGE_PASSWORD_FORM:
            return {
                ...state,
                changePasswordModel: {
                    ...state.changePasswordModel,
                    changePasswordError: null,
                    changePasswordInfo: null,
                    // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
                }
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                loginDialogOpen: false,
            }
        case RESET_FORGOT_PASSWORD_FORM:
            return {
                ...state,
                forgotPasswordModel: {
                    ...state.forgotPasswordModel,
                    forgotPasswordError: null,
                    forgotPasswordInfo: null,
                    // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
                }
            }
        case RESET_USER_PASSWORD_FAILED:
            return {
                ...state,
                forgotPasswordModel: {
                    ...state.forgotPasswordModel,
                    forgotPasswordError: action.errorMessage,
                    forgotPasswordInfo: null,
                    // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
                }
            }
        // case RESET_USER_PASSWORD_FAILED:
        //     return {
        //         ...state,
        //         forgotPasswordModel: {
        //             ...state.forgotPasswordModel,
        //             forgotPasswordError: action.errorMessage,
        //             forgotPasswordInfo: null,
        //             // changePasswordForm: initialUsersState().changePasswordModel.changePasswordForm,
        //         }
        //     }
        default:
            return state;
    }
}
export default session;