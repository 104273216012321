import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const SaveCareUnitDialog = ({
        saveErrorMessage,
        onSaveClicked,
        onUpdateClicked,
        onCancelClicked,
        careUnitForm: {
            emailUsername,
            emailUsernameRepetition,
            organizationId,
            hsaId,
            name,
            streetAddress,
            zipCode,
            city,
            contactName,
            contactPhoneNumber,
            clinicInfo,
            externalUserId
        }
    }) => {

    var emailUsernameInputRef;
    var emailUsernameRepetitionInputRef;
    var careUnitNameInputRef;
    let hsaIdInputRef;
    var streetAddressInputRef;
    var zipCodeInputRef;
    var cityInputRef;
    var contactNameInputRef;
    var contactPhoneInputRef;
    var clinicInfoInputRef;
    let externalUserIdInputRef;
    var saveButton;

    const performUpdate = () => {
        onUpdateClicked(
        {
            organizationId: organizationId,
            // emailUsername: emailUsernameInputRef.value,
            // emailUsernameRepetition: emailUsernameRepetitionInputRef.value,
            name: careUnitNameInputRef.value,
            hsaId: hsaIdInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
            clinicInfo: clinicInfoInputRef.value,
            externalUserId: externalUserIdInputRef.value
        });
    }

    const performSave = () => {
        onSaveClicked( {
            organizationId: null,
            emailUsername: emailUsernameInputRef.value,
            emailUsernameRepetition: emailUsernameRepetitionInputRef.value,
            name: careUnitNameInputRef.value,
            hsaId: hsaIdInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
            clinicInfo: clinicInfoInputRef.value,
            externalUserId: externalUserIdInputRef.value
        });
    }

    const significantFieldChanged = () => {
        if (saveButton) {
            saveButton.disabled = !(careUnitNameInputRef.value && careUnitNameInputRef.value.length > 0)
        }
    }

    return (
        <Modal
            show={true}
            onHide={onCancelClicked}
            backdrop="static"
            keyboard={true}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
        >
    <div className="SaveCareUnitDialog">
                <Modal.Header closeButton id="contained-modal-title-vcenter">
                    {(name &&
                        <ModalTitle>Uppdatera vårdenhet</ModalTitle>)
                            ||
                        <ModalTitle>Skapa vårdenhet</ModalTitle>
                    }
                </Modal.Header>
                <Modal.Body>

                    {saveErrorMessage &&
                        <Alert bsStyle="danger">{saveErrorMessage}</Alert>
                    }
                    <Form id="saveCareUnitForm"
                          className="SaveCareUnitForm"
                          // style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                              performSave();
                          }}>
                            <Row>
                                <Col sm={12}>

                                <FormGroup controlId="formHorizontalEmailUsername">
                                    <ControlLabel>Email-adress/användarnamn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     emailUsernameInputRef = ref
                                                     if (ref) {
                                                         ref.focus();
                                                     }
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         emailUsernameRepetitionInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 disabled={name}
                                                 value={emailUsername}
                                                 placeholder="Email-adress"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalEmailUsernameRepetition">
                                    <ControlLabel>Repetera email-adress/användarnamn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     emailUsernameRepetitionInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         careUnitNameInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 disabled={name}
                                                 value={emailUsernameRepetition}
                                                 placeholder="Email-adress (repetera)"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalCareUnitName">
                                    <ControlLabel>Vårdenhetens namn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     careUnitNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         hsaIdInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 // disabled={name}
                                                 defaultValue={name}
                                                 placeholder="Vårdenhetens namn"
                                                 />
                                </FormGroup>
                                    <FormGroup controlId="formHorizontalOpticianHsaId">
                                        <ControlLabel>HSA-id</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         hsaIdInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             streetAddressInputRef.focus();
                                                         }
                                                     }}
                                                     onChange={()=>significantFieldChanged()}
                                            // disabled={name}
                                                     defaultValue={hsaId}
                                                     placeholder="HSA-id"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalStreetAddress">
                                        <ControlLabel>Gatuadress</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         streetAddressInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             zipCodeInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={streetAddress}
                                                     placeholder="Ex: Sjukhusvägen 15B 2tr"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalZipCode">
                                        <ControlLabel>Postnummer</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         zipCodeInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             cityInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={zipCode}
                                                     placeholder="Ex: 112 12"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalCity">
                                        <ControlLabel>Stad</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         cityInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             contactNameInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={city}
                                                     placeholder="Stad"
                                        />
                                    </FormGroup>
                        <FormGroup controlId="formHorizontalContactName">
                            <ControlLabel>Namn på kontaktperson</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactNameInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 contactPhoneInputRef.focus();
                                             }
                                         }}
                                         defaultValue={contactName}
                                         placeholder="För- och efternamn"
                                        />
                        </FormGroup>
                        <FormGroup controlId="formHorizontalContactPhone">
                            <ControlLabel>Telefonnummer för kontakt</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactPhoneInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 clinicInfoInputRef.focus();
                                             }
                                         }}
                                         defaultValue={contactPhoneNumber}
                                         placeholder="Telefonnummer"
                                         />
                        </FormGroup>
                        <FormGroup controlId="formHorizontalClinicInfo">
                            <ControlLabel>Klinikinformation</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             clinicInfoInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                           if (e.charCode === 13) {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             externalUserIdInputRef.focus();
                                           }
                                         }}
                                         defaultValue={clinicInfo}
                                         placeholder="Klinikspecifik information till optiker"
                            />
                        </FormGroup>
                                  <FormGroup controlId="formHorizontalExternalId">
                                    <ControlLabel>Externt användar-ID</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                   externalUserIdInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                   if (e.charCode === 13) {
                                                     e.preventDefault();
                                                     e.stopPropagation();
                                                     if (onSaveClicked) {
                                                       performSave();
                                                     } else if (onUpdateClicked) {
                                                       performUpdate();
                                                     }
                                                   }
                                                 }}
                                                 defaultValue={externalUserId}
                                                 placeholder="Externt användar-ID"
                                    />
                                  </FormGroup>
                                </Col>
                            </Row>

                        <FormGroup>
                            <Row>
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}} >
                                    <Button bsStyle={'danger'} onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    {(onUpdateClicked &&
                                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                            performUpdate();
                                        }}>
                                            Uppdatera</Button>)
                                    ||
                                        <FormControl type={"submit"}
                                                     disabled
                                                     bsStyle={'primary'}
                                                     style={{marginLeft: '5px'}}
                                                     value={"Skapa"}
                                                     inputRef={(ref) => saveButton = ref}
                                                     onClick={(e) => {
                                                         e.preventDefault();
                                                         e.stopPropagation();

                                                     }}>
                                        </FormControl>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>
    </div>
        </Modal>
    )}
export default SaveCareUnitDialog;