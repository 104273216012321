// var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
import 'core-js/fn/object/assign';
import 'core-js/fn/promise';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'rxjs';
import 'datejs'
import Root from './Root';
import configureStore from './configureStore'
import config from 'react-global-configuration';
// eslint-disable-next-line
import moment from 'moment';
// eslint-disable-next-line
import momentLocale from 'moment/locale/sv';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import sv from "date-fns/locale/sv";

let debug = false;
if(window.location.hash) {
    let hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
    if (hash === 'debug') {
        debug = true;
    }
}

const keycloakDevPort = 8088;
if (window.location.host.startsWith('localhost:')) {
    config.set({
        apiBaseUrl: (window.location.host === 'localhost:8080') ? '/api' : 'http://localhost:8080/api',
        // authServerBaseUrl: 'http://localhost:' + keycloakDevPort,
        authServerBaseUrl: 'https://auth.local',
        appBaseUrl: 'http://'+window.location.host,
        debug,
    });
} else if (window.location.host === 'ogonremiss.local') {
    config.set({
        apiBaseUrl: '/api',
        authServerBaseUrl: 'https://auth.local',
        appBaseUrl: 'https://ogonremiss.local',
        debug,
    });
} else {
    config.set({
        // Apply different configuration when we develop locally (i.e. from localhost:3000 we want to call localhost:8080)
        apiBaseUrl: '/api',
        authServerBaseUrl: 'https://auth.ogonremiss.se',
        appBaseUrl: 'https://app.ogonremiss.se',
        debug,
    });
}
// config.set({
//     // Apply different configuration when we develop locally (i.e. from localhost:3000 we want to call localhost:8080)
//     apiBaseUrl: (window.location.host === 'localhost:3000') || (window.location.host === 'localhost:3001') ? 'http://localhost:8080/api' : '/api',
//     authServerBaseUrl: window.location.host.startsWith('localhost:') ? 'http://localhost:' + keycloakDevPort : 'https://auth.ogonremiss.se',
//     appBaseUrl: window.location.host.startsWith('localhost:') ? 'http://'+window.location.host : 'https://app.ogonremiss.se',
//     debug,
// });

if (!config.get('debug')) {
    console.log = (log) => {/*noop*/};
}

registerLocale('se', sv)
setDefaultLocale('se')

console.log("moment locale: " + moment.locale());
const store = configureStore();

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
