import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const LogInDialog = ({loginErrorMessage, onLogInClicked, loginDialogOpen, onCancelClicked, onForgotClicked}) => {
    var usernameInputRef;
    var passwordInputRef;
    return (
    <div className="LogInDialog">
        <Modal show={loginDialogOpen} >
            <Modal.Header>
                <Modal.Title>Logga in och skicka en remiss</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    {loginErrorMessage &&
                        <Alert bsStyle="danger">{loginErrorMessage}</Alert>
                    }
                    <Form id="logInForm"
                          className="LogInForm"
                          // style={{width: '360px'}}
                    >
                            <Row>
                                <Col sm={12}>
                        <FormGroup controlId="formHorizontalEmail">
                                    <ControlLabel>Användarnamn</ControlLabel>
                                    <FormControl type="text"
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         passwordInputRef.focus();
                                                     }
                                                 }}
                                                 placeholder="Användarnamn"
                                                 inputRef={(ref) => {
                                                     usernameInputRef = ref
                                                     if (ref) {
                                                         ref.focus();
                                                     }
                                                 }}/>
                        </FormGroup>

                        <FormGroup controlId="formHorizontalPassword">
                                    <ControlLabel>Lösenord</ControlLabel>
                                    <FormControl type="password" placeholder="Lösenord"
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         onLogInClicked(usernameInputRef.value, passwordInputRef.value);
                                                     }
                                                 }}
                                                 inputRef={(ref) => {
                                                     passwordInputRef = ref
                                                 }}
                                    />
                        </FormGroup>
                                </Col>
                            </Row>

                        <FormGroup>
                            <Row>
                                {/*<Col smOffset={5} sm={5} xs={5} md={5} lg={5}>*/}
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}} >
                                    <Button onClick={() => {
                                        (usernameInputRef.value
                                        && typeof usernameInputRef.value === 'string'
                                        && usernameInputRef.length > 0) ?
                                            onForgotClicked(usernameInputRef.value)
                                            :onForgotClicked()
                                    }} style={{marginLeft: '5px'}}>
                                        Glömt lösenord?
                                    </Button>
                                    <Button onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                        onLogInClicked(usernameInputRef.value, passwordInputRef.value)
                                    }}>
                                        Logga in
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
            </Modal.Body>
        </Modal>
    </div>
    )}
export default LogInDialog;