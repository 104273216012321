export const CREATE_OPTICIAN = 'optician.CREATE_OPTICIAN';
export const CREATE_OPTICIAN_SUCCESS = 'optician.CREATE_OPTICIAN_SUCCESS';
export const CREATE_OPTICIAN_FAILURE = 'optician.CREATE_OPTICIAN_FAILURE';
export const UPDATE_OPTICIAN = 'optician.UPDATE_OPTICIAN';
export const UPDATE_OPTICIAN_SUCCESS = 'optician.UPDATE_OPTICIAN_SUCCESS';
export const UPDATE_OPTICIAN_FAILURE = 'optician.UPDATE_OPTICIAN_FAILURE';
export const DELETE_OPTICIAN = 'optician.DELETE_OPTICIAN';
export const DELETE_OPTICIAN_SUCCESS = 'optician.DELETE_OPTICIAN_SUCCESS';
export const DELETE_OPTICIAN_FAILURE = 'optician.DELETE_OPTICIAN_FAILURE';
export const LIST_OPTICIANS = 'optician.LIST_OPTICIANS';
export const LIST_OPTICIANS_SUCCESS = 'optician.LIST_OPTICIANS_SUCCESS';
export const LIST_OPTICIANS_FAILURE = 'optician.LIST_OPTICIANS_FAILURE';
export const LOAD_OPTICIAN = 'optician.LOAD_OPTICIAN';
export const LOAD_OPTICIAN_SUCCESS = 'optician.LOAD_OPTICIAN_SUCCESS';
export const LOAD_OPTICIAN_FAILURE = 'optician.LOAD_OPTICIAN_FAILURE';
export const OPEN_DELETE_DIALOG = 'optician.OPEN_DELETE_DIALOG';
export const OPEN_UPDATE_DIALOG = 'optician.OPEN_UPDATE_DIALOG';
export const OPEN_RESET_DIALOG = 'optician.OPEN_RESET_DIALOG';
export const OPEN_CREATE_DIALOG = 'optician.OPEN_CREATE_DIALOG';
export const OPEN_PROFILE_DIALOG = 'optician.OPEN_PROFILE_DIALOG';
export const CLOSE_DIALOGS = 'optician.CLOSE_DIALOGS';
export const RESET_OPTICIAN_USER_PASSWORD = 'optician.RESET_OPTICIAN_USER_PASSWORD';
export const RESET_OPTICIAN_USER_PASSWORD_SUCCESS = 'optician.RESET_OPTICIAN_USER_PASSWORD_SUCCESS';
export const RESET_OPTICIAN_USER_PASSWORD_FAILURE = 'optician.RESET_OPTICIAN_USER_PASSWORD_FAILURE';