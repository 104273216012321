import {
    CREATE_OPTICIAN, CREATE_OPTICIAN_SUCCESS,
    UPDATE_OPTICIAN, UPDATE_OPTICIAN_SUCCESS,
    DELETE_OPTICIAN, DELETE_OPTICIAN_SUCCESS,
    LIST_OPTICIANS, RESET_OPTICIAN_USER_PASSWORD, LOAD_OPTICIAN,
} from './OpticianActionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import config from 'react-global-configuration';
import {opticianActions} from './'
import {
    deleteWithAuth,
    errorHandlingWithLogoutCheck,
    getJsonWithAuth,
    postJsonWithAuth,
    putJsonWithAuth
} from "../../../components/AjaxHelpers";

export const opticianEpics = combineEpics(

    // Events of type LIST_OPTICIANS
    (action$, state$) => action$.ofType(LIST_OPTICIANS).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1',
                state: state$.value
            }).pipe(
                map(payload => opticianActions.listOpticiansSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response,
                    [opticianActions.listOpticiansFailed(response)],
                    "Något gick fel när lista av optiker skulle hämtas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(LOAD_OPTICIAN).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1/' + action.organizationId,
                state: state$.value
            }).pipe(
                map(payload => opticianActions.loadOpticianSucceeded(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error,
                    [opticianActions.loadOpticianFailed(error)],
                    "Något gick fel när optikerdata skulle laddas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_OPTICIAN).pipe(
        switchMap(action =>
            postJsonWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1',
                body: {
                    ...action.opticianForm,
                    organizationId: null,
                },
                state: state$.value
            }).pipe(
                map(payload => opticianActions.createOpticianSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response,
                    [opticianActions.createOpticianFailed(response)],
                    "Något gick fel när optiker skulle skapas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_OPTICIAN_SUCCESS).pipe(
        map(action => opticianActions.listOpticians())
    ),


    (action$, state$) => action$.ofType(UPDATE_OPTICIAN).pipe(
        switchMap(action =>
            putJsonWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1/' + action.opticianForm.organizationId,
                body: {
                    ...action.opticianForm,
                },
                state: state$.value
            }).pipe(
                map(ajaxResponse => opticianActions.updateOpticianSucceeded(ajaxResponse.response)),
                catchError(response => errorHandlingWithLogoutCheck(response, [opticianActions.updateOpticianFailed(response)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(UPDATE_OPTICIAN_SUCCESS).pipe(
        map(action => opticianActions.listOpticians())
    ),

    (action$, state$) => action$.ofType(RESET_OPTICIAN_USER_PASSWORD).pipe(
        switchMap(action =>
            deleteWithAuth({
                url: config.get('apiBaseUrl') + '/user/v1/users/' + action.opticianForm.emailUsername + '/password',
                state: state$.value
            }).pipe(
                map(payload => opticianActions.resetOpticianUserPasswordSucceeded(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error,
                    [opticianActions.resetOpticianUserPasswordFailed(error)],
                    "Något gick fel när användarens lösenord skulle nollställas."))
            )
        )
    ),


    (action$, state$) => action$.ofType(DELETE_OPTICIAN).pipe(
        switchMap(action =>
            deleteWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1/' + action.opticianForm.organizationId,
                state: state$.value
            }).pipe(
                map(payload => opticianActions.deleteOpticianSucceeded()),
                catchError(response => errorHandlingWithLogoutCheck(response, [opticianActions.deleteOpticianFailed(response)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(DELETE_OPTICIAN_SUCCESS).pipe(
        map(action => opticianActions.listOpticians())
    ),


);