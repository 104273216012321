import React from 'react'
import logo from '../images/logo.png'
import './Menu.css'
import { Image, Nav, Navbar, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SessionMenuItemContainer from "../domains/session/SessionMenuItemContainer";
import SecuredContainer from "../domains/session/SecuredContainer";

const Menu = () => (
    <div className="Menu">
        <Navbar inverse collapseOnSelect>
            <Navbar.Header>
                <Navbar.Brand>
                    <LinkContainer to="/"><a href="/"><Image src={logo} responsive/></a></LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle/>
            </Navbar.Header>
            <Navbar.Collapse>
                <Nav pullRight>
                    {/*<InfoContainer/>*/}
                    <SessionMenuItemContainer />
                </Nav>
                {/*<SecuredContainer allowedRoles={["ADMIN"]}>*/}
                    {/*<Nav pullRight>*/}
                        {/*<LinkContainer to="/anvandare"><NavItem eventKey={6}>Användare</NavItem></LinkContainer>*/}
                        {/*/!*<LinkContainer to="/organizations"><NavItem eventKey={5}>Optiker</NavItem></LinkContainer>*!/*/}
                    {/*</Nav>*/}
                {/*</SecuredContainer>*/}
                <SecuredContainer allowedRoles={["ADMIN"]}>
                    <Nav pullRight>
                        <LinkContainer to="/vardenheter"><NavItem eventKey={5}>Vårdenheter</NavItem></LinkContainer>
                    </Nav>
                </SecuredContainer>
                <SecuredContainer allowedRoles={["ADMIN","CARE_UNIT"]}>
                    <Nav pullRight>
                        <LinkContainer to="/optiker"><NavItem eventKey={4}>Optiker</NavItem></LinkContainer>
                    </Nav>
                </SecuredContainer>
                <SecuredContainer allowedRoles={["ADMIN", "OPTICIAN"]}>
                    <Nav pullRight>
                        <LinkContainer to="/remiss"><NavItem eventKey={3}>Remiss</NavItem></LinkContainer>
                    </Nav>
                </SecuredContainer>
                <Nav pullRight>
                    <NavItem href="/info/#omoss">Om oss</NavItem>
                    <NavItem href="/info/#kontakt">Kontakt</NavItem>
                    {/*<IndexLinkContainer to="/#omoss"><NavItem eventKey={1}>Om oss</NavItem></IndexLinkContainer>*/}
                    {/*<LinkContainer to="/#kontakt"><NavItem eventKey={2}>Kontakt</NavItem></LinkContainer>*/}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>
)

export default Menu