import { connect } from 'react-redux'
import { compose } from 'recompose'
import { sessionActions, sessionSelectors} from './redux'
import lifeCycle from './ChangePasswordLifeCycle'
import ChangePassword from "./ChangePassword";
import {withRouter} from "react-router";

const mapStateToProps = (state, { match: { params: { username, otp, }, url}}) => {
    return ({
    url: url,
    username: username,
    otp: otp,
    changePasswordModel: sessionSelectors.changePasswordModel(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
})};

const mapDispatchToProps = (dispatch) => ({
    resetChangePasswordForm: () => dispatch(sessionActions.resetChangePasswordForm()),
    // updateChangePasswordForm: (fieldName, fieldValue) => dispatch(sessionActions.updateChangePasswordForm(fieldName, fieldValue)),
    performChangePassword: (username, currentPassword, newPassword, retypeNewPassword) => dispatch(sessionActions.changePassword(username, currentPassword, newPassword, retypeNewPassword)),
    performActivateAccount: (username, oneTimePassword, newPassword, retypeNewPassword) => dispatch(sessionActions.activateAccount(username, oneTimePassword, newPassword, retypeNewPassword)),
});

export default compose (
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    lifeCycle,
)(ChangePassword)