import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const CareUnitProfile = ({
        isOpen,
        errorMessage,
        updateCareUnit,
        closeDialog,
        careUnitForm
    }) => {

    let careUnitNameInputRef;
    let streetAddressInputRef;
    let zipCodeInputRef;
    let cityInputRef;
    let contactNameInputRef;
    let contactPhoneInputRef;
    let clinicInfoInputRef;
    let saveButton;
    let organizationId = careUnitForm && careUnitForm.organizationId;


    const performUpdate = () => {
        updateCareUnit(
        {
            organizationId: organizationId,
            name: careUnitNameInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
            clinicInfo: clinicInfoInputRef.value
        });
    }

    const significantFieldChanged = () => {
        if (saveButton) {
            saveButton.disabled = !(careUnitNameInputRef.value && careUnitNameInputRef.value.length > 0)
        }
    }

    return (
        isOpen &&
        (<Modal
            show={true}
            onHide={closeDialog}
            backdrop="static"
            keyboard={true}
            size="lg"
        >
            <div className="CareUnitProfile">
                <ModalHeader>
                    <ModalTitle>Uppdatera användarprofil</ModalTitle>
                </ModalHeader>
                <Modal.Body>
                    {errorMessage &&
                    <Alert bsStyle="danger">{errorMessage}</Alert>
                    }
                    <Form id="saveCareUnitForm" className="CareUnitProfileForm"
                          onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              performUpdate();
                          }}>
                        <Row>
                            <Col sm={12}>

                                {/*<FormGroup controlId="formHorizontalEmailUsername">*/}
                                    {/*<ControlLabel>Email-adress/användarnamn</ControlLabel>*/}
                                    {/*<FormControl type="text"*/}
                                                 {/*inputRef={(ref) => {*/}
                                                     {/*emailUsernameInputRef = ref*/}
                                                     {/*if (ref) {*/}
                                                         {/*ref.focus();*/}
                                                     {/*}*/}
                                                 {/*}}*/}
                                                 {/*onKeyPress={(e) => {*/}
                                                     {/*if (e.charCode === 13) {*/}
                                                         {/*e.preventDefault();*/}
                                                         {/*e.stopPropagation();*/}
                                                         {/*emailUsernameRepetitionInputRef.focus();*/}
                                                     {/*}*/}
                                                 {/*}}*/}
                                                 {/*onChange={() => significantFieldChanged()}*/}
                                                 {/*disabled={opticianForm.name}*/}
                                                 {/*value={opticianForm.emailUsername}*/}
                                                 {/*placeholder="Email-adress"*/}
                                    {/*/>*/}
                                {/*</FormGroup>*/}
                                <FormGroup controlId="formHorizontalCareUnitName">
                                    <ControlLabel>Vårdenhetens namn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     careUnitNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         streetAddressInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={() => significantFieldChanged()}
                                        // disabled={name}
                                                 defaultValue={careUnitForm.name}
                                                 placeholder="Vårdenhetens namn"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalStreetAddress">
                                    <ControlLabel>Gatuadress</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     streetAddressInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         zipCodeInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={careUnitForm.streetAddress}
                                                 placeholder="Ex: Sjukhusvägen 15B 2tr"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalZipCode">
                                    <ControlLabel>Postnummer</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     zipCodeInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         cityInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={careUnitForm.zipCode}
                                                 placeholder="Ex: 112 12"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalCity">
                                    <ControlLabel>Stad</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     cityInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         contactNameInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={careUnitForm.city}
                                                 placeholder="Stad"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalContactName">
                                    <ControlLabel>Namn på kontaktperson</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     contactNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         contactPhoneInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={careUnitForm.contactName}
                                                 placeholder="För- och efternamn"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalContactPhone">
                                    <ControlLabel>Telefonnummer för kontakt</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     contactPhoneInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         clinicInfoInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={careUnitForm.contactPhoneNumber}
                                                 placeholder="Telefonnummer"
                                    />
                                    <FormGroup controlId="formHorizontalClinicInfo">
                                        <ControlLabel>Klinikinformation</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         clinicInfoInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             performUpdate();
                                                         }
                                                     }}
                                                     defaultValue={careUnitForm.clinicInfo}
                                                     placeholder="Klinikspecifik information till optiker"
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Row>
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button bsStyle={'danger'} onClick={closeDialog} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                        performUpdate();
                                    }}>Spara</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>)
    )}
export default CareUnitProfile;