import {
    SEND_REFERRAL, LIST_CARE_UNITS, LIST_OPTICIAN_SHOPS
} from './ReferralActionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import config from 'react-global-configuration';
import {referralActions} from './'
import {push} from 'connected-react-router';
import {referral} from './ReferralSelectors'
import {
    errorHandlingWithLogoutCheck,
    getJsonWithAuth,
    postJsonWithAuth,
} from "../../../components/AjaxHelpers";

export const referralEpics = combineEpics(

    // Events of type LIST_CARE_UNITS
    (action$, state$) => action$.ofType(LIST_CARE_UNITS).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/careUnits/v1',
                state: state$.value
            }).pipe(
                map(payload => referralActions.listCareUnitsSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response, [referralActions.listCareUnitsFailed(response), push('/error')]))
            )
        )
    ),

    // Events of type LIST_OPTICIAN_SHOPS
    (action$, state$) => action$.ofType(LIST_OPTICIAN_SHOPS).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/opticians/v1',
                state: state$.value
            }).pipe(
                map(payload => referralActions.listOpticianShopsSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response, [referralActions.listOpticianShopsFailed(response), push('/error')]))
            )
        )
    ),


    (action$, state$) => action$.ofType(SEND_REFERRAL).pipe(
        switchMap(action =>
            postJsonWithAuth({
                url: config.get('apiBaseUrl') + '/referrals/v1',
                body: {
                    ...referral(state$.value)
                },
                state: state$.value,
                responseType: 'blob', //maps to otherParams
            }).pipe(
                map(payload => referralActions.sendReferralSuccess(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error, [referralActions.sendReferralFailure(error)]))
            )
        )
    ),

);