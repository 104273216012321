// import {
//     GLOBAL_INFO
// } from './GlobalActionTypes';
// import { flatMap, switchMap, map, catchError, delay, mapTo, takeUntil, filter } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
// import config from 'react-global-configuration';
// import {globalActions} from './'
// import {
//     deleteWithAuth,
//     errorHandlingWithLogoutCheck,
//     getJsonWithAuth,
//     postJsonWithAuth,
//     putJsonWithAuth
// } from "../../../components/AjaxHelpers";

export const globalEpics = combineEpics(

    // Events of type HEARTBEAT
    // (action$, state$) => action$.ofType(HEARTBEAT).pipe(
    //     switchMap(action =>
    //         getJsonWithAuth({
    //             url: config.get('apiBaseUrl') + '/opticians/v1',
    //             state: state$.value
    //         }).pipe(
    //             map(payload => opticianActions.listOpticiansSucceeded(payload)),
    //             catchError(response => errorHandlingWithLogoutCheck(response, [opticianActions.listOpticiansFailed(response)]))
    //         )
    //     )
    // ),

);