import {lifecycle} from 'recompose';

export default lifecycle({

  componentDidUpdate() {
  },

  componentWillReceiveProps(newProps) {
    console.log("newProps", newProps);
      if (newProps.oidcPkceCodeChallenge && this.props.loggedInUser !== newProps.loggedInUser) {
        // component={(props, b) => {
        //   console.log('CATCH', props, b)
        //   const params = new URLSearchParams(props.location.search);
        //   const sessionState = params.get('session_state');
        //   const code = params.get('code'); // bar
        //   console.log('sessionState', sessionState);
        //   console.log('code', code);
        // }}
      }
      if (newProps.loggedInUser && this.props.loggedInUser !== newProps.loggedInUser) {
          this.props.onSessionInitialized(newProps.loggedInUser, newProps.roles);
      }
  },

  componentDidMount() {
      if(this.props.loggedInUser) {
          this.props.onSessionInitialized(this.props.loggedInUser, this.props.roles);
      }
  }

});