import React from 'react'
import {Table, Button, Glyphicon, Row, Col, Alert} from 'react-bootstrap'
import './OpticianList.css'
import SaveOpticianDialog from './SaveOpticianDialog';
import DeleteOpticianDialog from "./DeleteOpticianDialog";
import Grid from "react-bootstrap/es/Grid";
import ResetOpticianDialog from "./ResetOpticianDialog";

const OpticianList = ({opticians,
                          createDialogOpen,
                          updateDialogOpen,
                          deleteDialogOpen,
                          resetDialogOpen,
                          opticianForm,
                          careUnits,
                          saveOpticianError, loggedInOptician,
                        addOpticianClicked, updateOpticianClicked, deleteOpticianClicked, resetOpticianClicked, closeDialogClicked,
                        deleteOptician, createOptician, updateOptician, resetOpticianUserPassword, onOpticianValueChange, errorMessage
                  }) => {

    const openUpdateOptician = (org) => {
        updateOpticianClicked(org)
    }

    const openResetOptician = (org) => {
        resetOpticianClicked(org)
    }

    const openCreateOptician = () => {
        addOpticianClicked()
    }

    const openDeleteOptician = (org) => {
        deleteOpticianClicked(org)
    }

    return (
    <div className="OpticianList">
        <Grid id={"opticianMainGrid"}>
            {errorMessage &&
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Alert bsStyle={"danger"}>
                            <b><Glyphicon glyph={"exclamation-sign"}/> {errorMessage}</b>
                        </Alert>
                    </Col>
                </Row>
            }
                <Row>
                <Col xs={8} md={8} lg={8}>
                    <h1>Optiker</h1>
                </Col>
                <Col xs={4} md={4} lg={4}>
                    <span className={"pull-right"}>
                        <Button onClick={()=>openCreateOptician()}><Glyphicon glyph={"plus"}/> Lägg till optiker</Button><br/>
                    </span>
                </Col>
            </Row>
        </Grid>


        { (deleteDialogOpen &&
            <DeleteOpticianDialog deleteErrorMessage={saveOpticianError}
                                  onCancelClicked={closeDialogClicked}
                                  onDeleteClicked={deleteOptician}
                                  opticianForm={opticianForm}
            />)
        || (updateDialogOpen &&
            <div>
                <SaveOpticianDialog saveErrorMessage={saveOpticianError}
                                    onCancelClicked={closeDialogClicked}
                                    onUpdateClicked={updateOptician}
                                    opticianForm={opticianForm}
                                    careUnits={careUnits}
                />
            </div>)
        || (createDialogOpen &&
                <SaveOpticianDialog saveErrorMessage={saveOpticianError}
                                    opticianForm={{}}
                                    careUnits={careUnits}
                                    onCancelClicked={closeDialogClicked}
                                    onSaveClicked={createOptician} />)
        || (resetDialogOpen &&
            <ResetOpticianDialog resetErrorMessage={saveOpticianError}
                                 onCancelClicked={closeDialogClicked}
                                 onResetClicked={resetOpticianUserPassword}
                                 opticianForm={opticianForm} />)
        }
        <Table condensed hover responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>Namn</th>
                <th>Kontaktperson</th>
                <th>Telefon kontakt</th>
                <th>Gatuadress</th>
                <th>Postnummer</th>
                <th>Stad</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                opticians && opticians.map((org, index) =>
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{org.name}</td>
                        <td>{org.contactName}</td>
                        <td>{org.contactPhoneNumber}</td>
                        <td>{org.streetAddress}</td>
                        <td>{org.zipCode}</td>
                        <td>{org.city}</td>
                        <td>
                            <div className={"pull-right"}>
                                <Button bsStyle={"danger"} onClick={()=>openDeleteOptician(org)} disabled={org.name===loggedInOptician}><Glyphicon glyph={"remove"}/> Ta bort</Button>
                                &nbsp;
                                <Button onClick={()=>openResetOptician(org)} disabled={org.name===loggedInOptician} ><Glyphicon glyph={"repeat"}/> Nollställ lösenord</Button>
                                &nbsp;
                                <Button onClick={()=>openUpdateOptician(org)} disabled={org.name===loggedInOptician} ><Glyphicon glyph={"edit"}/> Uppdatera</Button>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    </div>)}
export default OpticianList;