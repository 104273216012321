import React from 'react'
import { Alert, Button, Col, Row, Form, Modal } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const DeleteOpticianDialog = (
    {resetErrorMessage,
        onResetClicked,
        onCancelClicked,
        opticianForm: {
            emailUsername,
            emailUsernameRepetition,
            organizationId,
            name,
            streetAddress,
            zipCode,
            city,
            contactName,
            contactPhoneNumber,
            clinicInfo
        },
    }) => {
    return (
    <div className="ResetOpticianDialog">
        <Modal
            show={true}
            onHide={onCancelClicked}
            keyboard={true}
        >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Nollställ optikers lösenord</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {resetErrorMessage &&
                        <Alert bsStyle="danger">{resetErrorMessage}</Alert>
                    }
                    <Form id="saveOpticianForm" className="SaveOpticianForm" style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                          }}>
                        <Row>
                            <Col sm={12}>
                                    <p>Är du säker på att du vill nollställa optikerns lösenord, {name}?</p>
                                    <p>Det kommer skickas ett nytt aktiveringsmail till optikern där denne kan
                                    ställa in sitt lösenord.</p>
                                    <Button bsStyle={'danger'} onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    <Button bsStyle={'primary'}
                                            style={{marginLeft: '5px'}}
                                            onClick={() => {onResetClicked({organizationId, emailUsername})}}>Nollställ lösenord</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
        </Modal>
    </div>
    )}
export default DeleteOpticianDialog;