import React from 'react';
import { connect } from 'react-redux'
import { branch, compose, renderComponent } from 'recompose'
import { sessionActions, sessionSelectors } from './redux'
import LogInDialog from "./LogInDialog";
import Loading from '../../components/Loading'
import base64url from "../../base64";

const mapStateToProps = (state) => ({
    isLoggingIn: sessionSelectors.loggingIn(state),
    loginDialogOpen: sessionSelectors.loginDialogOpen(state),
    loginErrorMessage: sessionSelectors.loginErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    onForgotClicked: (email) => dispatch(sessionActions.forgotPassword(email)),
    onLogInClicked: (username, password) => dispatch(sessionActions.logIn(username, password)),
    onLogInClicked2: async (username, password) => {
        // dec2hex :: Integer -> String
        // i.e. 0-255 -> '00'-'ff'
        function dec2hex (dec) {
            return dec.toString(16).padStart(2, "0")
        }

        // generateId :: Integer -> String
        function generateId (len) {
            var arr = new Uint8Array((len || 40) / 2)
            window.crypto.getRandomValues(arr)
            return Array.from(arr, dec2hex).join('')
        }

        async function digestMessage(message) {
            const encoder = new TextEncoder();
            const data = encoder.encode(message);
            const hash = await crypto.subtle.digest("SHA-256", data);
            console.log('hash', hash)
            return hash;
        }

        // const codeVerifier = generateId(20);
        const codeVerifier = '01234567890123456789'
        console.log("codeVerifier", codeVerifier);
        // const hashBytes = digestMessage(codeVerifier);
        // console.log('hashBytes.byteLength', hash.byteLength);
        // const decoder = new TextDecoder();
        // const codeChallenge = base64url.encode(decoder.decode(hashBytes))
        // console.log('codeChallenge bse64 encoded', codeChallenge)
        // dispatch(sessionActions.logInOidcPkce(codeChallenge))

        digestMessage(codeVerifier).then((digestBuffer) => {
            console.log('digestBuffer.byteLength', digestBuffer.byteLength)
            const decoder = new TextDecoder();
            const codeChallenge = base64url.encode(decoder.decode(digestBuffer))
            console.log('codeChallenge bse64 encoded', codeChallenge)
            dispatch(sessionActions.logInOidcPkce(codeChallenge))
        })

        // const encoder = new TextEncoder();
        // const codeVerifierData = encoder.encode(codeVerifier);
        // console.log('codeVerifierData.byteLength', codeVerifierData.byteLength)
        // console.log('codeVerifierData', codeVerifierData)
        // const hashPromise = crypto.subtle.digest("SHA-256", codeVerifierData);
        // hashPromise.then((digestBuffer) => {
        //     console.log('digestBuffer.byteLength', digestBuffer.byteLength)
        //     const decoder = new TextDecoder();
        //     const codeChallenge = base64url.encode(decoder.decode(digestBuffer))
        //     console.log('codeChallenge bse64 encoded', codeChallenge)
        //     dispatch(sessionActions.logInOidcPkce(codeChallenge))
        // });
    },
    onCancelClicked: () => dispatch(sessionActions.cancelLoginDialog()),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    branch(({isLoggingIn}) => isLoggingIn,
        renderComponent(()=><Loading title={'Loggar in...'}/>)
    )
)(LogInDialog)