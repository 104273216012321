import {
  LOG_IN,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  CANCEL_LOGIN_DIALOG,
  SESSION_INITIALIZED,
  EXTEND_SESSION,
  LOG_IN_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  ACTIVATE_ACCOUNT,
  EXTEND_SESSION_SUCCESS,
  RESET_USER_PASSWORD_SUCCESS, FORGOT_PASSWORD, RESET_USER_PASSWORD, LOG_IN_OIDC_PKCE, GET_TOKEN_OIDC_PKCE, CHANGE_PASSWORD_OIDC_PKCE

} from './SessionActionTypes';
import {iif, Observable} from 'rxjs';
import { flatMap, switchMap, map, catchError, delay, mapTo, takeUntil, filter } from 'rxjs/operators';

import { combineEpics } from 'redux-observable';
import config from 'react-global-configuration';
import {LOCATION_CHANGE, push, replace} from 'connected-react-router';
import {sessionSelectors, sessionActions} from './'
import {
  errorHandlingWithLogoutCheck, errorMessage,
  getJsonWithAuth, getJsonWithBasicAuth, postForm, postFormWithBasicAuth,
  postJsonWithBasicAuth,
  putJsonWithAuth, putJsonWithBasicAuth
} from "../../../components/AjaxHelpers";
import {globalActions} from "../../global/redux";
import {ajax} from "rxjs/ajax";
import history from "../../../history";

const errorHandlingForChangePasswordNotLoggedIn = (error, errorAction, alternateErrorMessage) => {
    console.log(error)
    if (error.status === 401) {
        errorAction[0].errorMessage =
            "Lösenordet kunde inte bytas, det kan bero på att giltighetstiden på din begäran om lösenordsbyte har gått ut.";
    } else {
        errorAction[0].errorMessage = errorMessage(error, alternateErrorMessage);
    }
    return errorAction;
}

export const sessionEpics = combineEpics(

    (action$, state$) => action$.ofType(CANCEL_LOGIN_DIALOG).pipe(
        flatMap(action => ([push('/'), sessionActions.closeLoginDialog()]))
    ),

    (action$, state$) => action$.ofType(ACTIVATE_ACCOUNT).pipe(
        switchMap(action =>
            postJsonWithBasicAuth({
                url: config.get('apiBaseUrl') + '/auth/v1/activate',
                body: {
                    username: action.username,
                    currentPassword: action.oneTimePassword,
                    newPassword: action.newPassword,
                    newPasswordRetype: action.newPasswordRetype,
                },
                username: action.username,
                password: action.oneTimePassword
            }).pipe(
                map(responseContainer => sessionActions.activationSucceeded(responseContainer.response.accessToken, action.username, responseContainer.response.expiresUTC)),
                catchError(error => [sessionActions.activationFailed(error)])
            )
        )
    ),

    (action$, state$) => action$.ofType(LOG_IN_OIDC_PKCE).forEach(action => {
          const authorizationEndpoint = config.get('authServerBaseUrl')+'/realms/ogonremiss/protocol/openid-connect/auth';
          const type = 'code'
          const redirectURI = encodeURIComponent(config.get('appBaseUrl') + '/oidc-callback#debug');
          // const redirectURI = 'http://localhost:8080/oidc-redirect';
          const clientId = 'ogonremiss-webapp';
          const codeChallenge = action.codeChallenge;
          const codeChallengeMethod = 'S256';
          let forwardURL = authorizationEndpoint
              + '?response_type=' + type
              + '&redirect_uri=' + redirectURI
              + '&client_id=' + clientId
              + '&code_challenge=' + codeChallenge
              + '&code_challenge_method=' + codeChallengeMethod
              + '&scope=openid'
              + '#debug'
          window.location = forwardURL;
          // return [push(forwardURL)];
          // return [push(cancel)]
        }
    ),

    (action$, state$) => action$.ofType(CHANGE_PASSWORD_OIDC_PKCE).forEach(action => {
          const authorizationEndpoint = config.get('authServerBaseUrl')+'/realms/ogonremiss/protocol/openid-connect/auth';
          const type = 'code'
          const redirectURI = encodeURIComponent(config.get('appBaseUrl') + '/oidc-callback#debug');
          // const redirectURI = 'http://localhost:8080/oidc-redirect';
          const clientId = 'ogonremiss-webapp';
          const codeChallenge = action.codeChallenge;
          const codeChallengeMethod = 'S256';
          let forwardURL = authorizationEndpoint
              + '?response_type=' + type
              + '&redirect_uri=' + redirectURI
              + '&client_id=' + clientId
              + '&code_challenge=' + codeChallenge
              + '&code_challenge_method=' + codeChallengeMethod
              + '&scope=openid'
              + '&kc_action=UPDATE_PASSWORD'
              + '#debug'
          window.location = forwardURL;
          // return [push(forwardURL)];
          // return [push(cancel)]
        }
    ),

    // Events of type LOG_IN
    /**
     *
     * @param action$
     * @param state$
     * @return {Observable<{auth: {organizationId: *, organizationName: *, jwt: *, roles: [], expiresUTC: *, username: *}, type: string} | {type: string, error: *}>}
     */
    (action$, state$) => action$.ofType(LOCATION_CHANGE)
    .pipe(
        filter(action => action.payload.location.pathname === '/oidc-callback'),
        filter(action => !sessionSelectors.loggedInUser(state$.value)),
        switchMap(action => {
          const redirectURI = config.get('appBaseUrl') + '/oidc-callback#debug';
          const grantCode = new URLSearchParams(action.payload.location.search).get('code');
          return [sessionActions.getTokenOidcPkce(redirectURI, grantCode)];
        })
    ),
    (action$, state$) => action$.ofType(LOCATION_CHANGE)
    .pipe(
        filter(action => action.payload.location.pathname === '/oidc-callback'),
        filter(action => sessionSelectors.loggedInUser(state$.value)),
        switchMap(action => {
          return [replace('/')];
        })
    ),

    (action$, state$) => action$.ofType(GET_TOKEN_OIDC_PKCE)
    .pipe(
        switchMap(action => {
          const redirectURI = action.redirectURI;
          const grantCode = action.grantCode;
          return postForm({
            // url: config.get('apiBaseUrl') + '/auth/v1/token',
            url: config.get('authServerBaseUrl')+'/realms/ogonremiss/protocol/openid-connect/token',
            formData: {
              grant_type: 'authorization_code',
              code: grantCode,
              code_verifier: sessionSelectors.oidcPkceCodeChallenge(state$.value),
              redirect_uri: redirectURI,
              client_id: 'ogonremiss-webapp',
              scope: 'openid'
            }
          }).pipe(
              map(payload => sessionActions.logInSucceeded(payload.response['access_token'], action.username,
                  (payload.response['not-before-policy'] + payload.response['expires_in']), payload.response['refresh_token'], payload.response['id_token'])),
              // catchError(payload => [sessionActions.logInOidcPkce()])
              // catchError(payload => [sessionActions.logInFailed(payload)])
              catchError(payload => [globalActions.globalError("Försökte ansluta till utgången session. Använd Logga In-knappen för att logga in på nytt."),push('/')])
          )
        })
    ),

    // Events of type LOG_IN_OIDC_PKCE
    // (action$, state$) => action$.ofType(LOG_IN_OIDC_PKCE).pipe(
    //      flatMap(action => {
    //        console.log('HERE')
    //          //http://localhost:50726/realms/ogonremiss/protocol/openid-connect/token
    //          const authorizationEndpoint = 'http://localhost:54479/realms/ogonremiss/protocol/openid-connect/auth';
    //          const type = 'code'
    //          const redirectURI = encodeURIComponent('http://localhost:8080/oidc-callback');
    //          // const redirectURI = 'http://localhost:8080/oidc-redirect';
    //          const clientId = 'ogonremiss-webapp';
    //          const codeChallenge = action.codeChallenge;
    //          const codeChallengeMethod = 'S256';
    //          let forwardURL = authorizationEndpoint
    //              + '?response_type=' + type
    //              + '&redirect_uri=' + redirectURI
    //              + '&client_id=' + clientId
    //              + '&code_challenge=' + codeChallenge
    //              + '&code_challenge_method=' + codeChallengeMethod
    //        window.location.replace(forwardURL);
    //        // return [push(forwardURL)];
    //        // return [push(cancel)]
    //      })
    // ),

    // Events of type LOG_IN
    (action$, state$) => action$.ofType(LOG_IN).pipe(
        switchMap(action =>
            postFormWithBasicAuth({
                    // url: config.get('apiBaseUrl') + '/auth/v1/token',
                    url: config.get('authServerBaseUrl') + '/realms/ogonremiss/protocol/openid-connect/token',
                    username: action.username,
                    password: action.password,
                    formData: {
                      client_id: 'ogonremiss-webapp',
                      username: action.username,
                      password: action.password,
                      grant_type: 'password',
                      scope: 'openid'
                    }
            }).pipe(
                map(payload => sessionActions.logInSucceeded(payload.response['access_token'], action.username, (payload.response['not-before-policy'] + payload.response['expires_in']))),
                catchError(payload => [sessionActions.logInFailed(payload)])
            )
        )
    ),

    // LOG_IN_SUCCESS is triggered by the session menu on manual logins, move the user to the start page
    (action$, state$) => action$.ofType(LOG_IN_SUCCESS).pipe(
        // delay(150),
        filter(action => action.auth.roles[0].replace('ROLE_','') === 'ADMIN'),
        flatMap(action => ([replace('/vardenheter'), sessionActions.closeLoginDialog()]))
    ),

    (action$, state$) => action$.ofType(LOG_IN_SUCCESS).pipe(
        // delay(150),
        // (action) => {
        //   console.log('actionzzz', action)
        //   return action;
        // },
        filter(action => action.auth.roles[0].replace('ROLE_','') === 'CARE_UNIT'),
        flatMap(action => ([replace('/optiker'), sessionActions.closeLoginDialog()]))
    ),

    (action$, state$) => action$.ofType(LOG_IN_SUCCESS).pipe(
        // delay(150),
        filter(action => action.auth.roles[0].replace('ROLE_','') === 'OPTICIAN'),
        flatMap(action => ([replace('/remiss'), sessionActions.closeLoginDialog()]))
    ),

    // SESSION_INITIALIZED is triggered by the session menu both on manual logins and automatic extensions, move the user to the start page
    (action$, state$) => action$.ofType(SESSION_INITIALIZED).pipe(
        // delay(150),
        filter(action => action.roles[0].replace('ROLE_','') === 'CHANGE_PASSWORD'),
        flatMap(action => ([push('/bytlosenord'), sessionActions.closeLoginDialog()]))
    ),

    (action$, state$) => action$.ofType(EXTEND_SESSION).pipe(
        switchMap(action =>
          postFormWithBasicAuth({
            // url: config.get('apiBaseUrl') + '/auth/v1/token',
            url: config.get('authServerBaseUrl') + '/realms/ogonremiss/protocol/openid-connect/token',
            formData: {
              grant_type: 'refresh_token',
              client_id: 'ogonremiss-webapp',
              refresh_token: sessionSelectors.refreshToken(state$.value),
              scope: 'openid'
            }
          }).pipe(
                map(payload => sessionActions.extendSessionSucceeded(payload.response['access_token'], sessionSelectors.loggedInUser(state$.value),
                    (payload.response['not-before-policy'] + payload.response['expires_in']), payload.response['refresh_token'], payload.response['id_token'])),
                catchError(response => errorHandlingWithLogoutCheck(response, [sessionActions.logInFailed(response)]))
            )
        )
    ),

    // (action$, state$) => action$.ofType(EXTEND_SESSION).pipe(
    //     switchMap(action =>
    //         getJsonWithAuth({
    //           url:config.get('apiBaseUrl') + '/auth/v1/token',
    //           state: state$.value
    //         }).pipe(
    //             map(payload => sessionActions.extendSessionSucceeded(payload.accessToken, sessionSelectors.loggedInUser(state$.value), payload.expiresUTC)),
    //             catchError(response => errorHandlingWithLogoutCheck(response, [sessionActions.logInFailed(response)]))
    //         )
    //     )
    // ),

    (action$, state$) => action$.ofType(LOG_OUT)
    .pipe(
        delay(500),
        flatMap(action => ([
            sessionActions.logOutSucceeded(action.username),
          {type: "REDIRECT", url: config.get('authServerBaseUrl')+"/realms/ogonremiss/protocol/openid-connect/logout?id_token_hint=" + sessionSelectors.idToken(state$.value) + "&post_logout_redirect_uri="+config.get('appBaseUrl')}]))
          // replace({redirect_uri:"http://localhost:53349/realms/ogonremiss/protocol/openid-connect/logout"})]))
        // flatMap(action => ([push({url:"http://localhost:53349/realms/ogonremiss/protocol/openid-connect/logout"})]))
        // flatMap(action => ([push({url:"redirect:http://localhost:53349/realms/ogonremiss/protocol/openid-connect/logout"}), sessionActions.logOutSucceeded(action.username)]))
    ),

    // (action$, state$) => action$.ofType(LOG_OUT_SUCCESS).pipe(
    //     flatMap(action => ([push('/')]))
    // ),

    // Send log out event when the session (access token) expires
    (action$, state$) => action$.pipe(
        // Create a new automatic log out time on both manual login and automatic session extensions
        filter((action) => (action.type === LOG_IN_SUCCESS || action.type === EXTEND_SESSION_SUCCESS) && sessionSelectors.loggedInUser(state$.value)),
        switchMap(action =>
            Observable.create(obs => {
                // create a fake observable to delay on
                obs.next([1,]);
                obs.complete();
            }).pipe(
                delay(Math.max(1, (new Date(sessionSelectors.expiresUTC(state$.value)).valueOf() - new Date().valueOf()))),
                mapTo(sessionActions.logOut()),
                takeUntil(action$.ofType(EXTEND_SESSION, LOG_OUT, LOG_OUT_SUCCESS)) //until logged out or the session is extended
            )
        )
    ),

    (action$, state$) => action$.ofType(CHANGE_PASSWORD).pipe(
        switchMap(action =>
            // if the new and new retype password are equal we map the putJson observable
            // otherwise we map a password change failure observable
            iif (
                () => (action.newPassword === action.newPasswordRetype),
                iif(()=>action.loggedInUser,
                putJsonWithAuth({
                    url: config.get('apiBaseUrl') + '/me/v1/password',
                    body: {
                        currentPassword: action.currentPassword,
                        newPassword: action.newPassword,
                        newPasswordRetype: action.newPasswordRetype,
                    },
                    state: state$.value
                }).pipe(
                    map(payload => sessionActions.changePasswordSucceeded(payload)),
                    catchError(error => errorHandlingWithLogoutCheck(error, [sessionActions.changePasswordFailed(error)]))
                ),
                    putJsonWithBasicAuth({
                        url: config.get('apiBaseUrl') + '/me/v1/password',
                        username: action.username,
                        password: action.currentPassword,
                        body: {
                            // currentPassword: action.currentPassword,
                            newPassword: action.newPassword,
                            newPasswordRetype: action.newPasswordRetype,
                        },
                        state: state$.value
                    }).pipe(
                        map(payload => sessionActions.changePasswordSucceeded(payload)),
                        catchError(error => errorHandlingForChangePasswordNotLoggedIn(error, [sessionActions.changePasswordFailed(error)]))
                    ),
                    ),
                Observable.create(obs => {
                    // create an observable to emit the changePasswordFailed
                    obs.next([1,]);
                    obs.complete();
                }).pipe(
                    flatMap(action => ([sessionActions.changePasswordFailed("Lösenorden stämmer ej överens")]))
                )
            )

        )
    ),

    (action$, state$) => action$.ofType(FORGOT_PASSWORD).pipe(
        flatMap(action =>
            iif(() => (action.email && typeof action.email === 'string' && action.email !== ''),
                [push('/glomt#email='+action.email)],
                [push('/glomt')]
        ))
    ),

    (action$, state$) => action$.ofType(RESET_USER_PASSWORD).pipe(
        switchMap(action =>
            // Get json without auth, i.e. unauthenticated/anonymous
            ajax.getJSON(config.get('apiBaseUrl') + '/user/v1/users/' + action.email + '/passwordReset').pipe(
                map(payload => sessionActions.resetUserPasswordSuccess()),
                catchError(error => errorHandlingWithLogoutCheck(error,
                    [sessionActions.resetUserPasswordFailed(error)],
                    "Något gick fel när lösenordet skulle nollställas."))
            )
        )
    ),

    (action$, state$) => action$.ofType(RESET_USER_PASSWORD_SUCCESS).pipe(
        flatMap(action => ([globalActions.globalInfo("Ett mail med information hur du nollställer ditt lösenord har skickats till din mailadress."),push('/')]))
    ),

    (action$, state$) => action$.ofType(CHANGE_PASSWORD_SUCCESS).pipe(
        flatMap(action => ([globalActions.globalInfo("Lösenordet uppdaterades"),push('/')]))
    )

);