import {
    LOG_IN,
    LOG_OUT,
    SHOW_LOGIN_DIALOG,
    CANCEL_LOGIN_DIALOG,
    SESSION_INITIALIZED,
    LOG_OUT_SUCCESS,
    CLOSE_LOGIN_DIALOG,
    LOG_IN_SUCCESS,
    LOG_IN_FAIL,
    EXTEND_SESSION_SUCCESS,
    EXTEND_SESSION,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    RESET_CHANGE_PASSWORD_FORM,
    ACTIVATE_ACCOUNT,
    RESET_USER_PASSWORD,
    RESET_USER_PASSWORD_SUCCESS,
    RESET_USER_PASSWORD_FAILED, RESET_FORGOT_PASSWORD_FORM, LOG_IN_OIDC_PKCE, GET_TOKEN_OIDC_PKCE, CHANGE_PASSWORD_OIDC_PKCE,
} from './SessionActionTypes';
import base64url from "../../../base64";
import jwtDecode from 'jwt-decode';
import {errorMessage} from "../../../components/AjaxHelpers";

export const logIn = (username, password) => ({
    type: LOG_IN,
    username,
    password,
})

export const logInOidcPkce = (codeVerifier, codeChallenge) => {
    // // dec2hex :: Integer -> String
    // // i.e. 0-255 -> '00'-'ff'
    // function dec2hex (dec) {
    //     return dec.toString(16).padStart(2, "0")
    // }
    //
    // // generateId :: Integer -> String
    // function generateId (len) {
    //     var arr = new Uint8Array((len || 40) / 2)
    //     window.crypto.getRandomValues(arr)
    //     return Array.from(arr, dec2hex).join('')
    // }
    //
    // const codeVerifier = generateId(20);
    // console.log("codeVerifier", codeVerifier);
    // const hashPromise = crypto.subtle.digest("SHA-256", new Uint8Array(codeVerifier));
    // let codeChallenge = '';
    // (async () => {
    //     await hashPromise.then((result) => {
    //         console.log('hash result', result)
    //         codeChallenge = base64url.encode(result)
    //     })
    // }());
    console.log("codeChallenge", codeChallenge);
    console.log("codeVerifier alpha", codeVerifier);
    return {
        type: LOG_IN_OIDC_PKCE,
        codeChallenge,
        codeVerifier,
    }
}

export const getTokenOidcPkce = (redirectURI, grantCode) => {
    return {
        type: GET_TOKEN_OIDC_PKCE,
        redirectURI,
        grantCode
    }
}

export const extendSession = () => ({
    type: EXTEND_SESSION,
})

export const activationSucceeded = (jwt, username, expiresUTC) => {
    // Same flow here as when we log in since successful activation returns a jwt
    return logInSucceeded(jwt,username,expiresUTC);
}

export const activationFailed = (error) => {
    // Same error handling as when we are changing passwords
    return changePasswordFailed(error);
}

export const forgotPassword = (email) => ({
    type: FORGOT_PASSWORD,
    email
})
export const resetForgotPasswordForm = () => ({
    type: RESET_FORGOT_PASSWORD_FORM,
})
export const resetUserPassword = (email) => ({
    type: RESET_USER_PASSWORD,
    email
})
export const resetUserPasswordSuccess = () => ({
    type: RESET_USER_PASSWORD_SUCCESS,
})
export const resetUserPasswordFailed = (error) => ({
    type: RESET_USER_PASSWORD_FAILED,
    error
})

export const logInSucceeded = (jwt, username, expiresUTC, refreshToken, idToken) => {
    console.log('loginSucceeded expiresUTC', expiresUTC);
    console.log(jwt)
    console.log('refresh token', refreshToken)
    let decodedRolesString = jwtDecode(jwt).scope;
    let roles = decodedRolesString && decodedRolesString.length > 0 ? decodedRolesString.trim().split(" ").filter(scope => scope.startsWith('ROLE_')): [];
    username = jwtDecode(jwt).preferred_username;
    if (username === 'undefined' || typeof username === 'undefined') {
        organizationId = null;
    }
    expiresUTC = jwtDecode(jwt).exp ? jwtDecode(jwt).exp*1000 : expiresUTC;
    if (expiresUTC === 'undefined' || typeof expiresUTC === 'undefined') {
        expiresUTC = null;
    }
    console.log("expiresUTC", expiresUTC)
    console.log("now", Date.now())
    console.log("time left", new Date(expiresUTC.valueOf()) - new Date().valueOf())
    let organizationId = jwtDecode(jwt).organizationId;
    if (organizationId === 'undefined' || typeof organizationId === 'undefined') {
        organizationId = null;
    }
    let organizationName = jwtDecode(jwt).organizationName;
    if (organizationName === 'undefined' || typeof organizationName === 'undefined') {
        organizationName = null;
    }
    let sid = jwtDecode(jwt).sid;
    if (sid === 'undefined' || typeof sid === 'undefined') {
        sid = null;
    }
    return ({
        type: LOG_IN_SUCCESS,
            auth: {
            jwt,
            username,
            organizationId,
            organizationName,
            expiresUTC,
            roles,
            sid,
            refreshToken,
            idToken
        }
    })
}

export const extendSessionSucceeded = (jwt, username, expiresUTC, refreshToken, idToken) => {
    console.log('loginSucceeded expiresUTC', expiresUTC);
    console.log(jwt)
    let decodedRolesString = jwtDecode(jwt).scope;
    let roles = decodedRolesString && decodedRolesString.length > 0 ? decodedRolesString.trim().split(" ").filter(scope => scope.startsWith('ROLE_')): [];
    username = jwtDecode(jwt).preferred_username;
    if (username === 'undefined' || typeof username === 'undefined') {
        organizationId = null;
    }
    expiresUTC = jwtDecode(jwt).exp ? jwtDecode(jwt).exp*1000 : expiresUTC;
    if (expiresUTC === 'undefined' || typeof expiresUTC === 'undefined') {
        expiresUTC = null;
    }
    console.log("expiresUTC", expiresUTC)
    console.log("now", Date.now())
    console.log("time left", new Date(expiresUTC.valueOf()) - new Date().valueOf())
    let organizationId = jwtDecode(jwt).organizationId;
    if (organizationId === 'undefined' || typeof organizationId === 'undefined') {
        organizationId = null;
    }
    let organizationName = jwtDecode(jwt).organizationName;
    if (organizationName === 'undefined' || typeof organizationName === 'undefined') {
        organizationName = null;
    }
    let sid = jwtDecode(jwt).sid;
    if (sid === 'undefined' || typeof sid === 'undefined') {
        sid = null;
    }
    return ({
        type: EXTEND_SESSION_SUCCESS,
        auth: {
            jwt,
            username,
            organizationId,
            organizationName,
            expiresUTC,
            roles,
            sid,
            refreshToken,
            idToken
        }
    })
}

export const logInFailed = (error) => {
    return ({
        type: LOG_IN_FAIL,
        error
    })
}

export const logOut = () => ({
    type: LOG_OUT,
})

export const logOutSucceeded = (username, reason) => ({
    type: LOG_OUT_SUCCESS,
    username,
    reason,
})

export const showLoginDialog = () => ({
    type: SHOW_LOGIN_DIALOG,
})

export const cancelLoginDialog = () => ({
    type: CANCEL_LOGIN_DIALOG,
})

export const closeLoginDialog = () => ({
    type: CLOSE_LOGIN_DIALOG,
})

export const sessionInitialized = (user, roles) => ({
    type: SESSION_INITIALIZED,
    user,
    roles
})

export const changePasswordOidcPkce = (codeVerifier, codeChallenge) => ({
    type: CHANGE_PASSWORD_OIDC_PKCE,
    codeVerifier,
    codeChallenge
})

export const changePassword = (username, currentPassword, newPassword, newPasswordRetype) => ({
    type: CHANGE_PASSWORD,
    username,
    currentPassword,
    newPassword,
    newPasswordRetype,
})

export const activateAccount = (username, oneTimePassword, newPassword, newPasswordRetype) => ({
    type: ACTIVATE_ACCOUNT,
    username,
    oneTimePassword,
    newPassword,
    newPasswordRetype,
})

export const changePasswordSucceeded = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
})
export const changePasswordFailed = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    errorMessage: errorMessage(error, 'Fel vid försök att byta lösenord. <br/> Det kan t.ex. bero på att lösenorden inte matchade eller att länkens giltighetstid gått ut.'),
})

export const resetChangePasswordForm = () => ({
    type: RESET_CHANGE_PASSWORD_FORM,
})
