import React from 'react'
import {Col, Grid, Row} from 'react-bootstrap'
import './Footer.css'

const Footer = () => (
   <div className="Footer">
        <footer>
            <div id={"footer"} className={"container"}>
                {/*<div className="footerContent" className={"content"}>*/}
                    <Grid id="homeJumbotronGrid">
                        <Row className="show-grid">
                            <Col>
                                <Grid>
                                    <Row>
                                        <div className={"footerContent"}>
                                    <Col xs={12} md={3} lg={3}>
                                        <span className={"footerLogo"}>ögonremiss.se</span>
                                    </Col>
                                    <Col xs={12} md={3} lg={3}>
                                        <span className={"footerLinks"}><a href={"/info/#omoss"}>Om oss</a> • <a href={"/info/#kontakt"}>Kontakt</a></span>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <span className={"footerCopyright"}>© 2018 Bluebrick AB | Org.nr: 556687-7428</span>
                                    </Col>
                                        </div>
                                </Row>
                                </Grid>
                            </Col>
                        </Row>
                    </Grid>
                {/*</div>*/}
            </div>
        </footer>
   </div>
)

export default Footer