import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const OpticianProfile = ({
        isOpen,
        errorMessage,
        updateOptician,
        closeDialog,
        opticianForm
    }) => {

    let opticianNameInputRef;
    let streetAddressInputRef;
    let zipCodeInputRef;
    let cityInputRef;
    let contactNameInputRef;
    let contactPhoneInputRef;
    let saveButton;
    let organizationId = opticianForm && opticianForm.organizationId;


    const performUpdate = () => {
        updateOptician(
        {
            organizationId: organizationId,
            name: opticianNameInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
        });
    }

    const significantFieldChanged = () => {
        if (saveButton) {
            saveButton.disabled = !(opticianNameInputRef.value && opticianNameInputRef.value.length > 0)
        }
    }

    return (
        isOpen &&
        (<Modal
            show={true}
            onHide={closeDialog}
            backdrop="static"
            keyboard={true}
            size="lg"
        >
            <div className="SaveOpticianDialog">
                <ModalHeader closeButton>
                    <ModalTitle>Uppdatera användarprofil</ModalTitle>
                </ModalHeader>
                <Modal.Body>
                    {errorMessage &&
                    <Alert bsStyle="danger">{errorMessage}</Alert>
                    }
                    <Form id="saveOpticianForm" className="SaveOpticianForm"
                          onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              performUpdate();
                          }}>
                        <Row>
                            <Col sm={12}>

                                <FormGroup controlId="formHorizontalOpticianName">
                                    <ControlLabel>Företagets eller butikens namn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     opticianNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         streetAddressInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={() => significantFieldChanged()}
                                                 defaultValue={opticianForm.name}
                                                 placeholder="Företagets namn"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalStreetAddress">
                                    <ControlLabel>Gatuadress</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     streetAddressInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         zipCodeInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={opticianForm.streetAddress}
                                                 placeholder="Ex: Stora Torget 1B"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalZipCode">
                                    <ControlLabel>Postnummer</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     zipCodeInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         cityInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={opticianForm.zipCode}
                                                 placeholder="Ex: 112 12"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalCity">
                                    <ControlLabel>Stad</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     cityInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         contactNameInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={opticianForm.city}
                                                 placeholder="Stad"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalContactName">
                                    <ControlLabel>Namn på kontaktperson</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     contactNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         contactPhoneInputRef.focus();
                                                     }
                                                 }}
                                                 defaultValue={opticianForm.contactName}
                                                 placeholder="För- och efternamn"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalContactPhone">
                                    <ControlLabel>Telefonnummer för kontakt</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     contactPhoneInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         performUpdate();
                                                     }
                                                 }}
                                                 defaultValue={opticianForm.contactPhoneNumber}
                                                 placeholder="Telefonnummer"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Row>
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button bsStyle={'danger'} onClick={closeDialog} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                        performUpdate();
                                    }}>Spara</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>)
    )}
export default OpticianProfile;