import React from 'react'
import {
    Alert,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    Row,
    Col,
} from 'react-bootstrap'
// import './ChangePassword.css'
import Grid from "react-bootstrap/es/Grid";
// import {useParams, useMatches} from "react-router";

const ChangePassword = ({ changePasswordModel: {
                            changePasswordForm,
                            changePasswordInfo,
                            changePasswordError},
                            url,
                            loggedInUser,
                            username,
                            otp,
                            // updateChangePasswordForm,
                            performChangePassword,
                            performActivateAccount}) => {

    // let {username, otp} = useParams();
    // let matches = useMatches();

    // var currentPasswordInputRef;
    var newPasswordInputRef;
    var newPasswordRetypeInputRef;

    // let activation = matches[0].pathname.startsWith("/aktivera/")
    let activation = url.startsWith("/aktivera/")

    const resetForm = () => {
        // currentPasswordInputRef.value = '';
        newPasswordInputRef.value = '';
        newPasswordRetypeInputRef.value = '';
    }

    const changePasswordClicked = (e) => {
        if (activation) {
            performActivateAccount(username, otp,
                newPasswordInputRef.value, newPasswordRetypeInputRef.value)
        } else {
            if (loggedInUser) {
                performChangePassword(loggedInUser, otp,
                    newPasswordInputRef.value, newPasswordRetypeInputRef.value);
            } else {
                performChangePassword(username, otp,
                    newPasswordInputRef.value, newPasswordRetypeInputRef.value);
            }
        }
        resetForm();
    }

    return (
    <div className="ChangePassword">
        { (changePasswordInfo &&
            <Alert bsStyle="info">{changePasswordInfo}</Alert>)
        || (changePasswordError &&
            <Alert bsStyle="danger">{changePasswordError}</Alert>)
        }
        <Grid id={"changePasswordMainGrid"}>
            <Row>
                <Col xs={8} md={8} lg={8}>
                    {(activation &&
                        <div>
                            <h1>Aktivera användare</h1>
                            <h4>Sätt lösenord för att aktivera användare</h4>
                            <input type={"hidden"} id={"username"} name={"username"} value={username}/>
                        </div>)
                        ||
                        <div>
                        <h1>Byt lösenord</h1>
                        </div>
                    }
                    {(loggedInUser &&
                        <input type={"hidden"} id={"username"} name={"username"} value={loggedInUser}/>)
                        || (username &&
                            <input type={"hidden"} id={"username"} name={"username"} value={username}/>)
                    }
                </Col>
            </Row>
            {/*<Row>*/}
                {/*<Col xs={8} md={8} lg={8}>*/}
                    {/*<FormGroup controlId={"currentPassword"}>*/}
                        {/*<ControlLabel>Nuvarande lösenord</ControlLabel>*/}
                        {/*<FormControl type={"password"}*/}
                                     {/*placeholder={"Nuvarande lösenord"}*/}
                                     {/*// value={changePasswordForm.currentPassword || ''}*/}
                                     {/*// onChange={handleChange}*/}
                                     {/*inputRef={(ref) => {*/}
                                         {/*currentPasswordInputRef = ref*/}
                                     {/*}}*/}
                        {/*/>*/}
                        {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                    {/*</FormGroup>*/}
                {/*</Col>*/}
            {/*</Row>*/}
            <Row>
                <Col xs={8} md={8} lg={8}>
                    <FormGroup controlId={"newPassword"}>
                        <ControlLabel>Nytt lösenord</ControlLabel>
                        <FormControl type={"password"}
                                     placeholder={"Nytt lösenord"}
                                     onKeyPress={(e) => {
                                         if (e.charCode === 13) {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             newPasswordRetypeInputRef.focus();
                                         }
                                     }}
                                     inputRef={(ref) => {
                                         newPasswordInputRef = ref
                                     }}
                        />
                        {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={8} md={8} lg={8}>
                    <FormGroup controlId={"newPasswordRetype"}>
                        <ControlLabel>Repetera nytt lösenord</ControlLabel>
                        <FormControl type={"password"}
                                     placeholder={"Nytt lösenord (repetera)"}
                                     onKeyPress={(e) => {
                                         if (e.charCode === 13) {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             changePasswordClicked()
                                         }
                                     }}
                                     inputRef={(ref) => {
                                         newPasswordRetypeInputRef = ref
                                     }}
                        />
                        {/*<HelpBlock>Fyll i patientens personnummer på formatet ÅÅMMDD-NNNN</HelpBlock>*/}
                    </FormGroup>
                </Col>
            </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} >
                        <FormGroup>
                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                            changePasswordClicked()
                        }}>
                            Byt lösenord
                        </Button>
                        </FormGroup>
                    </Col>
                </Row>
        </Grid>
    </div>
    )}
export default ChangePassword;