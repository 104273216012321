import React from 'react';
import { connect } from 'react-redux'
import { branch, compose, renderComponent } from 'recompose'
import { opticianActions, opticianSelectors} from './redux'
import { sessionSelectors } from '../session/redux'
import lifeCycle from './OpticianListLifecycle'
import OpticianList from "./OpticianList";
import Loading from '../../components/Loading'
import {careUnitActions, careUnitSelectors} from "../careUnit/redux";

const mapStateToProps = (state) => ({
    opticians: opticianSelectors.opticianList(state),
    createDialogOpen: opticianSelectors.createDialogOpen(state),
    updateDialogOpen: opticianSelectors.updateDialogOpen(state),
    deleteDialogOpen: opticianSelectors.deleteDialogOpen(state),
    resetDialogOpen: opticianSelectors.resetDialogOpen(state),
    opticianForm: opticianSelectors.opticianForm(state),
    careUnits: careUnitSelectors.careUnitList(state),
    careUnitOrganizationId:  null,
    saveOpticianError: opticianSelectors.saveOpticianError(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
    errorMessage: opticianSelectors.errorMessage(state),
    isLoading: opticianSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    listOpticians: () => dispatch(opticianActions.listOpticians()),
    listCareUnits: () => dispatch(careUnitActions.listCareUnits()),
    deleteOpticianClicked: (opticianForm) => dispatch(opticianActions.openDeleteDialog(opticianForm)),
    updateOpticianClicked: (opticianForm) => dispatch(opticianActions.openUpdateDialog(opticianForm)),
    resetOpticianClicked: (opticianForm) => dispatch(opticianActions.openResetDialog(opticianForm)),
    addOpticianClicked: () => dispatch(opticianActions.openCreateDialog()),
    closeDialogClicked: () => dispatch(opticianActions.closeDialogs()),
    createOptician: (opticianForm) => dispatch(opticianActions.createOptician(opticianForm)),
    updateOptician: (opticianForm) => dispatch(opticianActions.updateOptician(opticianForm)),
    deleteOptician: (opticianForm) => dispatch(opticianActions.deleteOptician(opticianForm)),
    resetOpticianUserPassword: (opticianForm) => dispatch(opticianActions.resetOpticianUserPassword(opticianForm)),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    lifeCycle,
    branch(({isLoading, opticians, careUnits, errorMessage}) => isLoading || (!errorMessage && (!opticians || !careUnits)),
        renderComponent(()=><Loading title={'Laddar...'}/>)
    )
)(OpticianList)