import React from 'react'
import { Button, Glyphicon, MenuItem, NavDropdown, NavItem } from 'react-bootstrap'
import './SessionMenuItem.css'
import LogInDialogContainer from "./LogInDialogContainer";
// import moment from 'moment';
import OpticianProfile from "../optician/OpticianProfileContainer";
import CareUnitProfile from "../careUnit/CareUnitProfileContainer";

// const translateRole = (role) => {
//     switch (role) {
//         case 'ADMIN':
//             return 'Administratör'
//         case 'CARE_UNIT':
//             return 'Vårdenhet'
//         case 'OPTICIAN':
//             return 'Optiker'
//         default:
//             return 'FEL'
//     }
// }

const getRole = (roles) => {
    return roles[0].replace('ROLE_','');
}

// const UserInfoBox = ({username, organizationName, roles, expiresUTC, active}) => {
//     var localDate = moment(expiresUTC).format("ll LTS"); //Show in local timezone
//     let role = getRole(roles);
//     let roleTranslated = translateRole(role);
//     return (
//         <div>
//             <p><strong>Inloggad som</strong></p>
//             <p><i>{username}</i></p>
//             {organizationName && <p>{organizationName}</p>}
//             <p>{roleTranslated}</p>
//             <p>Loggas ut {localDate}</p>
//         </div>
//     )
// }

const SessionMenuItem = ({loggedInUser, roles, isLoggingIn, isLoggingOut, loginDialogOpen,
                             opticianProfileDialogOpen, careUnitProfileDialogOpen,
                             expiresUTC, onLogInClicked, onLogOutClicked, onChangePasswordClicked,
                            onUpdateProfileClicked,
                            organizationName}) => (
    loginDialogOpen===true ?
        <div>
            <h3>loginDialogOpen={console.log("LOGIN DIALOG",loginDialogOpen)}</h3>
        <LogInDialogContainer/>
        </div>
    : (
        loggedInUser ? (
            <NavDropdown eventKey={"9"} title={<span><Glyphicon glyph={'user'}/> {organizationName || loggedInUser}</span>} id="nav-dropdown" className={'SessionMenuItem'}>
                { (opticianProfileDialogOpen &&
                        <OpticianProfile/>)
                    || (careUnitProfileDialogOpen &&
                        <CareUnitProfile/>)
                    || (<span></span>)
                }

                {/*<UserInfoBox username={loggedInUser} organizationName={organizationName} roles={roles} expiresUTC={expiresUTC}/>*/}
                {/*<MenuItem divider />*/}
                {getRole(roles) !== 'ADMIN' &&
                    <MenuItem eventKey={"9.1"} onClick={()=>onUpdateProfileClicked(getRole(roles))}><Glyphicon glyph={"edit"}/>&nbsp;&nbsp;Ändra min profil</MenuItem>
                }
                <MenuItem eventKey={"9.2"} onClick={()=>onChangePasswordClicked()}><Glyphicon glyph={"edit"}/>&nbsp;&nbsp;Byt lösenord</MenuItem>
                <MenuItem divider />
                <MenuItem eventKey={"9.3"} onClick={()=>onLogOutClicked()}><Glyphicon glyph={"off"}/>&nbsp;&nbsp;Logga ut</MenuItem>
            </NavDropdown>)
            :
            <NavItem eventKey={"9.4"} onClick={()=>onLogInClicked()}><Button>Logga in</Button></NavItem>
    )
)
export default SessionMenuItem;