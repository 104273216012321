import React from 'react'
import { Alert, Button, Col, Row, Form, FormGroup, ControlLabel, FormControl, Modal, ModalHeader, ModalTitle} from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const SaveOpticianDialog = ({
        saveErrorMessage,
        onSaveClicked,
        onUpdateClicked,
        onCancelClicked,
        careUnits,
        opticianForm: {
            emailUsername,
            emailUsernameRepetition,
            organizationId,
            careUnitOrganizationId,
            name,
            hsaId,
            streetAddress,
            zipCode,
            city,
            contactName,
            contactPhoneNumber
        }
    }) => {

    let emailUsernameInputRef;
    let emailUsernameRepetitionInputRef;
    let opticianNameInputRef;
    let hsaIdInputRef;
    let careUnitInputRef;
    let streetAddressInputRef;
    let zipCodeInputRef;
    let cityInputRef;
    let contactNameInputRef;
    let contactPhoneInputRef;
    let saveButton;

    const performUpdate = () => {
        onUpdateClicked(
        {
            organizationId: organizationId,
            // emailUsername: emailUsernameInputRef.value,
            // emailUsernameRepetition: emailUsernameRepetitionInputRef.value,
            name: opticianNameInputRef.value,
            hsaId: hsaIdInputRef.value,
            careUnitOrganizationId: careUnitInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
        });
    }

    const performSave = () => {
        onSaveClicked( {
            organizationId: null,
            emailUsername: emailUsernameInputRef.value,
            emailUsernameRepetition: emailUsernameRepetitionInputRef.value,
            name: opticianNameInputRef.value,
            hsaId: hsaIdInputRef.value,
            careUnitOrganizationId: careUnitInputRef.value,
            streetAddress: streetAddressInputRef.value,
            zipCode: zipCodeInputRef.value,
            city: cityInputRef.value,
            contactName: contactNameInputRef.value,
            contactPhoneNumber: contactPhoneInputRef.value,
        });
    }

    const significantFieldChanged = () => {
        if (saveButton) {
            saveButton.disabled = !(opticianNameInputRef.value && opticianNameInputRef.value.length > 0)
        }
    }

    return (
        <Modal
            show={true}
            onHide={onCancelClicked}
            backdrop="static"
            keyboard={true}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
        >
    <div className="SaveOpticianDialog">
                <Modal.Header closeButton id="contained-modal-title-vcenter">
                    {(name &&
                        <ModalTitle><h3>Uppdatera optiker</h3></ModalTitle>)
                            ||
                        <ModalTitle><h3>Skapa optiker</h3></ModalTitle>
                    }
                </Modal.Header>
                <Modal.Body>
                    {saveErrorMessage &&
                        <Alert bsStyle="danger">{saveErrorMessage}</Alert>
                    }
                    <Form id="saveOpticianForm"
                          className="SaveOpticianForm"
                          // style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                              performSave();
                          }}>
                            <Row>
                                <Col sm={12}>

                                <FormGroup controlId="formHorizontalEmailUsername">
                                    <ControlLabel>Email-adress/användarnamn</ControlLabel>
                                    <FormControl type="email"
                                                 inputRef={(ref) => {
                                                     emailUsernameInputRef = ref
                                                     if (ref) {
                                                         ref.focus();
                                                     }
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         emailUsernameRepetitionInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 disabled={name}
                                                 value={emailUsername}
                                                 placeholder="Email-adress"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalEmailUsernameRepetition">
                                    <ControlLabel>Repetera email-adress/användarnamn</ControlLabel>
                                    <FormControl type="email"
                                                 inputRef={(ref) => {
                                                     emailUsernameRepetitionInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         opticianNameInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 disabled={name}
                                                 value={emailUsernameRepetition}
                                                 placeholder="Email-adress (repetera)"
                                    />
                                </FormGroup>
                                <FormGroup controlId="formHorizontalOpticianName">
                                    <ControlLabel>Företagets eller butikens namn</ControlLabel>
                                    <FormControl type="text"
                                                 inputRef={(ref) => {
                                                     opticianNameInputRef = ref
                                                 }}
                                                 onKeyPress={(e) => {
                                                     if (e.charCode === 13) {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         hsaIdInputRef.focus();
                                                     }
                                                 }}
                                                 onChange={()=>significantFieldChanged()}
                                                 // disabled={name}
                                                 defaultValue={name}
                                                 placeholder="Företagets namn"
                                                 />
                                </FormGroup>
                                    <FormGroup controlId="formHorizontalOpticianHsaId">
                                        <ControlLabel>HSA-id</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         hsaIdInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             if (careUnits && careUnits.length === 1) {
                                                                 streetAddressInputRef.focus();
                                                             } else {
                                                                 careUnitInputRef.focus();
                                                             }
                                                         }
                                                     }}
                                                     onChange={()=>significantFieldChanged()}
                                            // disabled={name}
                                                     defaultValue={hsaId}
                                                     placeholder="HSA-id"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalOrganization">
                                        <ControlLabel>Vårdenhet</ControlLabel>
                                        <FormControl componentClass="select" placeholder="Vårdenhet"
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             streetAddressInputRef.focus();
                                                         }
                                                     }}
                                                     inputRef={(ref) => {
                                                         careUnitInputRef = ref
                                                     }}
                                                     disabled={careUnits && careUnits.length === 1}
                                                     defaultValue={ careUnitOrganizationId?careUnitOrganizationId:
                                                         ((careUnits && careUnits.length === 1)?careUnits[0].organizationId:"")
                                                     }
                                        >
                                            <option value={""}>Välj vårdenhet...</option>
                                            {careUnits && careUnits.map((o, index) =>
                                                <option value={o.organizationId} key={"venh-"+index}>{o.name}</option>
                                            )}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalStreetAddress">
                                        <ControlLabel>Gatuadress</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         streetAddressInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             zipCodeInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={streetAddress}
                                                     placeholder="Ex: Stora Torget 1B"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalZipCode">
                                        <ControlLabel>Postnummer</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         zipCodeInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             cityInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={zipCode}
                                                     placeholder="Ex: 112 12"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="formHorizontalCity">
                                        <ControlLabel>Stad</ControlLabel>
                                        <FormControl type="text"
                                                     inputRef={(ref) => {
                                                         cityInputRef = ref
                                                     }}
                                                     onKeyPress={(e) => {
                                                         if (e.charCode === 13) {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             contactNameInputRef.focus();
                                                         }
                                                     }}
                                                     defaultValue={city}
                                                     placeholder="Stad"
                                        />
                                    </FormGroup>
                        <FormGroup controlId="formHorizontalContactName">
                            <ControlLabel>Namn på kontaktperson</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactNameInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 contactPhoneInputRef.focus();
                                             }
                                         }}
                                         defaultValue={contactName}
                                         placeholder="För- och efternamn"
                                        />
                        </FormGroup>
                        <FormGroup controlId="formHorizontalContactPhone">
                            <ControlLabel>Telefonnummer för kontakt</ControlLabel>
                            <FormControl type="text"
                                         inputRef={(ref) => {
                                             contactPhoneInputRef = ref
                                         }}
                                         onKeyPress={(e) => {
                                             if (e.charCode === 13) {
                                                 e.preventDefault();
                                                 e.stopPropagation();
                                                 if (onSaveClicked) {
                                                     performSave();
                                                 } else if (onUpdateClicked) {
                                                     performUpdate();
                                                 }
                                             }
                                         }}
                                         defaultValue={contactPhoneNumber}
                                         placeholder="Telefonnummer"
                                         />
                        </FormGroup>
                                </Col>
                            </Row>

                        <FormGroup>
                            <Row>
                                <Col smOffset={5} sm={7} style={{display: 'flex', justifyContent: 'flex-end'}} >
                                    <Button bsStyle={'danger'} onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    {(onUpdateClicked &&
                                        <Button bsStyle={'primary'} style={{marginLeft: '5px'}} onClick={() => {
                                            performUpdate();
                                        }}>
                                            Uppdatera</Button>)
                                    ||
                                        <FormControl type={"submit"}
                                                     disabled
                                                     bsStyle={'primary'}
                                                     style={{marginLeft: '5px'}}
                                                     value={"Skapa"}
                                                     inputRef={(ref) => saveButton = ref}
                                                     onClick={(e) => {
                                                         e.preventDefault();
                                                         e.stopPropagation();

                                                     }}>
                                        </FormControl>
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
              </Modal.Body>
    </div>
    </Modal>
    )}
export default SaveOpticianDialog;