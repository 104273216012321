import React from 'react'
import { Alert, Button, Col, Row, Form, Modal } from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';

const ResetReferralDialog = ({deleteErrorMessage, onDeleteClicked, onCancelClicked, organizationName, organizationId}) => {
    return (
    <div className="DeleteOrganizationDialog">
        <Modal>
                <Modal.Header>
                    <Modal.Title><h3>Delete organization</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {deleteErrorMessage &&
                        <Alert bsStyle="danger">{deleteErrorMessage}</Alert>
                    }
                    <Form id="saveOrganizationForm" className="SaveOrganizationForm" style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                          }}>
                        <Row>
                            <Col sm={12}>
                                    <p>Are you sure you want to delete the organization, {organizationName}</p>
                                    <Button onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Cancel
                                    </Button>
                                    <Button bsStyle={'primary'}
                                            style={{marginLeft: '5px'}}
                                            onClick={() => {onDeleteClicked(organizationId)}}>Delete organization</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
        </Modal>
    </div>
    )}
export default ResetReferralDialog;