import { ajax } from 'rxjs/ajax';
import {sessionSelectors, sessionActions} from "../domains/session/redux";

export const getJsonWithBasicAuth = ({url, username, password}) => {
    return ajax.getJSON(url,
        {
            'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(username+':'+password))),
            'X-Requested-With': 'XMLHttpRequest',
            // 'Authorization': 'Basic ' + btoa(username+':'+password)
        }
    )
}

export const putJsonWithBasicAuth = ({url, username, password, body, state}) => {
    return ajax.put(
        url,
        body,
        {
            'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(username+':'+password))),
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    )
}

export const postForm = ({url, formData}) => {
  return ajax.post(
      url,
      formData,
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      }
  )
}

export const postFormWithBasicAuth = ({url, username, password, formData}) => {
  return ajax.post(
      url,
      formData,
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(username+':'+password))),
        'X-Requested-With': 'XMLHttpRequest',
      }
  )
}

export const postJsonWithBasicAuth = ({url, username, password, body}) => {
    return ajax.post(
        url,
        body,
        {
            'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(username+':'+password))),
            'X-Requested-With': 'XMLHttpRequest',
            // 'Authorization': 'Basic ' + btoa(username+':'+password)
        }
    )
}

/**
 * Helper for fetching JSON using JWT from state as credentials.
 * Returns the same observable stream as when calling ajax.getJSON directly
 * @param properties.url
 * @param properties.state
 * @returns {Observable<any>}
 */
export const getJsonWithAuth = ({url, state}) => {
    return ajax.getJSON(
        url,
        {
            'Authorization': 'Bearer ' + sessionSelectors.jwt(state)
        }
    )
}

export const postJsonWithAuth = ({url, body, state, ...otherParams}) => {
    return ajax(
        {
            method: 'POST',
            url: url,
            headers: {
                'Authorization': 'Bearer ' + sessionSelectors.jwt(state),
                'Content-Type': 'application/json',
            },
            body: body,
            ...otherParams,
        }
    )
    // return ajax.post(
    //     url,
    //     body,
    //     {
    //         'Authorization': 'Bearer ' + sessionSelectors.jwt(state),
    //         'Content-Type': 'application/json',
    //     },
    // )
}


export const putJsonWithAuth = ({url, body, state}) => {
    return ajax.put(
        url,
        body,
        {
            'Authorization': 'Bearer ' + sessionSelectors.jwt(state),
            'Content-Type': 'application/json',
        }
    )
}

export const deleteWithAuth = ({url, state}) => {
    return ajax.delete(
        url,
        {
            'Authorization': 'Bearer ' + sessionSelectors.jwt(state),
        }
    )
}

/**
 * Checks if the error in the response is a 401 and if it is emits the logout-action
 * to clear the session and go back to the start page.
 * If it is another error just return the given "otherErrorsAction" to emit that one instead.
 * @param error the error object of the erroneous API call
 * @param otherErrorsAction array of actions to emit on other errors
 * @param Used as alternate error message if the error doesn't have error code 9004 or 9002
 * @returns {*} logOut action if the erroneous response had status 401 otherwise otherErrorsAction
 */
export const errorHandlingWithLogoutCheck = (error, otherErrorsAction, alternateErrorMessage) => {
    if (error.status === 401) {
        return [sessionActions.logOut()]
    }
    console.log(error)
    otherErrorsAction[0].errorMessage = errorMessage(error, alternateErrorMessage);
    return otherErrorsAction;
}

export const errorMessage = (error, alternateErrorMessage) => {
    console.log('error:', error);
    return (error && error.response && error.response.code === 9004 && 'Regelfel: ' + error.response.message)
        || (error && error.response && error.response.code === 9002 && 'Indatafel: ' + error.response.message)
        || (error && error.response && error.response.code === 9005 && 'Fel: ' + error.response.message)
        || (error && error.response && error.response.code === 9001 && 'Fel: ' + error.response.message)
        || (error && typeof error === 'string' && error)
        || alternateErrorMessage
}