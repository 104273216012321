import { connect } from 'react-redux'
import {branch, compose, renderComponent} from 'recompose'
import { referralActions, referralSelectors} from './redux'
import { sessionSelectors } from '../session/redux'
import lifeCycle from './ReferralLifecycle'
import Referral from "./Referral";
import Loading from "../../components/Loading";
import React from "react";

const mapStateToProps = (state) => ({
    referral: referralSelectors.referral(state),
    careUnits: referralSelectors.careUnitList(state),
    selectedCareUnit: referralSelectors.selectedCareUnit(state),
    opticianShops: referralSelectors.opticianShopList(state),
    selectedOpticianShop: referralSelectors.selectedOpticianShop(state),
    loggedInOrganizationId: sessionSelectors.organizationId(state),
    isResetDialogOpen: referralSelectors.resetDialogOpen(state),
    isSendDialogOpen: referralSelectors.sendDialogOpen(state),
    isClinicInfoDialogOpen: referralSelectors.clinicInfoDialogOpen(state),
    isSending: referralSelectors.isSending(state),
    sendReferralError: referralSelectors.sendReferralError(state),
    validationErrors: referralSelectors.validationErrors(state),
    fileURL: referralSelectors.fileURL(state),
    fileCreatedDate: referralSelectors.fileCreatedDate(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadCareUnits: () => dispatch(referralActions.listCareUnits()),
    loadOpticianShops: () => dispatch(referralActions.listOpticianShops()),
    updateReferralValue: (name, value) => dispatch(referralActions.updateReferralValue(name, value)),
    openResetDialog: () => dispatch(referralActions.openResetDialog()),
    openSendDialog: () => dispatch(referralActions.openSendDialog()),
    openClinicInfoDialog: () => dispatch(referralActions.openClinicInfoDialog()),
    closeDialogs: () => dispatch(referralActions.closeDialogs()),
    resetReferral: () => dispatch(referralActions.resetReferral()),
    sendReferral: () => dispatch(referralActions.sendReferral()),
    setValidationErrors: (errors) => dispatch(referralActions.setReferralValidationErrors(errors))
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    lifeCycle,
    branch(({careUnits, opticianShops}) => (!careUnits || !opticianShops),
        renderComponent(()=><Loading title={'Laddar vårdenheter...'}/>)
    ),
    branch(({isSending}) => (isSending),
        renderComponent(()=><Loading title={'Skickar remiss...'}/>)
    )
)(Referral)