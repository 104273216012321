const getReferralState = (state) => state && state.referral ? state.referral : {};
export const referral = (state) => getReferralState(state).referral;
export const careUnitList = (state) => getReferralState(state).careUnitList;
export const selectedCareUnit = (state) => getReferralState(state).selectedCareUnit;
export const opticianShopList = (state) => getReferralState(state).opticianShopList;
export const selectedOpticianShop = (state) => getReferralState(state).selectedOpticianShop;
export const clinicInfoDialogOpen = (state) => getReferralState(state).clinicInfoDialogOpen;
export const resetDialogOpen = (state) => getReferralState(state).resetDialogOpen;
export const sendDialogOpen = (state) => getReferralState(state).sendDialogOpen;
export const sendReferralError = (state) => getReferralState(state).sendReferralError;
export const validationErrors = (state) => getReferralState(state).validationErrors;
export const fileURL = (state) => getReferralState(state).fileURL;
export const fileCreatedDate = (state) => getReferralState(state).fileCreatedDate;
export const isSending = (state) => getReferralState(state).isSending;