import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Home from '../domains/home/Home'
import CareUnitListContainer from '../domains/careUnit/CareUnitListContainer'
import OpticianListContainer from '../domains/optician/OpticianListContainer'
import ReferralContainer from '../domains/referral/ReferralContainer'
import Error from './Error'
import PrivateRoute from "../domains/session/PrivateRoute";
import ChangePasswordContainer from "../domains/session/ChangePasswordContainer";
import GlobalMessage from "../domains/global/GlobalMessageContainer";
import HomeInfo from "../domains/home/HomeInfo";
import ForgotPasswordContainer from "../domains/session/ForgotPasswordContainer";
import OicdCallbackContainer from "../domains/session/OicdCallbackContainer";

// https://stackoverflow.com/questions/42768620/onenter-not-called-in-react-router
// Instead of onEnter, do this since Router 4
// <Route exact path="/home" render={() => (
//     isLoggedIn() ? (
//         <Redirect to="/front"/>
//     ) : (
//         <Home />
//     )
// )}/>

const Main = () => (
    <div className={"Main"}>
    <main>
        <div id="content" className="container">
            <GlobalMessage/>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/info' component={HomeInfo}/>
                <Route exact path='/glomt' component={ForgotPasswordContainer} />
                {/*<Route exact path='/#omoss' component={Home}/>*/}
                {/*<Route exact path='/kontakt' onClick={()=>{console.log('1');push('/#kontakt')}}/>*/}
                <Route exact path='/error' component={Error}/>
                <Route exact path='/aktivera/:username/:otp' component={ChangePasswordContainer} />
                <Route exact path='/bytlosenord/:username/:otp' component={ChangePasswordContainer} />
                <Route exact path={'/oidc-callback'} component={OicdCallbackContainer} />
                <PrivateRoute exact path='/remiss' component={ReferralContainer} allowedRoles={["ADMIN", "OPTICIAN"]}/>
                {/*<PrivateRoute exact path='/examples' component={ExampleListContainer} allowedRoles={["ADMIN"]}/>*/}
                {/*<PrivateRoute exact path='/examples/:exampleId' component={ExampleDetailsContainer} onEnter={()=>requireAuth()} allowedRoles={["ADMIN"]}/>*/}
                {/*<PrivateRoute exact path='/examples/:exampleId/assets/:assetId' component={ExampleAssetDetailsContainer} onEnter={()=>requireAuth()} allowedRoles={["ADMIN"]}/>*/}
                <PrivateRoute path='/optiker' component={OpticianListContainer} allowedRoles={["ADMIN", "CARE_UNIT"]}/>
                {/*<PrivateRoute exact path='/anvandare' component={UserListContainer} allowedRoles={["ADMIN"]}/>*/}
                <PrivateRoute exact path='/bytlosenord' component={ChangePasswordContainer} allowedRoles={["ADMIN", "CHANGE_PASSWORD", "CARE_UNIT", "OPTICIAN"]}/>
                <PrivateRoute path='/vardenheter' component={CareUnitListContainer} allowedRoles={["ADMIN"]}/>
            </Switch>
        </div>
    </main>
    </div>
)

export default Main