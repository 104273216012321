import React from 'react'
import { Alert, Button } from 'react-bootstrap'

const Error = () => (
      <div className="Error">
        <Alert bsStyle="danger"><h3>Hoppsan....</h3>
          <p>Något gick fel,
            vänligen testa igen eller kontakta supporten om problemet kvarstår.
          </p>
            <p>&nbsp;</p>
            <Button bsStyle="danger" href={"/"}>Tillbaka till startsidan</Button>
        </Alert>
      </div>
)

export default Error