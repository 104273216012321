import {
    LIST_OPTICIANS_SUCCESS,
    OPEN_UPDATE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_DELETE_DIALOG,
    CLOSE_DIALOGS,
    CREATE_OPTICIAN_SUCCESS,
    CREATE_OPTICIAN_FAILURE,
    UPDATE_OPTICIAN_SUCCESS,
    UPDATE_OPTICIAN_FAILURE,
    DELETE_OPTICIAN_FAILURE,
    DELETE_OPTICIAN_SUCCESS,
    LIST_OPTICIANS_FAILURE,
    OPEN_RESET_DIALOG,
    RESET_OPTICIAN_USER_PASSWORD_FAILURE,
    RESET_OPTICIAN_USER_PASSWORD_SUCCESS,
    OPEN_PROFILE_DIALOG,
    LOAD_OPTICIAN_SUCCESS, LOAD_OPTICIAN_FAILURE, UPDATE_OPTICIAN, LOAD_OPTICIAN, CREATE_OPTICIAN,
} from './OpticianActionTypes'
import {errorMessage} from "../../../components/AjaxHelpers";

export const initialOpticianState = () => ({
    opticianList: null,
    opticianForm: {
        careUnitOrganizationId: null,
        emailUsername: null,
        emailUsernameRepetition: null,
        name: null,
        hsaId: null,
        organizationId: null,
        streetAddress: null,
        zipCode: null,
        city: null,
        contactName: null,
        contactPhoneNumber: null,
    },
    createDialogOpen: false,
    updateDialogOpen: false,
    deleteDialogOpen: false,
    resetDialogOpen: false,
    profileDialogOpen: false,
    saveOpticianError: null,
    errorMessage: null,
    isLoading: null,
});

const opticians = (state = initialOpticianState(), action) => {
    switch (action.type) {
        case LIST_OPTICIANS_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: false,
                opticianList: action.opticians,
                opticianName: null,
                errorMessage: null,
            }
        case LIST_OPTICIANS_FAILURE:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: false,
                errorMessage: action.errorMessage,
            }
        case LOAD_OPTICIAN:
            return {
                ...state,
                isLoading: true,
            }
        case LOAD_OPTICIAN_SUCCESS:
            return {
                ...state,
                opticianForm: action.optician,
                errorMessage: null,
                isLoading: false,
            }
        case LOAD_OPTICIAN_FAILURE:
            return {
                ...state,
                opticianForm: initialOpticianState().opticianForm,
                errorMessage: action.errorMessage,
                isLoading: false,
            }
        case OPEN_CREATE_DIALOG:
            return {
                ...state,
                createDialogOpen: true,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: false,
                opticianForm: initialOpticianState().opticianForm,
            }
        case OPEN_UPDATE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: true,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: false,
                opticianForm: action.opticianForm,
            }
        case OPEN_RESET_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: true,
                profileDialogOpen: false,
                opticianForm: action.opticianForm,
            }
        case OPEN_PROFILE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: true,
                opticianForm: initialOpticianState().opticianForm,
            }
        case OPEN_DELETE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: true,
                resetDialogOpen: false,
                profileDialogOpen: false,
                opticianForm: action.opticianForm,
            }
        case CLOSE_DIALOGS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: false,
                saveOpticianError: null,
                opticianForm: initialOpticianState().opticianForm,
            }
        case CREATE_OPTICIAN:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_OPTICIAN_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                saveOpticianError: null,
                isLoading: false,
            }
        case CREATE_OPTICIAN_FAILURE:
            return {
                ...state,
                createDialogOpen: true,
                saveOpticianError: errorMessage(action.error, "Något gick fel vid försök att skapa vårdenhet"),
                isLoading: false,
            }
        case UPDATE_OPTICIAN:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_OPTICIAN_SUCCESS:
            return {
                ...state,
                updateDialogOpen: false,
                saveOpticianError: null,
                isLoading: false,
            }
        case UPDATE_OPTICIAN_FAILURE:
            return {
                ...state,
                updateDialogOpen: true,
                saveOpticianError: errorMessage(action.error, "Något gick fel vid försök att uppdatera vårdenhet"),
                errorMessage: action.errorMessage,
                isLoading: false,
            }
        case DELETE_OPTICIAN_SUCCESS:
            return {
                ...state,
                deleteDialogOpen: false,
                saveOpticianError: null,
            }
        case DELETE_OPTICIAN_FAILURE:
            return {
                ...state,
                deleteDialogOpen: true,
                saveOpticianError: errorMessage(action.error, "Något gick fel vid försök att ta bort vårdenhet"),
            }
        case RESET_OPTICIAN_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                resetDialogOpen: false,
                saveOpticianError: null,
            }
        case RESET_OPTICIAN_USER_PASSWORD_FAILURE:
            return {
                ...state,
                resetDialogOpen: true,
                saveOpticianError: errorMessage(action.error, "Något gick fel vid försök att nolsltälla användarens lösenord"),
            }
        default:
            return state;
    }
}
export default opticians;