import {
    GLOBAL_ERROR,
    GLOBAL_INFO, RESET_GLOBAL_ERROR,
    RESET_GLOBAL_INFO
} from './GlobalActionTypes';

export const globalInfo = (message) => ({
    type: GLOBAL_INFO,
    message,
})

export const resetGlobalInfo = () => ({
    type: RESET_GLOBAL_INFO,
})

export const globalError = (message) => ({
    type: GLOBAL_ERROR,
    message,
})

export const resetGlobalError = () => ({
    type: RESET_GLOBAL_ERROR,
})