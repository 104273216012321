const getOpticiansState = (state) => state && state.optician ? state.optician : {};
export const opticianList = (state) => getOpticiansState(state).opticianList;
export const createDialogOpen = (state) => getOpticiansState(state).createDialogOpen;
export const updateDialogOpen = (state) => getOpticiansState(state).updateDialogOpen;
export const deleteDialogOpen = (state) => getOpticiansState(state).deleteDialogOpen;
export const resetDialogOpen = (state) => getOpticiansState(state).resetDialogOpen;
export const profileDialogOpen = (state) => getOpticiansState(state).profileDialogOpen;
export const opticianForm = (state) => getOpticiansState(state).opticianForm;
export const errorMessage = (state) => getOpticiansState(state).errorMessage;
export const saveOpticianError = (state) => getOpticiansState(state).saveOpticianError;
export const isLoading = (state) => getOpticiansState(state).isLoading;