import { connect } from 'react-redux'
import { compose } from 'recompose'
import {globalActions, globalSelectors } from './redux'
import GlobalMessage from "./GlobalMessage";

const mapStateToProps = (state) => ({
    info: globalSelectors.info(state),
    error: globalSelectors.error(state),
});

const mapDispatchToProps = (dispatch) => ({
    resetGlobalInfo: () => dispatch(globalActions.resetGlobalInfo()),
    resetGlobalError: () => dispatch(globalActions.resetGlobalError()),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
)(GlobalMessage)