import {
    LIST_CARE_UNITS, LIST_CARE_UNITS_SUCCESS, LIST_CARE_UNITS_FAILURE,
    CREATE_CARE_UNIT, CREATE_CARE_UNIT_SUCCESS, CREATE_CARE_UNIT_FAILURE,
    UPDATE_CARE_UNIT, UPDATE_CARE_UNIT_FAILURE, UPDATE_CARE_UNIT_SUCCESS,
    DELETE_CARE_UNIT, DELETE_CARE_UNIT_SUCCESS, DELETE_CARE_UNIT_FAILURE,
    OPEN_DELETE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_UPDATE_DIALOG,
    CLOSE_DIALOGS, LOAD_CARE_UNIT_SUCCESS, LOAD_CARE_UNIT_FAILURE, LOAD_CARE_UNIT, OPEN_PROFILE_DIALOG,
} from './CareUnitActionTypes';

export const createCareUnit = (careUnitForm) => ({
    type: CREATE_CARE_UNIT,
    careUnitForm
})
export const createCareUnitSucceeded = () => ({
    type: CREATE_CARE_UNIT_SUCCESS,
})
export const createCareUnitFailed = (error) => ({
    type: CREATE_CARE_UNIT_FAILURE,
    error
})

export const updateCareUnit = (careUnitForm) => ({
    type: UPDATE_CARE_UNIT,
    careUnitForm
})
export const updateCareUnitSucceeded = (careUnit) => ({
    type: UPDATE_CARE_UNIT_SUCCESS,
    careUnit,
})
export const updateCareUnitFailed = (error) => ({
    type: UPDATE_CARE_UNIT_FAILURE,
    error
})

export const deleteCareUnit = (careUnitForm) => ({
    type: DELETE_CARE_UNIT,
    careUnitForm
})
export const deleteCareUnitSucceeded = () => ({
    type: DELETE_CARE_UNIT_SUCCESS,
})
export const deleteCareUnitFailed = (error) => ({
    type: DELETE_CARE_UNIT_FAILURE,
    error
})

export const listCareUnits = () => ({
    type: LIST_CARE_UNITS,
})
export const listCareUnitsSucceeded = (careUnits) => ({
    type: LIST_CARE_UNITS_SUCCESS,
    careUnits
})
export const listCareUnitsFailed = (error) => ({
    type: LIST_CARE_UNITS_FAILURE,
    error
})

export const openDeleteDialog = (careUnitForm) => ({
    type: OPEN_DELETE_DIALOG,
    careUnitForm
})

export const openCreateDialog = () => ({
    type: OPEN_CREATE_DIALOG,
})

export const openUpdateDialog = (careUnitForm) => ({
    type: OPEN_UPDATE_DIALOG,
    careUnitForm
})

export const openProfileDialog = () => ({
    type: OPEN_PROFILE_DIALOG,
})

export const closeDialogs = () => ({
    type: CLOSE_DIALOGS,
})

export const loadCareUnit = (organizationId) => ({
    type: LOAD_CARE_UNIT,
    organizationId
})
export const loadCareUnitSucceeded = (careUnit) => ({
    type: LOAD_CARE_UNIT_SUCCESS,
    careUnit
})
export const loadCareUnitFailed = (error) => ({
    type: LOAD_CARE_UNIT_FAILURE,
    error,
})