// import { routerReducer } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable';
import {filter, mapTo, sample} from 'rxjs/operators';
import {interval} from 'rxjs';
import browserHistory from '../history'
import { global, globalEpics, initialGlobalState } from '../domains/global/redux';
import { referral, referralEpics, initialReferralState } from '../domains/referral/redux';
import { session, sessionEpics, initialSessionState, sessionActionTypes, sessionSelectors } from '../domains/session/redux';
import { users, usersEpics, initialUsersState } from '../domains/users/redux';
import { careUnit, careUnitEpics, initialCareUnitState } from '../domains/careUnit/redux';
import { optician, opticianEpics, initialOpticianState } from '../domains/optician/redux';
import {
    LOG_IN,
    LOG_IN_SUCCESS,
    LOG_IN_FAIL,
    LOG_OUT,
    LOG_OUT_FAIL,
    LOG_OUT_SUCCESS,
    EXTEND_SESSION,
    SESSION_INITIALIZED,
    SHOW_LOGIN_DIALOG,
    CLOSE_LOGIN_DIALOG,
    CANCEL_LOGIN_DIALOG, EXTEND_SESSION_SUCCESS,
} from '../domains/session/redux/SessionActionTypes'
import {extendSession, logOut} from '../domains/session/redux/SessionActions'
import store from 'store2'

const isActionThatRequiresAuth = (action) =>
    (
    action.type!==LOG_IN
    && action.type!==LOG_IN_SUCCESS
    && action.type!==EXTEND_SESSION_SUCCESS
    && action.type!==LOG_IN_FAIL
    && action.type!==LOG_OUT
    && action.type!==LOG_OUT_SUCCESS
    && action.type!==LOG_OUT_FAIL
    && action.type!==SHOW_LOGIN_DIALOG
    && action.type!==CLOSE_LOGIN_DIALOG
    && action.type!==CANCEL_LOGIN_DIALOG)

const isActionThatTriggersExtension = (action) =>
    (
        action.type!==EXTEND_SESSION
        && action.type!==SESSION_INITIALIZED)


export const rootEpic = combineEpics(

    // If a loggedIn user tries to do an action after the session has expired and the
    // state for some reason isn't already in a "logging out" state, emit a logOut action
    (action$, state$) => action$.pipe(
        filter((action)=>
            sessionSelectors.loggedInUser(state$.value)
            && isActionThatRequiresAuth(action)
            && !sessionSelectors.loggingOut(state$.value)
            && new Date(sessionSelectors.expiresUTC(state$.value)) < new Date()
        ),
        mapTo(logOut())
    ),
    // Extend the session every 10 seconds if any action except the ones below has occurred and the
    // session hasn't expired
    // i.e. don't extend the session if the last action was a login/logout or an automatic session extension
    (action$, state$) => action$.pipe(
        sample(interval(10000)),
        filter((action)=>
            sessionSelectors.loggedInUser(state$.value)
            && new Date(sessionSelectors.expiresUTC(state$.value)) > new Date()
            && isActionThatRequiresAuth(action)
            && isActionThatTriggersExtension(action)
        ),
        mapTo(extendSession())
    ),

    sessionEpics,
    globalEpics,
    referralEpics,
    usersEpics,
    careUnitEpics,
    opticianEpics,
    // organizationsEpics, // TEMP
);

export const appReducer = combineReducers({
    session,
    global,
    referral,
    users,
    optician,
    careUnit,
    // organizations, // TEMP
    router: connectRouter(browserHistory),
});

export const rootReducer = (state, action) => {
    if (action.type === sessionActionTypes.LOG_OUT_SUCCESS) {
        console.log('Resetting all state')
        store.namespace('ogonremiss.auth').session.clearAll();
        state = {
            global: initialGlobalState(),
            session: initialSessionState(),
            users: initialUsersState(),
            careUnit: initialCareUnitState(),
            optician: initialOpticianState(),
            referral: initialReferralState(),
            // organizations: initialOrganizationsState(), // TEMP
        };
    }
    return appReducer(state, action);
}