export const LOG_IN_OIDC_PKCE = 'session.LOG_IN_OIDC_PKCE';
export const GET_TOKEN_OIDC_PKCE = 'session.GET_TOKEN_OIDC_PKCE';
export const LOG_IN = 'session.LOG_IN';
export const LOG_IN_SUCCESS = 'session.LOG_IN_SUCCESS';
export const EXTEND_SESSION_SUCCESS = 'session.EXTEND_SESSION_SUCCESS';
export const LOG_IN_FAIL = 'session.LOG_IN_FAIL';
export const LOG_OUT = 'session.LOG_OUT';
export const LOG_OUT_SUCCESS = 'session.LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'session.LOG_OUT_FAIL';
export const SHOW_LOGIN_DIALOG = 'session.SHOW_LOGIN_DIALOG';
export const SHOW_CHANGE_PASSWORD_DIALOG = 'session.SHOW_CHANGE_PASSWORD_DIALOG';
export const CLOSE_LOGIN_DIALOG = 'session.CLOSE_LOGIN_DIALOG';
export const CANCEL_LOGIN_DIALOG = 'session.CANCEL_LOGIN_DIALOG';
export const SESSION_INITIALIZED = 'session.SESSION_INITIALIZED';
export const EXTEND_SESSION = 'session.EXTEND_SESSION'
export const FORGOT_PASSWORD = 'session.FORGOT_PASSWORD'
export const RESET_FORGOT_PASSWORD_FORM = 'session.RESET_FORGOT_PASSWORD_FORM';
export const RESET_USER_PASSWORD = 'session.RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_SUCCESS = 'session.RESET_USER_PASSWORD_SUCCESS'
export const RESET_USER_PASSWORD_FAILED = 'session.RESET_USER_PASSWORD_FAILED'

export const ACTIVATE_ACCOUNT = 'session.ACTIVATE_ACCOUNT';

export const CHANGE_PASSWORD_OIDC_PKCE = 'session.CHANGE_PASSWORD_OIDC_PKCE';
export const CHANGE_PASSWORD = 'session.CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'session.CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'session.CHANGE_PASSWORD_FAILURE';
export const UPDATE_CHANGE_PASSWORD_FORM = 'session.UPDATE_CHANGE_PASSWORD_FORM';
export const RESET_CHANGE_PASSWORD_FORM = 'session.RESET_CHANGE_PASSWORD_FORM';
