import {
    SEND_REFERRAL,
    SEND_REFERRAL_SUCCESS,
    SEND_REFERRAL_FAILURE,
    RESET_REFERRAL,
    OPEN_RESET_DIALOG,
    OPEN_SEND_DIALOG,
    CLOSE_DIALOGS,
    UPDATE_REFERRAL_VALUE,
    LIST_CARE_UNITS,
    LIST_CARE_UNITS_FAILURE,
    LIST_CARE_UNITS_SUCCESS,
    LIST_OPTICIAN_SHOPS_SUCCESS,
    LIST_OPTICIAN_SHOPS,
    LIST_OPTICIAN_SHOPS_FAILURE,
    SET_REFERRAL_VALIDATION_ERRORS, OPEN_CLINIC_INFO_DIALOG
} from './ReferralActionTypes';
import {errorMessage} from "../../../components/AjaxHelpers";

export const updateReferralValue = (name, value) => ({
    type: UPDATE_REFERRAL_VALUE,
    name,
    value
})

export const sendReferral = () => ({
    type: SEND_REFERRAL,
})

export const sendReferralSuccess = (payload) => {
    //Create a Blob from the PDF Stream
    const blob = new Blob(
        [payload.response],
        {type: 'application/pdf'});
    // let file = new File([blob], "remiss.pdf",{lastModified: 1534584790000, type: 'application/pdf'});
    //Build a URL from the file
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL); // Opens the referral pdf in a new tab
    // and sets the referral editor to "sent" mode
    return({
        type: SEND_REFERRAL_SUCCESS,
        payload: payload.response,
        fileURL,
        fileCreatedDate: new Date(),
    })
}

export const sendReferralFailure = (error) => ({
    type: SEND_REFERRAL_FAILURE,
    message: errorMessage(error, 'Fel vid försök att skicka remiss'),
})

export const setReferralValidationErrors = (errors) => ({
    type: SET_REFERRAL_VALIDATION_ERRORS,
    errors
})

export const resetReferral = () => ({
    type: RESET_REFERRAL,
})

export const openResetDialog = () => ({
    type: OPEN_RESET_DIALOG,
})

export const openSendDialog = () => ({
    type: OPEN_SEND_DIALOG,
})

export const openClinicInfoDialog = () => ({
    type: OPEN_CLINIC_INFO_DIALOG,
})

export const closeDialogs = () => ({
    type: CLOSE_DIALOGS,
})

export const listCareUnits = () => ({
    type: LIST_CARE_UNITS,
})
export const listCareUnitsSucceeded = (careUnits) => ({
    type: LIST_CARE_UNITS_SUCCESS,
    careUnits
})
export const listCareUnitsFailed = (response) => ({
    type: LIST_CARE_UNITS_FAILURE,
    response
})

export const listOpticianShops = () => ({
    type: LIST_OPTICIAN_SHOPS,
})
export const listOpticianShopsSucceeded = (opticianShops) => ({
    type: LIST_OPTICIAN_SHOPS_SUCCESS,
    opticianShops
})
export const listOpticianShopsFailed = (response) => ({
    type: LIST_OPTICIAN_SHOPS_FAILURE,
    response
})