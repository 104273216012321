import React from 'react';
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { branch, compose, renderComponent } from 'recompose'
import { sessionSelectors } from './redux'
import './PrivateRoute.css'
import LogInDialogContainer from "./LogInDialogContainer";
import Home from "../home/Home";
import SessionMenuItemContainer from "./SessionMenuItemContainer";

const isUserAllowed = (allowedRoles, userRoles) => {
    return userRoles.filter((userRole) => (allowedRoles.filter((allowedRole) => "ROLE_" + allowedRole === userRole).length > 0)).length > 0
}

const PrivateRoute = ({ownProps: { component: Component, ...rest}}) =>
    <Route {...rest} render={props => (
        <Component {...rest}/>
    )}/>

const mapStateToProps = (state, ownProps) => ({
    loggedInUser: sessionSelectors.loggedInUser(state),
    expiresUTC: sessionSelectors.expiresUTC(state),
    roles: sessionSelectors.roles(state),
    ownProps: ownProps
})

const mapDispatchToProps = (dispatch) => ({
})

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    branch(
        ({loggedInUser, expiresUTC}) => !loggedInUser || new Date(expiresUTC) < new Date(),
        renderComponent(() => <div class={"LoginInfo"}><h3>Du behöver logga in för använda tjänsten.</h3><SessionMenuItemContainer/></div>)
    ),
    branch(
        ({roles, allowedRoles}) => !isUserAllowed(allowedRoles, roles),
        renderComponent(() => <h3>Hoppsan din användare har inte rättighet att vara på den här sidan.</h3>)
    ),
)(PrivateRoute)