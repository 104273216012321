import {
    LIST_OPTICIANS,
    LIST_OPTICIANS_SUCCESS,
    LIST_OPTICIANS_FAILURE,
    CREATE_OPTICIAN,
    CREATE_OPTICIAN_SUCCESS,
    CREATE_OPTICIAN_FAILURE,
    UPDATE_OPTICIAN,
    UPDATE_OPTICIAN_FAILURE,
    UPDATE_OPTICIAN_SUCCESS,
    DELETE_OPTICIAN,
    DELETE_OPTICIAN_SUCCESS,
    DELETE_OPTICIAN_FAILURE,
    OPEN_DELETE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_UPDATE_DIALOG,
    CLOSE_DIALOGS,
    RESET_OPTICIAN_USER_PASSWORD,
    RESET_OPTICIAN_USER_PASSWORD_SUCCESS,
    OPEN_RESET_DIALOG,
    RESET_OPTICIAN_USER_PASSWORD_FAILURE,
    OPEN_PROFILE_DIALOG,
    LOAD_OPTICIAN,
    LOAD_OPTICIAN_SUCCESS,
    LOAD_OPTICIAN_FAILURE,
} from './OpticianActionTypes';

export const createOptician = (opticianForm) => ({
    type: CREATE_OPTICIAN,
    opticianForm
})
export const createOpticianSucceeded = () => ({
    type: CREATE_OPTICIAN_SUCCESS,
})
export const createOpticianFailed = (error) => ({
    type: CREATE_OPTICIAN_FAILURE,
    error
})

export const updateOptician = (opticianForm) => ({
    type: UPDATE_OPTICIAN,
    opticianForm
})
export const updateOpticianSucceeded = (optician) => ({
    type: UPDATE_OPTICIAN_SUCCESS,
    optician
})
export const updateOpticianFailed = (error) => ({
    type: UPDATE_OPTICIAN_FAILURE,
    error
})
export const resetOpticianUserPassword = (opticianForm) => ({
    type: RESET_OPTICIAN_USER_PASSWORD,
    opticianForm
})
export const resetOpticianUserPasswordSucceeded = (opticianForm) => ({
    type: RESET_OPTICIAN_USER_PASSWORD_SUCCESS,
    opticianForm
})
export const resetOpticianUserPasswordFailed = (error) => ({
    type: RESET_OPTICIAN_USER_PASSWORD_FAILURE,
    error
})

export const deleteOptician = (opticianForm) => ({
    type: DELETE_OPTICIAN,
    opticianForm
})
export const deleteOpticianSucceeded = () => ({
    type: DELETE_OPTICIAN_SUCCESS,
})
export const deleteOpticianFailed = (error) => ({
    type: DELETE_OPTICIAN_FAILURE,
    error
})

export const listOpticians = () => ({
    type: LIST_OPTICIANS,
})
export const listOpticiansSucceeded = (opticians) => ({
    type: LIST_OPTICIANS_SUCCESS,
    opticians
})
export const listOpticiansFailed = (error) => ({
    type: LIST_OPTICIANS_FAILURE,
    error,
    // errorMessage: errorMessage("")
})

export const loadOptician = (organizationId) => ({
    type: LOAD_OPTICIAN,
    organizationId
})
export const loadOpticianSucceeded = (optician) => ({
    type: LOAD_OPTICIAN_SUCCESS,
    optician
})
export const loadOpticianFailed = (error) => ({
    type: LOAD_OPTICIAN_FAILURE,
    error,
})

export const openDeleteDialog = (opticianForm) => ({
    type: OPEN_DELETE_DIALOG,
    opticianForm
})

export const openCreateDialog = () => ({
    type: OPEN_CREATE_DIALOG,
})

export const openUpdateDialog = (opticianForm) => ({
    type: OPEN_UPDATE_DIALOG,
    opticianForm
})
export const openResetDialog = (opticianForm) => ({
    type: OPEN_RESET_DIALOG,
    opticianForm
})
export const openProfileDialog = () => ({
    type: OPEN_PROFILE_DIALOG,
})

export const closeDialogs = () => ({
    type: CLOSE_DIALOGS,
})