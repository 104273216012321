import React from 'react'
import {Alert, Button, Col, Row, Form, Modal, ModalHeader, ModalTitle} from 'react-bootstrap'
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';
// import { Modal } from "react-bootstrap";

const DeleteOpticianDialog = (
    {deleteErrorMessage,
        onDeleteClicked,
        onCancelClicked,
        opticianForm: {
            emailUsername,
            emailUsernameRepetition,
            organizationId,
            name,
            streetAddress,
            zipCode,
            city,
            contactName,
            contactPhoneNumber,
            clinicInfo
        },
    }) => {
    return (
    <div className="DeleteOpticianDialog">
        <Modal
            show={true}
            onHide={onCancelClicked}
            keyboard={true}
        >
          <ModalHeader closeButton>
            {/*<h3 className={"modal-title"}>Ta bort optiker</h3>*/}
            <ModalTitle>Ta bort optiker</ModalTitle>
          </ModalHeader>
          <Modal.Body>
                    {deleteErrorMessage &&
                        <Alert bsStyle="danger">{deleteErrorMessage}</Alert>
                    }
                    <Form id="saveOpticianForm" className="SaveOpticianForm" style={{width: '360px'}}
                          onSubmit={(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                          }}>
                        <Row>
                            <Col sm={12}>
                                    <p>Är du säker på att du vill ta bort optikern, {name}?</p>
                                    <Button bsStyle={'danger'} onClick={onCancelClicked} style={{marginLeft: '5px'}}>
                                        Avbryt
                                    </Button>
                                    <Button bsStyle={'primary'}
                                            style={{marginLeft: '5px'}}
                                            onClick={() => {onDeleteClicked({organizationId})}}>Ta bort optiker</Button>
                            </Col>
                        </Row>
                    </Form>
          </Modal.Body>
        </Modal>
    </div>
    )}
export default DeleteOpticianDialog;