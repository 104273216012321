import React from 'react'
import {Alert} from 'react-bootstrap'

const GlobalMessage = ({info, error, resetGlobalInfo, resetGlobalError}) =>
    <div className="GlobalMessage">
        { (info &&
            <Alert onDismiss={resetGlobalInfo} bsStyle="info">{info}</Alert>)
            || (error &&
            <Alert onDismiss={resetGlobalError} bsStyle="danger">{error}</Alert>)
        }
    </div>

export default GlobalMessage;