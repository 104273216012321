import React from 'react'
import {Table, Button, Glyphicon, Row, Col, Alert} from 'react-bootstrap'
import './CareUnitList.css'
import SaveCareUnitDialog from './SaveCareUnitDialog';
import DeleteCareUnitDialog from "./DeleteCareUnitDialog";
import Grid from "react-bootstrap/es/Grid";

const CareUnitList = ({careUnits,
                          createDialogOpen,
                          updateDialogOpen,
                          deleteDialogOpen,
                          careUnitForm,
                          saveCareUnitError, loggedInCareUnit,
                        addCareUnitClicked, updateCareUnitClicked, deleteCareUnitClicked, closeDialogClicked,
                        deleteCareUnit, createCareUnit, updateCareUnit, onCareUnitValueChange, errorMessage,
                  }) => {

    const openUpdateCareUnit = (org) => {
        updateCareUnitClicked(org)
    }

    const openCreateCareUnit = () => {
        addCareUnitClicked()
    }

    const openDeleteCareUnit = (org) => {
        deleteCareUnitClicked(org)
    }

    return (
    <div className="CareUnitList">
        <Grid id={"careUnitMainGrid"}>
            {errorMessage &&
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <Alert bsStyle={"danger"}>
                        <b><Glyphicon glyph={"exclamation-sign"}/> {errorMessage}</b>
                    </Alert>
                </Col>
            </Row>
            }
            <Row>
                <Col xs={8} md={8} lg={8}>
                    <h1>Vårdenheter</h1>
                </Col>
                <Col xs={4} md={4} lg={4}>
                    <span className={"pull-right"}>
                        <Button onClick={()=>openCreateCareUnit()}><Glyphicon glyph={"plus"}/> Lägg till vårdenhet</Button><br/>
                    </span>
                </Col>
            </Row>
        </Grid>


        { (deleteDialogOpen &&
            <DeleteCareUnitDialog deleteErrorMessage={saveCareUnitError}
                                  onCancelClicked={closeDialogClicked}
                                  onDeleteClicked={deleteCareUnit}
                                  careUnitForm={careUnitForm}
                                  // careUnitName={careUnitName}
                                  // careUnitOrganizationId={careUnitOrganizationId}
            />)
        || (updateDialogOpen &&
            <div>
                <SaveCareUnitDialog saveErrorMessage={saveCareUnitError}
                                    onCancelClicked={closeDialogClicked}
                                    onUpdateClicked={updateCareUnit}
                                    careUnitForm={careUnitForm}
                                    // careUnitOrganizationId={careUnitOrganizationId}
                                    // careUnitName={careUnitName}
                                    // streetAddress={careUnitStreetAddress}
                                    // zipCode={careUnitZipCode}
                                    // city={careUnitCity}
                                    // contactName={careUnitContactName}
                                    // contactPhone={careUnitContactPhone}
                                    // clinicInfo={careUnitClinicInfo}
                />
            </div>)
        || (createDialogOpen &&
                <SaveCareUnitDialog saveErrorMessage={saveCareUnitError}
                                    careUnitForm={{}}
                                    onCancelClicked={closeDialogClicked}
                                    onSaveClicked={createCareUnit} />)
        }
        <Table condensed hover responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>Namn</th>
                <th>Kontaktperson</th>
                <th>Telefon kontakt</th>
                <th>Gatuadress</th>
                <th>Postnummer</th>
                <th>Stad</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                careUnits && careUnits.map((org, index) =>
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{org.name}</td>
                        <td>{org.contactName}</td>
                        <td>{org.contactPhoneNumber}</td>
                        <td>{org.streetAddress}</td>
                        <td>{org.zipCode}</td>
                        <td>{org.city}</td>
                        <td>
                            <div className={"pull-right"}>
                                <Button bsStyle={"danger"} onClick={()=>openDeleteCareUnit(org)} disabled={org.name===loggedInCareUnit}><Glyphicon glyph={"remove"}/> Ta bort</Button>
                                &nbsp;
                                <Button onClick={()=>openUpdateCareUnit(org)} disabled={org.name===loggedInCareUnit} ><Glyphicon glyph={"edit"}/> Uppdatera</Button>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    </div>)}
export default CareUnitList;