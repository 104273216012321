import React from 'react'
import './Home.css';
import {Col, Grid, Jumbotron, Row} from 'react-bootstrap';

const HomeInfo = () => (
    <div className="Home">
            <Grid id="homeJumbotronGrid">
                <Row style={{height:'32px'}}/>
                <Row className="show-grid">
                    <Col xs={12} md={7} lg={7}>
                        <Grid>
                            <Row>
                            <Col xs={1} md={1} lg={1}></Col>
                            <Col xs={9} md={9} lg={9}>
                                <Jumbotron id="beskrivning" className={"white description"}>
                                    <span className={"startingLine"}></span>
                                    <h2 >Om tjänsten</h2>
                                    <p>
                                        Ögonremiss är en tjänst för dig som Optiker, där du på ett enkelt och effektivt sätt
                                        kan skapa och skicka en remiss elektroniskt till mottagaren.
                                    </p>
                                    <p>
                                        För att komma igång så tar Ni kontakt med den mottagning som Ni brukar skicka era remisser till.
                                        De kan informera er om hur ni kan gå vidare och om de är anslutna till tjänsten ögonremiss.se.
                                    </p>
                                    <p>
                                        Om mottagaren är ansluten kommer de registrera er som användare. Ni kommer då få ett mail från
                                        ögonremiss.se där du får möjlighet att logga in med unikt lösenord. När du är inloggad kan du fylla i
                                        information om patienten och skicka remissen elektroniskt till mottagaren. Allt du behöver göra efter
                                        att remissen är ifylld, är att klicka på knappen skicka. Du kommer få en bekräftelse på att remissen är
                                        inskickad direkt på skärmen i form av en PDF. PDF’en kan du välja att spara, skriva ut och ge till
                                        patienten, eller helt enkelt stänga ner. Remissen som skickas till mottagaren kommer läsas in
                                        elektroniskt i mottagares journalsystem, och patient kommer bli kontaktad på vanligt sätt av
                                        mottagaren efter att en läkare bedömt remissen.
                                    </p>
                                    <p>
                                        All kommunikation mellan din dator och mottagande journalsystem sker krypterat och med de säkerhetsfunktioner
                                        som krävs och godkänts av mottagaren och berörda myndigheter. Ingen patientinformation lagras någon annanstans
                                        än hos i mottagarens journalsystem på vanligt sätt.
                                    </p>
                                </Jumbotron>
                            </Col>
                            <Col xs={1} md={1} lg={1}></Col>
                            </Row>
                            <Row>
                            <Col xs={1} md={1} lg={1}></Col>
                            <Col xs={9} md={9} lg={9}>
                                <Jumbotron id="omoss" className={"white about"}>
                                    <span className={"startingLine"}></span>
                                    <h2 >Om oss</h2>
                                    <p>Ögonremiss.se är en tjänst för utvecklad av Bluebrick AB i samråd med
                                        Region Jämtland Härjedalen för att förenkla och
                                        förbättra hanteringen av ögonremisser.
                                    </p>
                                </Jumbotron>
                            </Col>
                                <Col xs={1} md={1} lg={1}></Col>
                            </Row>
                            <Row>
                            <Col xs={1} md={1} lg={1}></Col>
                            <Col xs={10} md={10} lg={10}>
                                <Jumbotron id="kontakt" className={"white contact"}>
                                    <span className={"startingLine"}></span>
                                    <h2 >Kontakt</h2>
                                    <p>Har du frågor om inloggning eller om remisser som du skickat.
                                        Kontakta den enhet som tar emot dina remisser.
                                        Om du idag inte har inloggning till ögonremiss.se, så ber vi er kontakta
                                        den mottagare som du normalt skickar dina remisser
                                        till för att bli registrerad.
                                    </p>
                                </Jumbotron>
                            </Col>
                                <Col xs={1} md={1} lg={1}></Col>
                            </Row>
                        </Grid>
                    </Col>

                </Row>
            </Grid>
    </div>
)
export default HomeInfo