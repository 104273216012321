export const CREATE_CARE_UNIT = 'care_unit.CREATE_CARE_UNIT';
export const CREATE_CARE_UNIT_SUCCESS = 'care_unit.CREATE_CARE_UNIT_SUCCESS';
export const CREATE_CARE_UNIT_FAILURE = 'care_unit.CREATE_CARE_UNIT_FAILURE';
export const UPDATE_CARE_UNIT = 'care_unit.UPDATE_CARE_UNIT';
export const UPDATE_CARE_UNIT_SUCCESS = 'care_unit.UPDATE_CARE_UNIT_SUCCESS';
export const UPDATE_CARE_UNIT_FAILURE = 'care_unit.UPDATE_CARE_UNIT_FAILURE';
export const DELETE_CARE_UNIT = 'care_unit.DELETE_CARE_UNIT';
export const DELETE_CARE_UNIT_SUCCESS = 'care_unit.DELETE_CARE_UNIT_SUCCESS';
export const DELETE_CARE_UNIT_FAILURE = 'care_unit.DELETE_CARE_UNIT_FAILURE';
export const LIST_CARE_UNITS = 'care_unit.LIST_CARE_UNITS';
export const LIST_CARE_UNITS_SUCCESS = 'care_unit.LIST_CARE_UNITS_SUCCESS';
export const LIST_CARE_UNITS_FAILURE = 'care_unit.LIST_CARE_UNITS_FAILURE';
export const OPEN_DELETE_DIALOG = 'care_unit.OPEN_DELETE_DIALOG';
export const OPEN_UPDATE_DIALOG = 'care_unit.OPEN_UPDATE_DIALOG';
export const OPEN_CREATE_DIALOG = 'care_unit.OPEN_CREATE_DIALOG';
export const CLOSE_DIALOGS = 'care_unit.CLOSE_DIALOGS';
export const LOAD_CARE_UNIT = 'care_unit.LOAD_CARE_UNIT';
export const LOAD_CARE_UNIT_SUCCESS = 'care_unit.LOAD_CARE_UNIT_SUCCESS';
export const LOAD_CARE_UNIT_FAILURE = 'care_unit.LOAD_CARE_UNIT_FAILURE';
export const OPEN_PROFILE_DIALOG = 'care_unit.OPEN_PROFILE_DIALOG';