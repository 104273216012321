import {
    LIST_USERS,
    LIST_USERS_SUCCESS,
    LIST_USERS_FAILURE,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    // UPDATE_CHANGE_PASSWORD_FORM,
    // RESET_CHANGE_PASSWORD_FORM,
    OPEN_DELETE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_UPDATE_DIALOG,
    CLOSE_DIALOGS,
    // CHANGE_PASSWORD,
    CREATE_USER_SUCCESS, CREATE_USER_FAILURE, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS,
    // CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    DELETE_USER_SUCCESS, DELETE_USER_FAILURE,
} from './UsersActionTypes';
import {errorMessage} from "../../../components/AjaxHelpers";

export const createUser = (username, role, organizationId, password, retypePassword) => ({
    type: CREATE_USER,
    username,
    password,
    retypePassword,
    role,
    organizationId,
})
export const createUserSucceeded = () => ({
    type: CREATE_USER_SUCCESS,
})
export const createUserFailed = (error) => ({
    type: CREATE_USER_FAILURE,
    message: errorMessage(error, 'Fel vid försök att skapa användare'),
})

export const updateUser = (username, role, organizationId) => ({
    type: UPDATE_USER,
    username,
    role,
    organizationId
})
export const updateUserSucceeded = () => ({
    type: UPDATE_USER_SUCCESS,
})
export const updateUserFailed = (error) => ({
    type: UPDATE_USER_FAILURE,
    message: errorMessage(error,'Fel vid försök att uppdatera användare'),
})

// export const resetChangePasswordForm = () => ({
//     type: RESET_CHANGE_PASSWORD_FORM,
// })
//
// export const updateChangePasswordForm = (fieldName, fieldValue) => ({
//     type: UPDATE_CHANGE_PASSWORD_FORM,
//     fieldName,
//     fieldValue
// })

// export const changePassword = (username, currentPassword, newPassword, newPasswordRetype) => ({
//     type: CHANGE_PASSWORD,
//     username,
//     currentPassword,
//     newPassword,
//     newPasswordRetype,
// })
// export const changePasswordSucceeded = () => ({
//     type: CHANGE_PASSWORD_SUCCESS,
// })
// export const changePasswordFailed = (error) => ({
//     type: CHANGE_PASSWORD_FAILURE,
//     message: error.response.message || 'Fel vid försök att byta lösenord',
// })

export const deleteUser = (username) => ({
    type: DELETE_USER,
    username,
})
export const deleteUserSucceeded = () => ({
    type: DELETE_USER_SUCCESS,
})
export const deleteUserFailed = (error) => ({
    type: DELETE_USER_FAILURE,
    message: errorMessage(error,'Fel vid försök att ta bort användare'),
})

export const listUsers = () => ({
    type: LIST_USERS,
})
export const listUsersSucceeded = (users) => ({
    type: LIST_USERS_SUCCESS,
    users
})
export const listUsersFailed = (error) => ({
    type: LIST_USERS_FAILURE,
    message: errorMessage(error,'Fel vid försök att lista användare'),
})

export const openDeleteDialog = (username) => ({
    type: OPEN_DELETE_DIALOG,
    username
})

export const openCreateDialog = () => ({
    type: OPEN_CREATE_DIALOG,
})

export const openUpdateDialog = (username, role, organizationId) => ({
    type: OPEN_UPDATE_DIALOG,
    username,
    role,
    organizationId
})

export const closeDialogs = () => ({
    type: CLOSE_DIALOGS,
})