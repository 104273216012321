import {
    LIST_CARE_UNITS_SUCCESS,
    OPEN_UPDATE_DIALOG,
    OPEN_CREATE_DIALOG,
    OPEN_DELETE_DIALOG,
    CLOSE_DIALOGS,
    CREATE_CARE_UNIT_SUCCESS,
    CREATE_CARE_UNIT_FAILURE,
    UPDATE_CARE_UNIT_SUCCESS,
    UPDATE_CARE_UNIT_FAILURE,
    DELETE_CARE_UNIT_FAILURE,
    DELETE_CARE_UNIT_SUCCESS,
    LIST_CARE_UNITS_FAILURE,
    LOAD_CARE_UNIT_FAILURE,
    LOAD_CARE_UNIT_SUCCESS,
    LOAD_CARE_UNIT,
    OPEN_PROFILE_DIALOG,
    UPDATE_CARE_UNIT, CREATE_CARE_UNIT,
} from './CareUnitActionTypes'
import {errorMessage} from "../../../components/AjaxHelpers";
import {initialOpticianState} from "../../optician/redux";

export const initialCareUnitState = () => ({
    careUnitList: null,
    careUnitForm: {
        emailUsername: null,
        emailUsernameRepetition: null,
        name: null,
        hsaId: null,
        organizationId: null,
        streetAddress: null,
        zipCode: null,
        city: null,
        contactName: null,
        contactPhoneNumber: null,
        clinicInfo: null,
    },
    createDialogOpen: false,
    updateDialogOpen: false,
    deleteDialogOpen: false,
    profileDialogOpen: false,
    saveCareUnitError: null,
    errorMessage: null,
    isLoading: false,
});

const careUnits = (state = initialCareUnitState(), action) => {
    switch (action.type) {
        case LIST_CARE_UNITS_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                profileDialogOpen: false,
                careUnitList: action.careUnits,
                careUnitName: null,
                errorMessage: null,
            }
        case LIST_CARE_UNITS_FAILURE:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                profileDialogOpen: false,
                errorMessage: action.errorMessage,
            }
        case LOAD_CARE_UNIT:
            return {
                ...state,
                isLoading: true,
            }
        case LOAD_CARE_UNIT_SUCCESS:
            return {
                ...state,
                careUnitForm: action.careUnit,
                errorMessage: null,
                isLoading: false,
            }
        case LOAD_CARE_UNIT_FAILURE:
            return {
                ...state,
                careUnitForm: initialCareUnitState().careUnitForm,
                errorMessage: action.errorMessage,
                isLoading: false,
            }
        case OPEN_CREATE_DIALOG:
            return {
                ...state,
                createDialogOpen: true,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                profileDialogOpen: false,
                careUnitForm: initialCareUnitState().careUnitForm,
            }
        case OPEN_UPDATE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: true,
                deleteDialogOpen: false,
                profileDialogOpen: false,
                careUnitForm: action.careUnitForm,
            }
        case OPEN_PROFILE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                resetDialogOpen: false,
                profileDialogOpen: true,
                careUnitForm: initialOpticianState().careUnitForm,
            }
        case OPEN_DELETE_DIALOG:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: true,
                profileDialogOpen: false,
                careUnitForm: action.careUnitForm,
                // careUnitOrganizationId: action.careUnitOrganizationId,
                // careUnitName: action.careUnitName,
            }
        case CLOSE_DIALOGS:
            return {
                ...state,
                createDialogOpen: false,
                updateDialogOpen: false,
                deleteDialogOpen: false,
                profileDialogOpen: false,
                saveCareUnitError: null,
                careUnitForm: initialCareUnitState().careUnitForm,
            }
        case CREATE_CARE_UNIT:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_CARE_UNIT_SUCCESS:
            return {
                ...state,
                createDialogOpen: false,
                saveCareUnitError: null,
                isLoading: false,
            }
        case CREATE_CARE_UNIT_FAILURE:
            return {
                ...state,
                createDialogOpen: true,
                saveCareUnitError: errorMessage(action.error, "Något gick fel vid försök att skapa vårdenhet"),
                isLoading: false,
            }
        case UPDATE_CARE_UNIT:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_CARE_UNIT_SUCCESS:
            return {
                ...state,
                updateDialogOpen: false,
                saveCareUnitError: null,
                isLoading: false,
            }
        case UPDATE_CARE_UNIT_FAILURE:
            return {
                ...state,
                updateDialogOpen: true,
                saveCareUnitError: errorMessage(action.error, "Något gick fel vid försök att uppdatera vårdenhet"),
                errorMessage: action.errorMessage,
                isLoading: false,
            }
        case DELETE_CARE_UNIT_SUCCESS:
            return {
                ...state,
                deleteDialogOpen: false,
                saveCareUnitError: null,
            }
        case DELETE_CARE_UNIT_FAILURE:
            return {
                ...state,
                deleteDialogOpen: true,
                saveCareUnitError: errorMessage(action.error, "Något gick fel vid försök att ta bort vårdenhet"),
            }
        default:
            return state;
    }
}
export default careUnits;