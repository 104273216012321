import React from 'react'
import { connect } from 'react-redux'
import { branch, compose, renderComponent } from 'recompose'
import { sessionActions, sessionSelectors } from './redux'
import sessionMenuItemLifecycle from './SessionMenuItemLifecycle'
import SessionMenuItem from './SessionMenuItem'
import Loading from '../../components/Loading'
import {push} from "connected-react-router";
import {opticianActions, opticianSelectors} from "../optician/redux";
import {careUnitActions, careUnitSelectors} from "../careUnit/redux";

const mapStateToProps = (state) => ({
    oidcPkceCodeChallenge: sessionSelectors.oidcPkceCodeChallenge(state),
    oidcGrantCode: sessionSelectors.oidcGrantCode(state),
    loggedInUser: sessionSelectors.loggedInUser(state),
    roles: sessionSelectors.roles(state),
    organizationName: sessionSelectors.organizationName(state),
    expiresUTC: sessionSelectors.expiresUTC(state),
    isLoggingOut: sessionSelectors.loggingOut(state),
    opticianProfileDialogOpen: opticianSelectors.profileDialogOpen(state),
    careUnitProfileDialogOpen: careUnitSelectors.profileDialogOpen(state),
    loginDialogOpen: sessionSelectors.loginDialogOpen(state)
});



const mapDispatchToProps = (dispatch) => ({
    onLogInClicked: async (username) => {
        // dec2hex :: Integer -> String
        // i.e. 0-255 -> '00'-'ff'
        function dec2hex (dec) {
            return dec.toString(16).padStart(2, '0')
        }
        // generateId :: Integer -> String
        function generateId (len) {
            let arr = new Uint8Array((len || 40) / 2)
            window.crypto.getRandomValues(arr)
            return Array.from(arr, dec2hex).join('')
        }
        function base64EncodeURL(byteArray) {
            // from https://gist.github.com/themikefuller/c1de46cbbdad02645b9dc006baedf88e
            // themikefuller/base64url.js
            return btoa(Array.from(new Uint8Array(byteArray)).map(val => {
                return String.fromCharCode(val);
            }).join('')).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
        }
        async function hash(string, algorithm='SHA-256') {
            const utf8 = new TextEncoder().encode(string);
            const hashBuffer = await crypto.subtle.digest(algorithm, utf8);
            return hashBuffer;
        }
        const codeVerifier = generateId(64);
        console.log("codeVerifier", codeVerifier);
        hash(codeVerifier, 'SHA-256').then((hashHex) => {
            const codeChallenge = base64EncodeURL(hashHex)
            console.log("codeVerifier2", codeVerifier);
            console.log("codeChallenge", codeChallenge);
            dispatch(sessionActions.logInOidcPkce(codeVerifier, codeChallenge))
        })
        // dispatch(sessionActions.showLoginDialog())
    },
    onLogOutClicked: () => dispatch(sessionActions.logOut()),
    onChangePasswordClicked: () => {
        // dec2hex :: Integer -> String
        // i.e. 0-255 -> '00'-'ff'
        function dec2hex (dec) {
            return dec.toString(16).padStart(2, '0')
        }
        // generateId :: Integer -> String
        function generateId (len) {
            let arr = new Uint8Array((len || 40) / 2)
            window.crypto.getRandomValues(arr)
            return Array.from(arr, dec2hex).join('')
        }
        function base64EncodeURL(byteArray) {
            // from https://gist.github.com/themikefuller/c1de46cbbdad02645b9dc006baedf88e
            // themikefuller/base64url.js
            return btoa(Array.from(new Uint8Array(byteArray)).map(val => {
                return String.fromCharCode(val);
            }).join('')).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
        }
        async function hash(string, algorithm='SHA-256') {
            const utf8 = new TextEncoder().encode(string);
            const hashBuffer = await crypto.subtle.digest(algorithm, utf8);
            return hashBuffer;
        }
        const codeVerifier = generateId(64);
        console.log("codeVerifier", codeVerifier);
        hash(codeVerifier, 'SHA-256').then((hashHex) => {
            const codeChallenge = base64EncodeURL(hashHex)
            console.log("codeVerifier2", codeVerifier);
            console.log("codeChallenge", codeChallenge);
            dispatch(sessionActions.changePasswordOidcPkce(codeVerifier, codeChallenge))
        })
        // dispatch(push("/bytlosenord")),
    },
    onUpdateProfileClicked: (role) => {
        switch(role) {
            case 'CARE_UNIT':
                dispatch(careUnitActions.openProfileDialog())
                break;
            case 'OPTICIAN':
                dispatch(opticianActions.openProfileDialog())
                break;
            default:
                // dispatch nothing
                break;
        }
    },
    onSessionInitialized: (user, roles) => dispatch(sessionActions.sessionInitialized(user, roles)),
});

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    sessionMenuItemLifecycle,
    branch(({isLoggingOut}) => isLoggingOut,
        renderComponent(({loggedInUser})=><Loading title={'Loggar ut ' + loggedInUser + '...'}/>)
    )
)(SessionMenuItem)