import {
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER, LIST_USERS, CREATE_USER_SUCCESS, UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS,
} from './UsersActionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import config from 'react-global-configuration';
import {usersActions} from './'
import {
    deleteWithAuth,
    errorHandlingWithLogoutCheck,
    getJsonWithAuth,
    postJsonWithAuth,
    putJsonWithAuth
} from "../../../components/AjaxHelpers";

export const usersEpics = combineEpics(

    // Events of type LIST_USERS
    (action$, state$) => action$.ofType(LIST_USERS).pipe(
        switchMap(action =>
            getJsonWithAuth({
                url: config.get('apiBaseUrl') + '/user/v1/users',
                state: state$.value
            }).pipe(
                map(payload => usersActions.listUsersSucceeded(payload)),
                catchError(response => errorHandlingWithLogoutCheck(response, [usersActions.listUsersFailed(response)]))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_USER).pipe(
        switchMap(action =>
            postJsonWithAuth({
                url: config.get('apiBaseUrl') + '/user/v1/users',
                body: {
                    username: action.username,
                    password: action.password,
                    role: action.role,
                    organizationId: action.organizationId,
                },
                state: state$.value
            }).pipe(
                map(payload => usersActions.createUserSucceeded(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error, [usersActions.createUserFailed(error)]))
            )
        )
    ),

    (action$, state$) => action$.ofType(CREATE_USER_SUCCESS).pipe(
        map(action => usersActions.listUsers())
    ),


    (action$, state$) => action$.ofType(UPDATE_USER).pipe(
        switchMap(action =>
            putJsonWithAuth({
                url: config.get('apiBaseUrl') + '/user/v1/users/' + action.username,
                body: {
                    username: action.username,
                    role: action.role,
                    organizationId: action.organizationId
                },
                state: state$.value
            }).pipe(
                map(payload => usersActions.updateUserSucceeded(payload)),
                catchError(error => errorHandlingWithLogoutCheck(error, [usersActions.updateUserFailed(error)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(UPDATE_USER_SUCCESS).pipe(
        map(action => usersActions.listUsers())
    ),

    (action$, state$) => action$.ofType(DELETE_USER).pipe(
        switchMap(action =>
            deleteWithAuth({
                url: config.get('apiBaseUrl') + '/user/v1/users/' + action.username,
                state: state$.value
            }).pipe(
                map(payload => usersActions.deleteUserSucceeded()),
                catchError(error => errorHandlingWithLogoutCheck(error, [usersActions.deleteUserFailed(error)]))
            )
        )
    ),
    (action$, state$) => action$.ofType(DELETE_USER_SUCCESS).pipe(
        map(action => usersActions.listUsers())
    ),

    // (action$, state$) => action$.ofType(CHANGE_PASSWORD).pipe(
    //     switchMap(action =>
    //         // if the new and old password aren't equal
    //         iif (
    //             () => (action.newPassword === action.newPasswordRetype),
    //
    //             putJsonWithAuth({
    //                 url: config.get('apiBaseUrl') + '/user/v1/users/' + action.username,
    //                 body: {
    //                     username: action.username,
    //                     password: action.newPassword,
    //                 },
    //                 state: state$.value
    //             }).pipe(
    //                 map(payload => usersActions.changePasswordSucceeded(payload)),
    //                 catchError(error => errorHandlingWithLogoutCheck(error, [usersActions.changePasswordFailed(error)]))
    //             ),
    //             Observable.create(obs => {
    //                 // create an observable to emit the changePasswordFailed
    //                 obs.next([1,]);
    //                 obs.complete();
    //             }).pipe(
    //                 flatMap(action => ([usersActions.changePasswordFailed("Lösenorden stämmer ej överens")]))
    //             )
    //         )
    //
    //     )
    // ),


);